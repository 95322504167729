
import { createStore } from "vuex";
import authModule from './modules/auth';
import groupReports from "./modules/groupReports";
import information from "./modules/information";
import navInfo from "./modules/navInfo";
import permissions from "./modules/permissions";
import privateReports from "./modules/privateReports";
import publicReports from "./modules/publicReports";
import schemaDataNav from "./modules/schemaDataNav";
import dropdownStore from "./modules/dropdownStore";

const store = createStore({
    modules:{
        auth:authModule,       
        private: privateReports,
        public: publicReports,
        information:information,
        navInfo:navInfo,
        permissions:permissions,
        groupReports:groupReports,
        schemaDataNav:schemaDataNav,
        dropdownStore:dropdownStore
    }
});

export default store;