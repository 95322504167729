<template id="reportbuilder">
  <div class="changeHeightEvent" >
    <template v-if="this.$route.name === 'reportbuilder'">
      <Toast v-if="greskaResponse || greskaResponse.length" />

      <div
        style="
              display: flex;
              align-items: strecth;
              justify-content: flex-start;
              margin-top: 5px;
              height: 37px;
            "
      >
        <div>
          <div class="dropdown">
            <button
              class="btn dropdown-toggle"
              type="button"
              style="
                    height: 32px !important;
                    width: 18rem !important;
                    margin-left: 1.5rem !important;
                    background-color: var(--mainBlue) !important;
                    border-radius: none !important;
                    color: white;
                    text-align: left;
                    line-height: 30px;
                  "
              id="dropdownButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ buttonPlaceholder
              }}<i
                class="pi pi-angle-down"
                style="text-align: right; margin-left: 4.5rem"
              ></i>
            </button>
            <!-- ukoliko schema ne postoji, da se ne prikazu dropdown sa schemama -->
            <template v-if="schemas.length != 0">
              <ul
                class="dropdown-menu"
                aria-labelledby="dropdownButton"
                style="height: fit-content"
              >
                <li
                  class="dropdown-item"
                  style="
                        color: white;
                        border-radius: none !important;
                        cursor: pointer;
                      "
                  v-for="item of schemas"
                  :key="item.id"
                  :value="item.id"
                  v-on:click="
                    chosenSchemaID = item.id;
                    addCountryFlagBasedByChosenSchema(item);
                  "
                >
                  <template v-if="item.flag === 'un.png'">
                    <img src="../assets/un.png" width="20" height="15" />
                    {{ item.label }}
                  </template>
                  <template v-if="item.flag === 'serbia.png'">
                    <img src="../assets/serbia.png" width="20" height="15" />
                    {{ item.label }}
                  </template>
                  <template v-if="item.flag === 'bulgaria.png'">
                    <img src="../assets/bulgaria.png" width="20" height="15" />
                    {{ item.label }}
                  </template>
                </li>
              </ul>
            </template>
          </div>
        </div>

        <div style="display: flex">
          <template v-for="t in schemas" :key="t.id">
            <template v-if="chosenSchemaID === t.id">
              <ul class="liBuilder" v-for="d of t.schemas" :key="d.id">
                <li>
                  <button
                    @click="
                      showColumnsAndFilters(d);
                      getDataFromNavbar(d);
                      clearFilters();
                      changeTableVisibility(d.id);
                      selectedSchemaID = d.id;
                      isSaveDisabled = true;
                    "
                    :style="[
                      selectedSchemaID === d.id
                        ? ' border: var(--button-border-width) solid var(--lm-button-border-color);background-color:#c8cfe8'
                        : 'border:none;background-color:transparent',
                    ]"
                    class="
                          buttonForHeader
                          +
                          `${selectedSchemaID
                          ===
                          d.id
                          ?
                          ' '
                          :
                          'active_button'}`
                        "
                    id="buttonForHeader"
                  >
                    <template v-if="d.label === 'Company'">
                      <i
                        style="font-size: 8px; font-weight: bold"
                        class="pi pi-briefcase"
                      ></i>
                      {{ d.label }}
                    </template>
                    <template v-else-if="d.label === 'Persons'">
                      <i
                        style="font-size: 8px; font-weight: bold"
                        class="pi pi-users"
                      ></i>
                      {{ d.label }}
                    </template>
                    <template v-else-if="d.label === 'Financials'">
                      <i
                        style="font-size: 8px; font-weight: bold"
                        class="pi pi-chart-line"
                      ></i>
                      {{ d.label }}
                    </template>
                    <template v-else-if="d.label === 'Promissory note'">
                      <i
                        style="font-size: 8px; font-weight: bold"
                        class="pi pi-chart-line"
                      ></i>
                      {{ d.label }}
                    </template>
                    <template v-else>
                      <i
                        style="font-size: 8px; font-weight: bold"
                        class="pi pi-chart-line"
                      ></i>
                      {{ d.label }}
                    </template>
                  </button>
                </li>
              </ul>
            </template>
          </template>
        </div>
      </div>

      <span class="builderBlock">
        <div id="cascadeSelectFilter" style="width: 30vw; padding-left: 8px">
          <div style="display: flex; align-items: center">
            <div style="flex-direction: row-reverse">
              <ul
                class="dropdown"
                style="margin: 0 !important; padding-right: 4px"
              >
                <li class="dropdown" style="margin: 0 !important">
                  <button
                    v-bind:class="setAdditionalTableSettingsStyle()"
                    style="line-height: unset"
                    to=""
                    id="nav-link-dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i
                      class="pi pi-chart-bar"
                      :style="[groupByReportData ? 'color:#ed8936' : true]"
                    ></i
                    ><i class="pi pi-angle-down" id="hideArrowIcon"></i>
                  </button>
                  <div class="dropdown-menu">
                    <label
                      class="dropdown-item"
                      style="
                          color: white;
                          padding: 0;
                          font-size: 11px;
                          text-transform: unset;
                          font-weight: unset;
                        "
                    >
                      Distinct report data

                      <input
                        type="checkbox"
                        id="groupReportData"
                        name="groupReportData"
                        @change="onChangedGroupByReportData()"
                        v-model="groupByReportData"
                        style="
                            display: inline;
                            margin-left: 1rem;
                            vertical-align: middle;
                          "
                        class="hoverCheckbox"
                    /></label>
                  </div>
                </li>
              </ul>

              <span class="dropdown" id="test">
                <CascadeSelect
                  class="test"
                  v-model="selectedFields"
                  id="columns"
                  :options="fields"
                  optionLabel="column_name"
                  optionGroupLabel="table_name"
                  :optionGroupChildren="['columns']"
                  style="width: 11vw"
                  placeholder="&#8862; Columns"
                  v-on:change="addColumnsToTable($event.value)"
                  v-on:group-change="getTableNameAndTableID($event.value)"
                  :disabled="
                    (userOnlyVisible && !this.gettersAllInfo.is_superuser) ||
                      this.columnsData.length == 0
                  "
                />
              </span>
              <CascadeSelect
                id="filters"
                v-model="selectedFilters"
                :options="filters"
                optionLabel="column_name"
                optionGroupLabel="table_name"
                :optionGroupChildren="['columns']"
                style="width: 11vw"
                placeholder="&#x022EE; Filter"
                v-on:change="addChosenFilter($event.value)"
                v-on:group-change="getFilterParentData($event.value)"
                :disabled="
                  (userOnlyVisible && !this.gettersAllInfo.is_superuser) ||
                    this.columnsData.length == 0
                "
              />
            </div>
          </div>
        </div>

        <div
          id="filterPlaceholderMediaQuery"
          style="
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              cursor: pointer;
              margin-top: 2px;
              width: 59vw;
              cursor: default;
              padding-left: 8px;
            "
        >
          <div
            v-for="(item, index) in getFiltersForRender()"
            :key="item.id"
            class="indexForHotkeys"
            style="padding-bottom:2px"
          >
            <button
              v-bind:class="setValueFromFilter(item)"
              v-bind:id="index + 1"
              @click="openFilterModal(item)"
            >
              <span style="text-align: center">
                {{ item.column_name }}
              </span>
            </button>

            <button v-bind:class="setValueFromFilterX(item)">
              <i v-on:click="deleteFilter(item)" class="pi pi-times"></i>
            </button>
          </div>
          <div
            v-for="(item, index) in this.unusedFilters"
            :key="item.id"
            class="indexForHotkeys"
            style="padding-bottom:2px"
          >
            <button
              v-bind:class="setValueFromFilter(item)"
              v-bind:id="index + 1 + getFiltersForRender().length"
              @click="openFilterModal(item)"
            >
              <span style="text-align: center">
                {{ item.column_name }}
              </span>
            </button>

            <button
              v-on:click="deleteFilter(item)"
              v-bind:class="setValueFromFilterX(item)"
            >
              <i class="pi pi-times" style="text-align: center"></i>
            </button>
          </div>
        </div>
        <div style="width: 18vw; padding-right: 8px" id="thirdContainer">
          <div
            style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
              "
          >
            <div
              id="executeandOptionButtons"
              style="
                  top: 0.5rem;
                  bottom: 0.5rem;
                  left: 0;
                  right: 0;
                  margin-left: auto;
                  align-items: flex-end;
                  height: 25px;
                "
            >
              <button
                class="executeButton"
                :disabled="userOnlyVisible && !this.gettersAllInfo.is_superuser"
                v-on:click="executeShowTableWithoutId()"
              >
                <i class="pi pi-cog"></i>Execute
              </button>

              <ul class="dropdown" :disabled="true">
                <li class="dropdown" style="margin: 0 !important">
                  <button
                    class="optionsButton"
                    to=""
                    id="nav-link-dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="pi pi-sliders-h"></i>Options<i
                      class="pi pi-angle-down"
                    ></i>
                  </button>
                  <template v-if="schemas.length != 0">
                    <div
                      v-bind:class="setOptionsDropdown()"
                      class="dropdown-menu"
                    >
                      <a
                        class="dropdown-item"
                        style="color: white"
                        v-if="isSaveDisabled"
                        @click.prevent="
                          isSaveNewReportModalOpen = !isSaveNewReportModalOpen;
                          this.isSaveChangesToReportOpen = false;
                        "
                        >Save</a
                      >
                    </div>
                  </template>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </span>

      <div class="whiteWrapper">
        <div ref="content">
          <div class="modalDownload" v-if="loading">
            <div class="modalDownloadDiv">
              <ProgressBar mode="indeterminate" />
            </div>
          </div>

          <div ref="table"></div>

          <div style="margin-right:2rem">
            <div
              style="
                  background-color: #f5f5f5;
                  display: flex;
                  height: 45px;
                "
            >
              <div
                style="
                  align-items: center;"
              >
                <p
                  style="
                      flex-direction: row-reverse;
                      margin-left: 1rem;
                      font-weight: bold;
                      color: var(--mainBlue);
                    "
                >
                  <span v-if="result.result_count != null">
                    Report row count:
                    {{ formatNumber(result.result_count) }}
                  </span>
                </p>
              </div>
              <div
                style="
                  background-color: #f5f5f5;
                  display: flex;
                  align-items: center;
                  height: 45px;
                  margin-left:auto;
                "
              >
                <button
                  class="btn btn-regular"
                  style="margin-right:2rem;padding: 16px!important;
    align-items: center;
    line-height: 1px!important;height:unset!important"
                  :disabled="
                    (userOnlyVisible && !this.gettersAllInfo.is_superuser) ||
                      this.columnsData.length == 0
                  "
                  @click="this.openTestDataMethod()"
                >
                  report info
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- </div>
      </div>
    </div> -->
      <teleport v-if="openTestData == true" to="#modals">
        <test-data
          v-on:modalState="onOpenedTestData"
          v-bind:testDataPassed="this.tableTestData"
        />
      </teleport>
      <teleport v-if="isSaveNewReportModalOpen" to="#modals">
        <save-new-report
          v-on:modalState="onSaveNewReportChanged"
          v-bind:dataForTableCreationPassedObj="this.dataForTableCreationObj"
          v-bind:selectedChildButtonPassed="this.selectedSchemaID"
          v-bind:reportNamePassed="this.reportName"
          v-bind:reportDescriptionPassed="this.reportDescription"
          v-bind:reportGroupPassed="this.reportGroup"
          v-bind:userPassed="this.user"
          v-bind:reportTypePassed="this.reportType"
          v-bind:isSaveChangesToReportOpenPassed="
            this.isSaveChangesToReportOpen
          "
          @close="isSaveNewReportModalOpen = false"
        />
      </teleport>
      <teleport v-if="filterModal == true" to="#modals">
        <filter-middleman
          v-bind:filters="this.filtersForModal"
          v-on:modalState="onModalStateChanged"
          @close="filterModal = false"
        />
      </teleport>
    </template>

    <template v-else-if="this.$route.name === 'reportbuilderedit'">
      <Toast v-if="greskaResponse || greskaResponse.length" />
      <div>
        <div
          style="
            display: flex;
            align-items: strecth;
            justify-content: flex-start;
            margin-top: 5px;
            height: 37px;
          "
        >
          <div>
            <div class="dropdown">
              <button
                class="btn dropdown-toggle"
                type="button"
                style="
                  height: 32px !important;
                  width: 18rem !important;
                  margin-left: 1.5rem !important;
                  background-color: var(--mainBlue) !important;
                  border-radius: none !important;
                  color: white;
                  text-align: left;
                  line-height: 30px;
                "
                id="dropdownButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ buttonPlaceholderFromEdit
                }}<i
                  class="pi pi-angle-down"
                  v-bind:class="hideArrowIconOnLoad()"
                  style="text-align: right; margin-left: 4.5rem"
                ></i>
              </button>
              <template v-if="schemasBody.length != 0">
                <ul
                  class="dropdown-menu"
                  aria-labelledby="dropdownButton"
                  style="height: fit-content"
                >
                  <li
                    class="dropdown-item"
                    style="
                      color: white;
                      border-radius: none !important;
                      cursor: pointer;
                    "
                    v-for="itemBody of schemasBody"
                    :key="itemBody.id"
                    :value="itemBody.id"
                    v-on:click="
                      selectedSchemaForButtons = itemBody.id;
                      changeButtonPlaceholderFromEdit(itemBody);
                    "
                  >
                    <template v-if="itemBody.flag === 'un.png'">
                      <img src="../assets/un.png" width="20" height="15" />
                      {{ itemBody.label }}
                    </template>
                    <template v-if="itemBody.flag === 'serbia.png'">
                      <img src="../assets/serbia.png" width="20" height="15" />
                      {{ itemBody.label }}
                    </template>
                    <template v-if="itemBody.flag === 'bulgaria.png'">
                      <img
                        src="../assets/bulgaria.png"
                        width="20"
                        height="15"
                      />
                      {{ itemBody.label }}
                    </template>
                  </li>
                </ul>
              </template>
            </div>
          </div>
          <div style="display: flex">
            <template v-for="tBody in schemasBody" :key="tBody.id">
              <template v-if="selectedSchemaForButtons === tBody.id">
                <ul
                  class="liBuilder"
                  v-for="dBody of tBody.schemas"
                  :key="dBody.id"
                >
                  <li>
                    <button
                      @click="
                        showColumnsAndFilters(dBody);
                        getDataFromNavbar(dBody);
                        clearFilters();
                        changeTableVisibility(dBody.id);
                        selectedSchemaID = dBody.id;
                      "
                      :style="[
                        selectedSchemaID === dBody.id
                          ? ' border: var(--button-border-width) solid var(--lm-button-border-color);background-color:#c8cfe8'
                          : 'border:none;background-color:transparent',
                      ]"
                      class="
                        buttonForHeader
                        +
                        `${selectedSchemaID
                        ===
                        dBody.id
                        ?
                        ' '
                        :
                        'active_button'}`
                      "
                      id="buttonForHeader"
                    >
                      <template v-if="dBody.label === 'Company'">
                        <i
                          style="font-size: 8px; font-weight: bold"
                          class="pi pi-briefcase"
                        ></i>
                        {{ dBody.label }}
                      </template>
                      <template v-else-if="dBody.label === 'Persons'">
                        <i
                          style="font-size: 8px; font-weight: bold"
                          class="pi pi-users"
                        ></i>
                        {{ dBody.label }}
                      </template>
                      <template v-else-if="dBody.label === 'Financials'">
                        <i
                          style="font-size: 8px; font-weight: bold"
                          class="pi pi-chart-line"
                        ></i>
                        {{ dBody.label }}
                      </template>
                      <template v-else-if="dBody.label === 'Promissory note'">
                        <i
                          style="font-size: 8px; font-weight: bold"
                          class="pi pi-chart-line"
                        ></i>
                        {{ dBody.label }}
                      </template>
                      <template v-else>
                        <i
                          style="font-size: 8px; font-weight: bold"
                          class="pi pi-chart-line"
                        ></i>
                        {{ d.label }}
                      </template>
                    </button>
                  </li>
                </ul>
              </template>
            </template>
          </div>
        </div>

        <span class="builderBlock">
          <div id="cascadeSelectFilter" style="width: 30vw; padding-left: 8px">
            <div style="display: flex; align-items: center">
              <div style="flex-direction: row-reverse">
                <ul
                  class="dropdown"
                  style="margin: 0 !important; padding-right: 4px"
                >
                  <li class="dropdown" style="margin: 0 !important">
                    <button
                      v-bind:class="setAdditionalTableSettingsStyle()"
                      style="line-height: unset"
                      to=""
                      id="nav-link-dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i
                        class="pi pi-chart-bar"
                        :style="[groupByReportData ? 'color:#ed8936' : true]"
                      ></i
                      ><i class="pi pi-angle-down" id="hideArrowIcon"></i>
                    </button>
                    <div class="dropdown-menu">
                      <label
                        class="dropdown-item"
                        style="
                          color: white;
                          padding: 0;
                          font-size: 11px;
                          text-transform: unset;
                          font-weight: unset;
                        "
                      >
                        Distinct report data

                        <input
                          type="checkbox"
                          id="groupReportData"
                          name="groupReportData"
                          @change="onChangedGroupByReportData()"
                          v-model="groupByReportData"
                          style="
                            display: inline;
                            margin-left: 1rem;
                            vertical-align: middle;
                          "
                          class="hoverCheckbox"
                      /></label>
                    </div>
                  </li>
                </ul>

                <span id="test">
                  <CascadeSelect
                    id="columns"
                    v-model="selectedFieldsFromReport"
                    :options="fieldsFromReport"
                    optionLabel="column_name"
                    optionGroupLabel="table_name"
                    :optionGroupChildren="['columns']"
                    style="width: 11vw"
                    placeholder="&#8862; Columns"
                    v-on:change="addColumnsToTable($event.value)"
                    v-on:group-change="getTableNameAndTableID($event.value)"
                    :disabled="
                      userOnlyVisible && !this.gettersAllInfo.is_superuser
                    "
                  />
                </span>
                <CascadeSelect
                  v-model="selectedFiltersFromReport"
                  :options="filtersFromReport"
                  id="filters"
                  optionLabel="column_name"
                  optionGroupLabel="table_name"
                  :optionGroupChildren="['columns']"
                  style="width: 11vw"
                  placeholder="&#x022EE; Filter"
                  v-on:change="addChosenFilter($event.value)"
                  v-on:group-change="getFilterParentData($event.value)"
                  :disabled="
                    userOnlyVisible && !this.gettersAllInfo.is_superuser
                  "
                />
              </div>
            </div>
          </div>

          <div
            id="filterPlaceholderMediaQuery"
            style="
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              cursor: pointer;
              margin-top: 2px;
              width: 59vw;
              padding-left: 8px;
              cursor: default;
            "
          >
            <div
              v-for="(item, index) in getFiltersForRender()"
              :key="item.id"
              class="indexForHotkeys"
              style="padding-bottom:2px"
            >
              <button
                v-bind:class="setValueFromFilter(item)"
                v-bind:id="index + 1"
                @click="openFilterModal(item)"
              >
                <span style="text-align: center">
                  {{ item.column_name }}
                </span>
              </button>
              <span>
                <button v-bind:class="setValueFromFilterX(item)">
                  <i
                    v-on:click="deleteFilter(item)"
                    class="pi pi-times"
                    style="text-align: center"
                  ></i>
                </button>
              </span>
            </div>
            <div
              v-for="(item, index) in this.unusedFilters"
              :key="item.id"
              class="indexForHotkeys"
              style="padding-bottom:2px"
            >
              <button
                v-bind:class="setValueFromFilter(item)"
                v-bind:id="index + 1 + getFiltersForRender().length"
                @click="openFilterModal(item)"
              >
                <span style="text-align: center">
                  {{ item.column_name }}
                </span>
              </button>
              <span>
                <button v-bind:class="setValueFromFilterX(item)">
                  <i
                    v-on:click="deleteFilter(item)"
                    class="pi pi-times"
                    style="text-align: center"
                  ></i>
                </button>
              </span>
            </div>
          </div>
          <div style="width: 18vw; padding-right: 8px" id="thirdContainer">
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
              "
            >
              <div
                id="executeandOptionButtons"
                style="
                  top: 0.5rem;
                  bottom: 0.5rem;
                  left: 0;
                  right: 0;
                  margin-left: auto;
                  align-items: flex-end;
                  height: 25px;
                "
              >
                <button
                  class="executeButton"
                  :disabled="
                    userOnlyVisible && !this.gettersAllInfo.is_superuser
                  "
                  v-on:click="executeShowTableWithId()"
                >
                  <i class="pi pi-cog"></i>Execute
                </button>

                <ul class="dropdown">
                  <li class="dropdown" style="margin: 0 !important">
                    <button
                      class="optionsButton"
                      to=""
                      id="nav-link-dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="pi pi-sliders-h"></i>Options<i
                        class="pi pi-angle-down"
                      ></i>
                    </button>
                    <template v-if="schemasBody.length != 0">
                      <div class="dropdown-menu dropdownChanges">
                        <template
                          v-if="
                            this.gettersAllInfo.is_superuser ||
                              this.doesUserHavePermissionForEdit == 'true'
                          "
                        >
                          <a
                            class="dropdown-item"
                            style="color: white"
                            v-on:click="getNameAnddesc"
                            @click.prevent="
                              isSaveNewReportModalOpen = !isSaveNewReportModalOpen;
                              this.isSaveChangesToReportOpen = true;
                            "
                            >Save</a
                          >
                          <span>
                            <a
                              class="dropdown-item"
                              style="color: white"
                              @click.prevent="
                                isSaveNewReportModalOpen = !isSaveNewReportModalOpen;
                                this.clearInputNameAndDescription();
                                this.isSaveChangesToReportOpen = false;
                              "
                              >Save As</a
                            >
                          </span>
                        </template>
                        <template v-else>
                          <span>
                            <a
                              class="dropdown-item"
                              style="color: white"
                              @click.prevent="
                                isSaveNewReportModalOpen = !isSaveNewReportModalOpen;
                                this.clearInputNameAndDescription();
                                this.isSaveChangesToReportOpen = false;
                              "
                              >Save As</a
                            >
                          </span>
                        </template>
                      </div>
                    </template>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </span>

        <div class="whiteWrapper">
          <div ref="content">
            <div class="modalDownload" v-if="loading">
              <div class="modalDownloadDiv">
                <ProgressBar mode="indeterminate" />
              </div>
            </div>
            <div ref="table"></div>

            <div v-if="!loading" style="margin-right:2rem">
              <div
                style="
                  background-color: #f5f5f5;
            display:flex;
                  height: 45px;
                "
              >
                <div style="align-items:center">
                  <p
                    style="
                    flex-direction: row-reverse;
                    margin-left: 1rem;
                    font-weight: bold;
                    color: var(--mainBlue);
                  "
                  >
                    Report row count: {{ formatNumber(result.result_count) }}
                  </p>
                </div>

                <div
                  style="
                  background-color: #f5f5f5;
                  display: flex;
                  align-items: center;
                  height: 45px;
                  margin-left:auto;
                "
                >
                  <button
                    class="btn btn-regular"
                    style="margin-right:2rem;padding: 16px!important;
    align-items: center;
    line-height: 1px!important;height:unset!important"
                    @click="this.openTestDataMethod()"
                  >
                    report info
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <teleport v-if="filterModal == true" to="#modals">
        <filter-middleman
          v-bind:filters="this.filtersForModal"
          v-on:modalState="onModalStateChanged"
          @close="filterModal = false"
        />
      </teleport>
      <teleport v-if="openTestData == true" to="#modals">
        <test-data
          v-on:modalState="onOpenedTestData"
          v-bind:testDataPassed="this.tableTestDataFromEdit"
        />
      </teleport>
      <teleport v-if="isSaveNewReportModalOpen" to="#modals">
        <save-new-report
          v-on:modalState="onSaveNewReportChanged"
          v-bind:dataForTableCreationPassedObj="this.dataForTableCreationObj"
          v-bind:selectedChildButtonPassed="this.selectedSchemaID"
          v-bind:reportNamePassed="this.reportName"
          v-bind:reportDescriptionPassed="this.reportDescription"
          v-bind:reportGroupPassed="this.reportGroup"
          v-bind:userPassed="this.user"
          v-bind:reportTypePassed="this.reportType"
          v-bind:isSaveChangesToReportOpenPassed="
            this.isSaveChangesToReportOpen
          "
          v-bind:editedReportQueryIDPassed="this.editedReportQueryID"
          @close="isSaveNewReportModalOpen = false"
        />
      </teleport>
    </template>
  </div>
</template>

<script>
import $ from "jquery";
import CascadeSelect from "primevue/cascadeselect";
import Tabulator from "tabulator-tables";
import FilterMiddleman from "./FilterMiddleman.vue";
import SaveNewReport from "./SaveNewReport.vue";
import testData from "./testData.vue";
import { mapActions, mapGetters } from "vuex";
import jwtInterceptor from "../shared/jwtInterceptor";
import { API_URL } from "../../src/.env.js";
import moment from "moment";
import emitter from '@/mitt';

export default {
  name: "ReportBuilder",
  components: {
    CascadeSelect,
    FilterMiddleman,
    SaveNewReport,
    testData,
  },
  data() {
    return {
      modal1: false,
      buttonPlaceholder: "Choose dataset",
      buttonPlaceholderFromEdit: "",
      schemas: [],
      chosenSchemaID: 0,
      schemasBody: [],
      schemaIDFromEditedReport: 0,
      selectedSchemaForButtons: this.$route.params.id,
      canUserEdit: this.$route.params.userCanEdit,
      userCanDelete: this.$route.params.userCanDelete,
      fields: [],
      filters: [],
      fieldsFromReport: [],
      filtersFromReport: [],
      selectedFields: [],
      selectedFilters: "",
      selectedFieldsFromReport: [],
      selectedFiltersFromReport: [],
      newColumnsObj: {},
      selectedSchemaID: 0,
      loading: false,
      result: {
        result: [
          { "Company name": 0, "Company id": "0" },
          { "Company name": 0, "Company id": "0" },
          { "Company name": 0, "Company id": "0" },
          { "Company name": 0, "Company id": "0" },
          { "Company name": 0, "Company id": "0" },
          { "Company name": 0, "Company id": "0" },
          { "Company name": 0, "Company id": "0" },
          { "Company name": 0, "Company id": "0" },
          { "Company name": 0, "Company id": "0" },
          { "Company name": 0, "Company id": "0" },
        ],
      },
      tabulator: null, //variable to hold your table
      tableData: [],
      koloneTabele: [],
      greskaResponse: [],
      columnList: [],
      dataForTableCreation: "",
      dataForTableCreationObj: {
        limit: null,
        offset: null,
        having: [],
        aggregations: [],
      },
      report: [],
      greskaZaPrikazPrazneTabele: "",
      table_id: 0,
      table: "",
      field_id: 0,
      field: "",
      field_type: "",
      label: "",
      izabraneKoloneObj: {},
      columnsData: [],
      tableIdFromFilter: 0,
      tableDataFromFilter: "",
      order: "",
      isSaveNewReportModalOpen: false,
      modalSaveEdit: false,
      postGroupName: "",
      postDescriptionName: "",
      reportName: "",
      reportDescription: "",
      schemaSelected: 1,
      reportGroup: 0,
      reportType: 0,
      queryType: 1,
      user: 1,
      isSaveDisabled: false,
      userBy: this.getUserIdFromToken,
      prosledjeniReportIdZaPatch: 0,
      isFilterEmpty: false,
      filterModal: false,
      filterModalNotEqual: false,
      height: window.innerHeight + "px",
      width: 0,
      calendarValueFrom: null,
      calendarValueTo: null,
      valueForStringFilter: "",
      preuzetaVrednostZaFiltere: "",
      newFilterID: 1,
      izabraniFilter: {},
      filterType: 1,
      filtersForModal: [],
      unusedFilters: [],
      localFilterField: "",
      userOnlyVisible: false,
      groupByReportData: false,
      userMail: null,
      isButtonExecuted: false,
      isSaveChangesToReportOpen: false,
      editedReportQueryID: this.$route.query.report,
      doesUserHavePermissionForEdit: this.$route.query.edit,
      groupResponse: 0,
      openTestData: false,
      tableTestData: [],
      tableTestDataFromEdit: [],
      cellContextMenu: [{}],
    };
  },
  watch: {
    tableData: {
      handler: function(newData) {
        if(newData){
        this.tabulator.setData(newData);
        }

        if(this.columnsData){
        this.tabulator.setColumns(this.columnsData);}
      },
      deep: true,
    },
  },
  beforeMount() {
    if (this.$route.name === "reportbuilderedit") {
      this.getReportById();
    }
    this.getReportGroup();
  },


  created() {
    window.addEventListener("resize", this.eventForResizingDataTable);
    this.getUserPermissions();
    if (this.$route.name === "reportbuilder") {
      this.getSchemaDataWithoutBody();
    }

    if (this.$route.name === "reportbuilderedit") {
      this.schemaIDFromEditedReport = this.$route.params.id;
      this.showFieldsAndFiltersFromReport();
    }
  },
  unmounted() {
  
    emitter.off("linkClicked", this.handleLinkClicked);
    window.removeEventListener("resize", this.eventForResizingDataTable);
    //on unmounted report builder saves states of style to body tag
    //we remove the listener, but we also need to reset the body tag styles in order for
    //other components to be responsive again
    var x = document.getElementsByTagName("body");
    var i;
    for (i = 0; i < x.length; i++) {
      x[i].style.height = 100 + "%";
      x[i].style.width = 100 + "%";
      x[i].style.overflow = "unset";
    }
  },
  updated: function() {
    //catch bootstrap drodown when its open
    $(".dropdown").on("shown.bs.dropdown", function() {
      //when bootstrap dropdown is open, hide CascadeSelect dropdown
      $(".p-cascadeselect-panel.p-component").hide();
    });
  },
  mounted() {
    
    emitter.on("linkClicked", this.handleLinkClicked);
    this.moment = moment;

    //keydown listener for keyboard shortcuts
    document.addEventListener(
      "keydown",
      function(e) {
        if ((e.ctrlKey && e.key === "c") || (e.ctrlKey && e.key === "C")) {
          document.querySelector("#columns").click();
          setTimeout(
            () =>
              document
                .querySelectorAll(
                  "li.p-cascadeselect-item.p-cascadeselect-item-group>div"
                )[0]
                .click(),
            10
          );
          setTimeout(
            () =>
              document
                .querySelectorAll(
                  "li.p-cascadeselect-item.p-cascadeselect-item-group>div"
                )[0]
                .focus(),
            10
          );
        } else if (
          (e.ctrlKey && e.key === "f") ||
          (e.ctrlKey && e.key === "F")
        ) {
          e.preventDefault();
          e.stopImmediatePropagation();
          document.querySelector("#filters").click();

          setTimeout(
            () =>
              document
                .querySelectorAll(
                  "li.p-cascadeselect-item.p-cascadeselect-item-group>div"
                )[0]
                .click(),
            10
          );
          setTimeout(
            () =>
              document
                .querySelectorAll(
                  "li.p-cascadeselect-item.p-cascadeselect-item-group>div"
                )[0]
                .focus(),
            10
          );
        }
      },
      false
    );
    document.addEventListener(
      "keyup",
      function(e) {
        if (
          e.ctrlKey &&
          e.altKey &&
          e.key == document.getElementById(e.key).id
        ) {
          document.getElementById(e.key).click();
        }
      },
      false
    );
    //obican korisnik NIKADA ne moze da sacuva report kao PUBLIC. tako da ako user nije admin, report_type uvek mora biti 1
    if (!this.gettersAllInfo.is_superuser) {
      this.reportType = 1;
    }

    if (this.$route.name === "reportbuilder") {
      this.user = this.getUserIdFromToken;
    }
    //height and width of the body for resizing event
    this.height = window.innerHeight;
    this.width = window.innerWidth;
    this.cellContextMenu = [
      {
        label:
          "<span style='color:#38446F;font-size:12px'><i class='pi pi-copy' style='font-size:14px'></i>Copy Value</span>",
        action: function(e, cell) {
          var text = cell.getValue();
          navigator.clipboard.writeText(text);
        },
      },
    ];
    //instantiate Tabulator when element is mounted
    this.tabulator = new Tabulator(this.$refs.table, {
      data: this.tableData, //link data to table
      layout: "fitData",
      reactiveData: true,
      resizableColumns: true,
      columns: this.columnsData,
      movableColumns: true,
      height: "71vh",
      //define table columns
      dataSorting: this.onChangedOrderByColumns,
      columnMoved: this.onColumnMoved,
      columnResized: this.onColumnResized,
      placeholder: "",
      tooltips: true,
    });
    this.eventForResizingDataTable();
  },
  computed: {
    ...mapGetters("information", {
      gettersAllInfo: "getAllInfo",
    }),
    ...mapGetters("permissions", {
      gettersUserPermissions: "getUserPermissions",
    }),
    ...mapGetters("auth", {
      getUserIdFromToken: "getUserId",
    }),
  },
  methods: {
     handleLinkClicked() {
      // Do something when the link is clicked
      this.$router.push("/reportbuilder")
    


    
    },
    formatNumber(num) {
      return new Intl.NumberFormat("en-US").format(num);
    },
    openTestDataMethod() {
      this.openTestData = true;
    },
    getReportGroup() {
      //TODO: pitati saleta da li //?user_id=${this.user} da li ovaj opcioni query treba prilikom pozivanja ovog API-ja?
      jwtInterceptor
        .get(`${API_URL}/api/groups/?user_id=${this.getUserIdFromToken}`)
        .then((res) => {
          this.groupResponse = res.data[0].id;
          this.reportGroup = this.groupResponse;
        })
        .catch(() => {});
    },
    //ikonica prilikom ucitavanja stranice da se ne prikazuje
    hideArrowIconOnLoad() {
      if (this.loading) {
        return "hideArrowIconOnLoad";
      }
    },

    onChangedGroupByReportData: function() {
      this.dataForTableCreationObj.group_by = this.groupByReportData;
    },
    setOptionsDropdown() {
      if (this.columnsData.length != 0) {
        return "dropdownChangesOnNavBar";
      } else {
        return "disableOptionsButton";
      }
    },
    setAdditionalTableSettingsStyle() {
      if (this.columnsData.length != 0) {
        return "optionsButtonForTableSettings";
      } else {
        return "disabledOptionsButtonForTableSettings";
      }
    },
    changeTableVisibility(d) {
      var arrayBasedOnSchemaId = this.gettersUserPermissions.filter((obj) => {
        return obj.schema.id == d;
      });
      JSON.parse(JSON.stringify(arrayBasedOnSchemaId));
      JSON.parse(JSON.stringify(arrayBasedOnSchemaId));
      if (arrayBasedOnSchemaId.length != 0) {
        this.userOnlyVisible = arrayBasedOnSchemaId[0].visible_only;
      }
    },

    getNameAnddesc() {
      this.reportName = this.reports.name;
      this.reportDescription = this.reports.description;
    },
    clearInputNameAndDescription() {
      this.reportName = "";
      this.reportDescription = "";
    },
    //ova metoda provera da li postoji column_db_visible koji nekim korisnicima ne dozvoljava da vide odredjene
    //kolone
    changeColumnVisibility() {
      if (this.$route.name === "reportbuilder") {
        var filtered = [];
        this.fields.forEach((element) => {
          filtered = element.columns.filter(function(el) {
            return el.column_db_visible != false;
          });
          element.columns = filtered;
        });
      } else if (this.$route.name === "reportbuilderedit") {
        var filteredEdit = [];
        this.fieldsFromReport.forEach((element) => {
          filteredEdit = element.columns.filter(function(el) {
            return el.column_db_visible != false;
          });
          element.columns = filteredEdit;
        });
      }
    },

    getFiltersForRender: function() {
      //Vracamo jedinstvene vrednosti iz DTFCO zbog prikaza na listi primenjenih filtera
      var result = [];
      if (this.dataForTableCreationObj.filters == undefined) {
        return result; //Ako DTFCO jos nije popunjen (render pre biranja)
      } else {
        result = this.dataForTableCreationObj.filters.filter(
          (v, i, a) =>
            a.findIndex(
              (t) => t.column_id === v.column_id && t.field_id === v.field_id
            ) === i
        );
        if (this.$route.name === "reportbuilderedit") {
          this.filtersFromReport.forEach((element) => {
            element.columns.forEach((item) => {
              result.forEach((element) => {
                if (element.field_id == item.column_id) {
                  element.column_name = item.column_name;
                }
              });
            });
          });
        } else if (this.$route.name === "reportbuilder") {
          this.filters.forEach((element) => {
            element.columns.forEach((item) => {
              result.forEach((element) => {
                if (element.field_id == item.column_id) {
                  element.column_name = item.column_name;
                }
              });
            });
          });
        }

        return result;
      }
    },
    onOpenedTestData(value) {
      if (value.state == "Close") {
        this.openTestData = false;
      } else {
        this.openTestData = false;
      }
      if (document.activeElement.toString() == "[object HTMLButtonElement]") {
        document.activeElement.blur();
      }
    },
    onSaveNewReportChanged(value) {
      if (value.state == "Save") {
        //

        this.isSaveNewReportModalOpen = false;
      } else if (value.state == "Close") {
        this.isSaveNewReportModalOpen = false;
      } else if (value.state == "NewReportAddedSuccessfully") {
        //Kada se novi izvestaj uspesno doda, za slucaj da ima filtera bez vrednosti
        //moramo pocistiti takve vrednosti
        this.getFiltersForRender().splice(0, this.getFiltersForRender.length);
        this.unusedFilters = [];
        this.result = [];
        this.editedReportQueryID = value.patchID;
        this.isSaveNewReportModalOpen = false;
      } else if (value.state == "NewReportAddedUnSuccessfully") {
        this.unusedFilters = [];
        this.getFiltersForRender().splice(0, this.getFiltersForRender.length);
        this.greskaResponse = [];
        this.result = [];
        //za slucaj da na gresku treba da resetujemo celu tabelu
        //this.filtersForModal = [];
        // this.selectedSchemaID=0;
        //  this.tableData = [];
        // this.columnsData = [];
        this.isSaveNewReportModalOpen = false;
      }
      this.prosledjeniReportIdZaPatch = value.patchID;
    },
    onModalStateChanged(value) {
      //Da li je na modalu izabrano cuvanje ili odbacivanje.
      if (value.state == "Save") {
        //ove dve promenljive su bespotrebno deklarisane
        //da bih ja izvrsila neke promene
        this.localFilterField = value.id; //Preuzima field_ID

        //Ako je sa modala stiglo vise filtera.
        if (value.payload.length > 0) {
          // var localFilterFieldId = value.payload[0].field_id;
          //Logika za ne prikazivanje nekoriscenih filtera. Dakle, ako filter ima neicijalizovanu vrednost,
          //znaci da ne treba da se prikaze, kao i za slucaj da je isti kao vec dodati filter
          for (var l = this.unusedFilters.length - 1; l >= 0; --l) {
            if (this.unusedFilters[l].value == "neinicijalizovan") {
              if (this.localFilterField == this.unusedFilters[l].field_id) {
                this.unusedFilters.splice(l, 1);
              }
            }
          }
          //Ako smo menjali filter koji je vec bio u DTFCO
          if (
            this.dataForTableCreationObj.filters.some(
              (e) => e.field_id == this.localFilterField
            )
          ) {
            //trazimo prvo pojavljiivanje naseg filtera sa kojim radimo u DTFC

            var firstOccurance = this.dataForTableCreationObj.filters
              .map(function(e) {
                return e.field_id;
              })
              .indexOf(this.localFilterField);

            //Proleti kroz DTFCO
            for (
              var i = this.dataForTableCreationObj.filters.length - 1;
              i >= 0;
              --i
            ) {
              //Nadji taj filter
              if (
                this.dataForTableCreationObj.filters[i].field_id ==
                this.localFilterField
              ) {
                //Obrisi ga
                this.dataForTableCreationObj.filters.splice(i, 1);
              }
            }

            value.payload.reverse(); //Ovo radimo zato sto metoda ispod obratnim redosledom prolazi kroz listu.
            // Ubacujemo filter na odgovarajucu poziciju
            for (let k = 0; k < value.payload.length; k++) {
              // Ubaci  filtere iz modala u DTFCO
              this.dataForTableCreationObj.filters.splice(
                firstOccurance,
                0,
                value.payload[k]
              );
            }
          }
          //AKo je dodat filter koji do sada nije bio u DTFCO
          //DAKLE, KADA SE FILTER DODAJE PO PRVI PUT
          else {
            //TODO: kada se dodaje vise bez vrednosti pa se doda jednome
            //vrednost sacuvati mesto tog
            //Gurni ga pravo u DTFCO na kraj niza.

            value.payload.forEach((element) => {
              this.dataForTableCreationObj.filters.push(element);
            });
          }
        }
        //Ako su filteri obrisani iz modala, a ostali u DTFCO, obrisi ih i iz DTFCO
        else if (value.payload.length == 0) {
          //Ako je broj filtera == 0
          if (
            this.dataForTableCreationObj.filters.some(
              (e) => e.field_id == this.localFilterField
            )
          ) {
            //A ranije je postojao ovaj filter u DTFCo

            for (
              var j = this.dataForTableCreationObj.filters.length - 1;
              j >= 0;
              --j //Proleti kroz DTFCO. Ako se bude sredjivalo, mora ostati FOR petlja.
            ) {
              if (
                this.dataForTableCreationObj.filters[j].field_id ==
                this.localFilterField
              ) {
                //I za svaki elemenat DTFCO proveri da li je onaj koji je poslat modalu
                this.dataForTableCreationObj.filters.splice(j, 1); //A, ako jeste, obrisi ga.
              }
            }
            if (this.$route.name === "reportbuilder") {
              //I dodaj ga u unused filters, da bi ostao u UI.

              var tempForUnused = {};

              //Trazimo element po ID iz liste dostupnih mogucih filtera
              this.filters.forEach((element) => {
                element.columns.forEach((innerelement) => {
                  if (innerelement.column_id == this.localFilterField) {
                    tempForUnused = {
                      table_id: element.table_id,
                      table: element.table_db_name,
                      field_id: innerelement.column_id,
                      field: innerelement.column_db_name,
                      column_name: innerelement.column_name,
                      type: 1,
                      field_type: innerelement.column_db_type,
                      column_description: innerelement.column_description,
                      column_static: {
                        have_value: innerelement.column_static.have_value,
                        no_value: innerelement.column_static.no_value,
                      },
                      // this value is set, because of the metadata passed by addChosenFilter event. In order not to send it to DTFCObj, this is how we set it
                      value: "neinicijalizovan",
                      operator: "=",
                      not_equal: this.filterModalNotEqual,
                      column_db_grouped: innerelement.column_db_grouped,
                      column_db_visible: innerelement.column_db_visible,
                      case_insensitive: innerelement.case_insensitive,
                      column_db_select_values:
                        innerelement.column_db_select_values,
                      column_db_paste_values:
                        innerelement.column_db_paste_values,
                      option_type: 0,
                    };
                  }
                });
              });

              //Dodajemo u neiskoriscene..
              this.unusedFilters.push(tempForUnused);
            } else if (this.$route.name === "reportbuilderedit") {
              //I dodaj ga u unused filters, da bi ostao u UI.

              var tempForUnusedEdit = {};

              //Trazimo element po ID iz liste dostupnih mogucih filtera
              this.filtersFromReport.forEach((element) => {
                element.columns.forEach((innerelement) => {
                  if (innerelement.column_id == this.localFilterField) {
                    tempForUnusedEdit = {
                      table_id: element.table_id,
                      table: element.table_db_name,
                      field_id: innerelement.column_id,
                      field: innerelement.column_db_name,
                      column_name: innerelement.column_name,
                      type: 1,
                      field_type: innerelement.column_db_type,
                      column_description: innerelement.column_description,
                      column_static: {
                        have_value: innerelement.column_static.have_value,
                        no_value: innerelement.column_static.no_value,
                      },
                      // this value is set, because of the metadata passed by addChosenFilter event. In order not to send it to DTFCObj, this is how we set it
                      value: "neinicijalizovan",
                      operator: "=",
                      not_equal: this.filterModalNotEqual,
                      column_db_grouped: innerelement.column_db_grouped,
                      column_db_visible: innerelement.column_db_visible,
                      case_insensitive: innerelement.case_insensitive,
                      column_db_select_values:
                        innerelement.column_db_select_values,
                      column_db_paste_values:
                        innerelement.column_db_paste_values,
                    };
                  }
                });
              });

              //Dodajemo u neiskoriscene..
              this.unusedFilters.push(tempForUnusedEdit);
            }
          }
        }
      }

      this.filterModal = false; //Zatvori modal
      //this.unusedFilters = []; //I obrisi neiskoriscene filtere.
    },

    openFilterModal(it) {
      this.filtersForModal = [];

      //Ako se prosledjuje nedodat filter, dodaj ga u listu i posalji. u suprotnom nadji sva pojavljivanja
      //u DTFCO, spakuj ih u listu i posalji.

      if (this.unusedFilters.some((e) => e.field_id === it.field_id)) {
        this.filtersForModal.push(it);
      } else {
        this.dataForTableCreationObj.filters.forEach((element) => {
          if (
            element.field_id == it.field_id ||
            element.column_id == it.field_id
          ) {
            this.filtersForModal.push(element);
          }
        });
      }

      if (this.$route.name === "reportbuilderedit") {
        var filterSaApijaEdit = [];
        this.filtersFromReport.forEach((element) => {
          element.columns.forEach((item) => {
            if (item.column_id == this.filtersForModal[0].field_id) {
              filterSaApijaEdit.push(item);
            }
          });
        });
        this.filtersForModal.forEach((element) => {
          element.column_name = filterSaApijaEdit[0].column_name;
          element.column_description = filterSaApijaEdit[0].column_description;
          element.column_static = filterSaApijaEdit[0].column_static;
          element.field_type = filterSaApijaEdit[0].column_db_type;
        });
      } else if (this.$route.name === "reportbuilder") {
        var filterSaApija = [];
        this.filters.forEach((element) => {
          element.columns.forEach((item) => {
            if (item.column_id == this.filtersForModal[0].field_id) {
              filterSaApija.push(item);
            }
          });
        });

        this.filtersForModal.forEach((element) => {
          element.column_name = filterSaApija[0].column_name;
          element.column_description = filterSaApija[0].column_description;
          element.column_static = filterSaApija[0].column_static;
          element.field_type = filterSaApija[0].column_db_type;
        });
      }

      this.filterModal = true; //Dize modal
    },

    eventForResizingDataTable() {
      //handling resize...
      this.tabulator.redraw(true);
      this.height = window.innerHeight + "px";
      this.width = window.innerWidth;
      var x = document.getElementsByTagName("BODY");
      var i;
      for (i = 0; i < x.length; i++) {
        x[i].style.height = window.innerHeight - 5 + "px";
        x[i].style.width = window.innerWidth + "px";
        x[i].style.overflow = "hidden";
      }
      var tabulator = document.getElementsByClassName("tabulator");
      var iterator;
      for (iterator = 0; iterator < tabulator.length; iterator++) {
        tabulator[iterator].style.height = window.innerHeight - 200 + "px";
      }
    },
    close(e) {
      if (this.$refs?.content?.contains(e.target)) return;

      this.$emit("close");
      this.filterModal = false;

      if (document.activeElement.toString() == "[object HTMLButtonElement]") {
        document.activeElement.blur();
      }
    },
    apply(e) {
      if (this.$refs?.content?.contains(e.target)) return;
      this.filterModal = false;
      this.$emit("apply");
    },
    ...mapActions("permissions", {
      actionsGetUserPermissions: "getUserPermissions",
    }),
    async getUserPermissions() {
      await this.actionsGetUserPermissions();
    },
    ...mapActions("information", {
      actionsGetAllInfo: "getAllInfo",
    }),
    async getAllInfo() {
      await this.actionsGetAllInfo();
    },
    addCountryFlagBasedByChosenSchema(item) {
      if (item.flag === "un.png") {
        document.getElementById("dropdownButton").innerHTML =
          `<img src='/img/un.170e3caf.png'   width="20" height="15" />` +
          " " +
          item.label +
          `<i
                    class="pi pi-angle-down" style="text-align:right;margin-left:3.5rem;"
                  ></i>`;
      } else if (item.flag === "serbia.png") {
        document.getElementById("dropdownButton").innerHTML =
          `<img src='/img/serbia.cce756f1.png'   width="20" height="15" />` +
          " " +
          item.label +
          `<i
                    class="pi pi-angle-down" style="text-align:right;margin-left:7.5rem;"
                  ></i>`;
      } else if (item.flag === "bulgaria.png") {
        document.getElementById("dropdownButton").innerHTML =
          `<img src='/img/bulgaria.233ac6f1.png'   width="20" height="15" />` +
          " " +
          item.label +
          `<i
                    class="pi pi-angle-down" style="text-align:right;margin-left:6.3rem;"
                  ></i>`;
      } else {
        document.getElementById("dropdownButton").innerHTML =
          `<img src=''   width="20" height="15" />` +
          " " +
          item.label +
          `<i
                    class="pi pi-angle-down" style="text-align:right;margin-left:6.3rem;"
                  ></i>`;
      }
    },
    changeButtonPlaceholderFromEdit(itemBody) {
      if (itemBody.flag === "un.png") {
        document.getElementById("dropdownButton").innerHTML =
          `<img src='/img/un.170e3caf.png'   width="20" height="15" />` +
          " " +
          itemBody.label +
          `<i
                    class="pi pi-angle-down" style="text-align:right;margin-left:3.5rem;"
                  ></i>`;
      } else if (itemBody.flag === "serbia.png") {
        document.getElementById("dropdownButton").innerHTML =
          `<img src='/img/serbia.cce756f1.png'   width="20" height="15" />` +
          " " +
          itemBody.label +
          `<i
                    class="pi pi-angle-down" style="text-align:right;margin-left:7.5rem;"
                  ></i>`;
      } else if (itemBody.flag === "bulgaria.png") {
        document.getElementById("dropdownButton").innerHTML =
          `<img src='/img/bulgaria.233ac6f1.png'   width="20" height="15" />` +
          " " +
          itemBody.label +
          `<i
                    class="pi pi-angle-down" style="text-align:right;margin-left:6.3rem;"
                  ></i>`;
      } else {
        document.getElementById("dropdownButton").innerHTML =
          `<img src=''   width="20" height="15" />` +
          " " +
          itemBody.label +
          `<i
                    class="pi pi-angle-down" style="text-align:right;margin-left:6.3rem;"
                  ></i>`;
      }
    },
    setColumnSortOrder() {
      // http://tabulator.info/docs/4.0/sort
      //Redosled sortera ide po redosledu navedenih kolona za sortiranje

      var zeljeniSorteri = [];
      if(this.dataForTableCreationObj.length>0){
      this.dataForTableCreationObj.rows.forEach((element) => {
        if (element.order != "") {
          zeljeniSorteri.push({
            column: element.label,
            dir: element.order,
          });
        } else {
          /* tslint:disable:no-empty */
        }
      });
    }

      this.tabulator.setSort(zeljeniSorteri);
    },
    onColumnResized(column) {
      //TODO:update this on execute
      const definition = column._column.getDefinition();

      const width = column._column.width;

      // get defninitions of all columns
      const definitions = column._column.table.getColumnDefinitions();
      // find index of resized column in defninitions
      const i = definitions.findIndex((needle) => needle === definition);
      // refresh width
      definitions[i].width = width;

      column._column.table.setColumns(definitions);
    },
    onColumnMoved(column, columns) {
      var curColona = column._column.field;

      var index = this.getIndexFromLabel(curColona);

      var noviIndex = columns.findIndex((e) => e._column.field === curColona);

      this.moveArrayItemToNewIndex(
        this.dataForTableCreationObj.rows,
        index,
        noviIndex
      );
    },
    onChangedOrderByColumns(sorters) {
      //TODO startni undefinied

      if (sorters.length !== 0) {
        //bez ctrl, samo jedan sort
        if (sorters.length === 1) {
          this.dataForTableCreationObj.rows.forEach((element) => {
            if (element.label === sorters[0].field) {
              element.order = sorters[0].dir;
            } else {
              element.order = "";
            }
          });
        }
        //vise ordera selektovano sa ctrl
        else {
          // var index = this.getIndexFromLabel(sorters[0].field);

          // var curColona = this.dataForTableCreationObj.rows[index];

          // curColona.order = sorters[0].dir;
          sorters.forEach((element) => {
            var index = this.getIndexFromLabel(element.field);
            var curColona = this.dataForTableCreationObj.rows[index];
            curColona.order = element.dir;
          });
        }
      }
    },
    moveArrayItemToNewIndex: function(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr;
      //move index 1(b) to index 2(c)
      //console.log(moveArrayItemToNewIndex(["a","b","c","d"], 1, 2)); // returns ["a", "c", "b", "d"]
    },
    getIndexFromLabel(label) {
      return this.dataForTableCreationObj.rows
        .map((e) => e.label)
        .indexOf(label);
    },

    moveColumnToLeft(e, column) {
      var table = column.getTable(); //Dobavljamo tabelu, da bi mogli dobaviti kolone
      var colDefs = table.getColumnDefinitions(); //Dobavljamo definiciju kolona (ova metoda je dobra zato sto prikazuje azuriranu definiciju, za razliku od table.columnManager.columnsByFieldID.)

      var listaKolonaZaManipulaciju = []; //Lista stringova, naziva kolona.
      //jebenaListaKolona.length = 0; //Resetovali za svaki slucaj praznu tabelu, evidendno da nema razloga

      colDefs.forEach((element) => {
        //na osnovu definicije kolona, dobavi nazive kolona u njihovom izvornom redosledu.
        listaKolonaZaManipulaciju.push(element.title);
      });

      //console.log("konacno" + jebenaListaKolona);
      var curColona = column._column.field; //Dobavi naziv trenutne kolone na osnovu proksi objekta column
      var ind = listaKolonaZaManipulaciju.indexOf(curColona, 0); //Indeks trenutne kolone, odnosno pozicija u nizu.
      // console.log(ind);

      var index = this.getIndexFromLabel(curColona);

      if (index != 0) {
        //Da ne puca kada se nalazi u prvoj koloni.
        column.move(listaKolonaZaManipulaciju[ind - 1], false); //Premesti mi ovu kolonu, na jedno mesto iza prethodne kolone.
      }

      //this.getIndexFromLabel(curColona);

      // if (index >= 1) { Regulise redosled u DTFCO
      //   this.moveArrayItemToNewIndex(
      //     this.dataForTableCreationObj.rows,
      //     index,
      //     index - 1
      //   );
      // }
    },
    moveColumnToRight(e, column) {
      var table = column.getTable(); //Dobavljamo tabelu, da bi mogli dobaviti kolone
      var colDefs = table.getColumnDefinitions(); //Dobavljamo definiciju kolona (ova metoda je dobra zato sto prikazuje azuriranu definiciju, za razliku od table.columnManager.columnsByFieldID.)
      var listaKolonaZaManipulaciju = []; //Lista stringova, naziva kolona.
      colDefs.forEach((element) => {
        //na osnovu definicije kolona, dobavi nazive kolona u njihovom izvornom redosledu.
        listaKolonaZaManipulaciju.push(element.title);
      });
      var curColona = column._column.field; //Dobavi naziv trenutne kolone na osnovu proksi objekta column
      var ind = listaKolonaZaManipulaciju.indexOf(curColona, 0); //Indeks trenutne kolone, odnosno pozicija u nizu.

      var index = this.getIndexFromLabel(curColona);

      if (index != this.dataForTableCreationObj.rows.length - 1) {
        column.move(listaKolonaZaManipulaciju[ind + 1], true); //Premesti mi ovu kolonu, na jedno mesto posle naredne kolone.
      }

      // if (index < this.dataForTableCreationObj.rows.length - 1) {  Regulise redosled u DTFCO
      //   this.moveArrayItemToNewIndex(
      //     this.dataForTableCreationObj.rows,
      //     index,
      //     index + 1
      //   );
      // }
    },
    moveColumnToStart(e, column) {
      var table = column.getTable(); //Dobavljamo tabelu, da bi mogli dobaviti kolone
      var colDefs = table.getColumnDefinitions(); //Dobavljamo definiciju kolona (ova metoda je dobra zato sto prikazuje azuriranu definiciju, za razliku od table.columnManager.columnsByFieldID.)
      var listaKolonaZaManipulaciju = []; //Lista stringova, naziva kolona.
      colDefs.forEach((element) => {
        //na osnovu definicije kolona, dobavi nazive kolona u njihovom izvornom redosledu.
        listaKolonaZaManipulaciju.push(element.title);
      });
      var curColona = column._column.field; //Dobavi naziv trenutne kolone na osnovu proksi objekta column
      //var ind = jebenaListaKolona.indexOf(curColona, 0); //Indeks trenutne kolone, odnosno pozicija u nizu.
      column.move(listaKolonaZaManipulaciju[0], false); //Premesti mi ovu kolonu, na jedno mesto iza prethodne kolone.Tj prve u ovom slucaju

      column.move("col");
      var index = this.getIndexFromLabel(curColona);

      this.moveArrayItemToNewIndex(this.dataForTableCreationObj.rows, index, 0);
    },
    moveColumnToEnd(e, column) {
      var table = column.getTable(); //Dobavljamo tabelu, da bi mogli dobaviti kolone
      var colDefs = table.getColumnDefinitions(); //Dobavljamo definiciju kolona (ova metoda je dobra zato sto prikazuje azuriranu definiciju, za razliku od table.columnManager.columnsByFieldID.)
      var listaKolonaZaManipulaciju = []; //Lista stringova, naziva kolona.
      colDefs.forEach((element) => {
        //na osnovu definicije kolona, dobavi nazive kolona u njihovom izvornom redosledu.
        listaKolonaZaManipulaciju.push(element.title);
      });
      var curColona = column._column.field; //Dobavi naziv trenutne kolone na osnovu proksi objekta column
      //var ind = jebenaListaKolona.indexOf(curColona, 0); //Indeks trenutne kolone, odnosno pozicija u nizu.
      column.move(
        listaKolonaZaManipulaciju[listaKolonaZaManipulaciju.length - 1],
        true
      ); //Premesti mi ovu kolonu, na jedno mesto posle poslednje kolone.

      column.move("col");
      var index = this.getIndexFromLabel(curColona);

      this.moveArrayItemToNewIndex(
        this.dataForTableCreationObj.rows,
        index,
        this.dataForTableCreationObj.rows.length - 1
      );
    },
    deleteContextColumn(e, column) {
      column.delete();
      var curColona = column._column.field; //Dobavi naziv trenutne kolone na osnovu proksi objekta column

      var index = this.getIndexFromLabel(curColona);

      this.dataForTableCreationObj.rows.splice(index, 1);
      this.columnList.splice(index, 1);
    },
    removeSortFromColumn(e, column) {
      var curColona = column._column.field;

      var index = this.getIndexFromLabel(curColona);

      var curColonaFromData = this.dataForTableCreationObj.rows[index];
      curColonaFromData.order = "";

      //this.tabulator.setSort(curColona, "");

      this.setColumnSortOrder();
    },
    generateColumnData() {
      var headerContextMenu = [
        {
          label:
            "<span style='color:#38446F'><i class='pi pi-arrow-circle-left'></i> Move Column Left</span>",
          action: this.moveColumnToLeft,
        },
        {
          label:
            "<span style='color:#38446F'><i class='pi pi-arrow-circle-right'></i> Move Column Right</span>",
          action: this.moveColumnToRight,
        },
        {
          label:
            "<span style='color:#38446F'><i class='pi pi-angle-double-left'></i> Move Column To Start</span>",
          action: this.moveColumnToStart,
        },

        {
          label:
            "<span style='color:#38446F;'><i class='pi pi-angle-double-right'></i> Move Column to End</span>",
          action: this.moveColumnToEnd,
        },
        {
          separator: true,
        },
        {
          label:
            "<span style='color:red'><i class='pi pi-info-circle'></i>Delete column </span>",
          action: this.deleteContextColumn,
        },
        {
          label:
            "<span style='color:red'><i class='pi pi-info-circle'></i>Remove sort </span>",
          action: this.removeSortFromColumn,
        },
      ];

      this.columnsData = [];
      //Citamo labele iz DFTC i na osnovu njihovih vrednosti kreiramo uvek ispravan heder tabele
      this.dataForTableCreationObj.rows.forEach((element) => {
        var jednaKolona = {
          title: element["label"],
          field: element["label"],
          headerContextMenu: headerContextMenu,
          resizable: true,
          width: 200,

          contextMenu: this.cellContextMenu,
        };
        this.columnsData.push(jednaKolona);
      });
    },
    getReportById() {
      jwtInterceptor
        .get(`${API_URL}/api/reports/${this.$route.query.report}/`)
        .then((res) => {
          this.reports = res.data;
          this.prosledjeniReportIdZaPatch = this.reports.id;
          this.reportName = this.reports.name;
          this.reportDescription = this.reports.description;
          this.reportType = this.reports.type;
          this.queryType = this.reports.query_type;
          this.reportGroup = this.reports.group.id;
          //da li ovaj korisnik isti kao ulogovani korisnik
          this.user = this.reports.user.id;

          this.user_by = this.reports.user_by.id;
          this.userMail = this.reports.user.email;
          this.schema = this.reports.schema.id;

          this.dataForTableCreation = this.reports.data;

          this.dataForTableCreationObj = JSON.parse(this.dataForTableCreation);

          this.groupByReportData = this.dataForTableCreationObj.group_by;

          //Obican user ne sme da ima niti jedan public report. Pa cak i situaciji akda edituje public report, taj report prilikom cuvanja mora biti
          //public
          if (!this.gettersAllInfo.is_superuser) {
            this.reportType = 1;
          }
          this.getSchemaDataAndBody();
          this.showTableWithId();
        })
        .catch(() => {});
    },

    deleteFilter(objItem) {
      //brisemo filtere koji nemaju vrednostu, na osnovu toga da li je prosledjeni objItem(filter koji je kliknut) jednak jednom u nizu

      if (this.unusedFilters.some((e) => e.field_id === objItem.field_id)) {
        const index = this.unusedFilters.findIndex(
          (prop) => prop.field_id === objItem.field_id
        );
        this.unusedFilters.splice(index, 1);
      } else {
        //ovim istovremeno prolazimo kroz forEach i filter, gde brisemo sva pojavljivanja filtera u listi objekata DTFCO
        this.dataForTableCreationObj.filters
          .filter((x) => x.field_id === objItem.field_id)
          .forEach((x) =>
            this.dataForTableCreationObj.filters.splice(
              this.dataForTableCreationObj.filters.indexOf(x),
              1
            )
          );
      }
    },
    setValueFromFilter(item) {
      if (this.loading && !this.isButtonExecuted) {
        return "hideFilterWhileLoading";
      } else {
        if (this.unusedFilters.some((e) => e.field_id === item.field_id)) {
          return "filterInlineWithValue";
        } else {
          return "filterInline";
        }
      }
    },
    setValueFromFilterX(item) {
      if (this.loading && !this.isButtonExecuted) {
        return "hideFilterWhileLoading";
      } else {
        if (this.unusedFilters.some((e) => e.field_id === item.field_id)) {
          return "filterInlineWithValueX";
        } else {
          return "filterInlineX";
        }
      }
    },

    addChosenFilter: function(event) {
      if (document.activeElement.toString() == "[object HTMLButtonElement]") {
        document.activeElement.blur();
      }
      document.getElementById("columns").innerHTML =
        '<div class="p-hidden-accessible"><input type="text" readonly="" aria-haspopup="listbox" aria-expanded="true"></div><span class="p-cascadeselect-label p-placeholder">⊞ Columns</span><div class="p-cascadeselect-trigger" role="button" aria-haspopup="listbox" aria-expanded="true"><span class="p-cascadeselect-trigger-icon pi pi-chevron-down"></span></div>';

      document.getElementById("filters").innerHTML =
        '<div class="p-hidden-accessible"><input type="text" readonly="" aria-haspopup="listbox" aria-expanded="true"></div><span class="p-cascadeselect-label p-placeholder">&#x022EE; Filter</span><div class="p-cascadeselect-trigger" role="button" aria-haspopup="listbox" aria-expanded="true"><span class="p-cascadeselect-trigger-icon pi pi-chevron-down"></span></div>';

      var izabraniFilterObjNew = {};

      //ovde se kreira novi filter objekat. jako bitno, kada dobijem nove propertije, ne smao da ih sada menjam ovde
      //nego takodje i u tempForUnused
      izabraniFilterObjNew = {
        table_id: this.tableIdFromFilter,
        table: this.tableDataFromFilter,
        field_id: event.column_id,
        field: event.column_db_name,
        column_name: event.column_name,
        type: 1,
        field_type: event.column_db_type,
        column_description: event.column_description,
        column_static: {
          have_value: event.column_static.have_value,
          no_value: event.column_static.no_value,
        },
        // this value is set, because of the metadata passed by showUniqueFilterOnClick event. In order not to send it to DTFCObj, this is how we set it
        value: "neinicijalizovan",
        operator: "=",
        not_equal: this.filterModalNotEqual,
        column_db_grouped: event.column_db_grouped,
        column_db_visible: event.column_db_visible,
        case_insensitive: event.case_insensitive,
        column_db_select_values: event.column_db_select_values,
        column_db_paste_values: event.column_db_paste_values,
        option_type: 0,
      };

      if (
        !this.dataForTableCreationObj.filters.some(
          (e) => e.column_id === event.column_id
        ) &&
        !this.dataForTableCreationObj.filters.some(
          (e) => e.field_id === event.column_id
        ) &&
        !this.unusedFilters.some((e) => e.field_id === event.column_id) &&
        !this.unusedFilters.some((e) => e.column_id === event.column_id)
      ) {
        this.unusedFilters.push(izabraniFilterObjNew);
        // this.clearIndexForHotkey();
      }
    },

    getTableNameAndTableID: function(event) {
      if (document.activeElement.toString() == "[object HTMLButtonElement]") {
        document.activeElement.blur();
      }

      this.table_id = event.table_id;
      this.table = event.table_db_name;
    },
    getFilterParentData: function(event) {
      if (document.activeElement.toString() == "[object HTMLButtonElement]") {
        document.activeElement.blur();
      }
      this.tableIdFromFilter = event.table_id;
      this.tableDataFromFilter = event.table_db_name;
    },
    addColumnsToTable: function(event) {
      if (document.activeElement.toString() == "[object HTMLButtonElement]") {
        document.activeElement.blur();
      }
      document.getElementById("columns").innerHTML =
        '<div class="p-hidden-accessible"><input type="text" readonly="" aria-haspopup="listbox" aria-expanded="true"></div><span class="p-cascadeselect-label p-placeholder">⊞ Columns</span><div class="p-cascadeselect-trigger" role="button" aria-haspopup="listbox" aria-expanded="true"><span class="p-cascadeselect-trigger-icon pi pi-chevron-down"></span></div>';

      document.getElementById("filters").innerHTML =
        '<div class="p-hidden-accessible"><input type="text" readonly="" aria-haspopup="listbox" aria-expanded="true"></div><span class="p-cascadeselect-label p-placeholder">&#x022EE; Filter</span><div class="p-cascadeselect-trigger" role="button" aria-haspopup="listbox" aria-expanded="true"><span class="p-cascadeselect-trigger-icon pi pi-chevron-down"></span></div>';

      this.newColumnsObj = event;

      this.field_id = event.column_id;
      this.field = event.column_db_name;
      this.field_type = event.column_db_type;
      this.label = event.column_name;

      if (
        !this.dataForTableCreationObj.rows.some(
          (e) => e.field_id === this.newColumnsObj.column_id
        )
      ) {
        this.columnList.push(this.newColumnsObj);

        this.izabraneKoloneObj = {
          table_id: this.table_id,
          table: this.table,
          field_id: this.field_id,
          field: this.field,
          field_type: this.field_type,
          label: this.label,
          order: this.order,
        };

        this.dataForTableCreationObj.rows.push(this.izabraneKoloneObj);

        this.generateColumnData();

        this.tabulator.setColumns(this.columnsData); //Iscrtaj kolone nakon dodavanja nove kolone.
      }
    },

    getSchemaDataWithoutBody() {
      jwtInterceptor
        .post(`${API_URL}/api/metadata/schemas/`)
        .then((response) => {
          this.schemas = response.data.result;
          //sortiranje schema po redosledu iz baze(dovijanje greska kod Saleta)
          this.schemas.forEach((element) => {
            element.schemas.sort((a, b) => (a.order > b.order ? 1 : -1));
          });
        })
        .catch(() => {});
    },
    clearFilters() {
      this.selectedSchemaID = 0;
      this.tableData = [];
      this.columnsData = [];
      this.columnList = [];
    },
    getSchemaDataAndBody() {
      jwtInterceptor
        .post(`${API_URL}/api/metadata/schemas/`, {
          schema_id: this.$route.params.id,
        })
        .then((response) => {
          this.schemasBody = response.data.result;
          this.schemasBody.forEach((element) => {
            element.schemas.sort((a, b) => (a.order > b.order ? 1 : -1));
          });

          this.schemasBody.forEach((element) => {
            if (element.active === 1) {
              this.selectedSchemaForButtons = element.id;

              if (element.flag === "un.png") {
                document.getElementById("dropdownButton").innerHTML =
                  `<img src='/img/un.170e3caf.png'   width="20" height="15" />` +
                  " " +
                  element.label +
                  `<i
                    class="pi pi-angle-down" style="text-align:right;margin-left:3.5rem;"
                  ></i>`;
              } else if (element.flag === "serbia.png") {
                document.getElementById("dropdownButton").innerHTML =
                  `<img src='/img/serbia.cce756f1.png'   width="20" height="15" />` +
                  " " +
                  element.label +
                  `<i
                    class="pi pi-angle-down" style="text-align:right;margin-left:7.5rem;"
                  ></i>`;
              } else if (element.flag === "bulgaria.png") {
                document.getElementById("dropdownButton").innerHTML =
                  `<img src='/img/bulgaria.233ac6f1.png'   width="20" height="15" />` +
                  " " +
                  element.label +
                  `<i
                    class="pi pi-angle-down" style="text-align:right;margin-left:6.3rem;"
                  ></i>`;
              } else {
                document.getElementById("dropdownButton").innerHTML =
                  `<img src=''   width="20" height="15" />` +
                  " " +
                  element.label +
                  `<i
                    class="pi pi-angle-down" style="text-align:right;margin-left:6.3rem;"
                  ></i>`;
              }
              // this.buttonPlaceholderFromEdit=element.label;
            }
            element.schemas.forEach((childElement) => {
              if (childElement.active === 1) {
                this.selectedSchemaID = childElement.id;
              }
            });
          });
        })
        .catch(() => {});
    },
    // da li ovo da ide u save changes report, jer treba da preuzmem odavde ime i descripion
    //ili da posaljem ove podatke u child
    getDataFromNavbar(dropdownId) {
      this.tableData = [];
      this.columnsData = [];
      this.unusedFilters = [];
      this.filtersForModal = [];
      this.dataForTableCreationObj = [];
      this.result = [];
      this.unusedFilters = [];
      jwtInterceptor
        .post(`${API_URL}/api/reports/default/`, {
          schema_id: dropdownId.id,
        })
        .then((response) => {
          this.reports = response.data;
          this.reportName = this.reports.name;
          this.reportDescription = this.reports.description;
          this.dataForTableCreation = this.reports.data;
          this.dataForTableCreationObj = this.reports.data;
          this.groupByReportData = this.reports.data.group_by;
          this.dataForTableCreation = JSON.stringify(this.dataForTableCreation);

          this.showTableFromNavbar();
          this.showTableWithId();
        })
        .catch(() => {});
    },
    showColumnsAndFilters(dropdownId) {
      this.schemaIDFromEditedReport = dropdownId.id;
      jwtInterceptor
        .post(`${API_URL}/api/metadata/tables/`, {
          schema_id: dropdownId.id,
        })
        .then((response) => {
          this.fields = response.data.result.fields;
          this.filters = response.data.result.filters;
          this.fieldsFromReport = response.data.result.fields;
          this.filtersFromReport = response.data.result.filters;
          this.changeColumnVisibility();
        })
        .catch((error) => {
          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: error.response.data.error[0],
            life: 4000,
          });
        });
      this.fields = [];
      this.filters = [];
      this.fieldsFromReport = [];
      this.filtersFromReport = [];
    },
    showFieldsAndFiltersFromReport() {
      jwtInterceptor
        .post(`${API_URL}/api/metadata/tables/`, {
          schema_id: this.$route.params.id,
        })
        .then((response) => {
          this.fieldsFromReport = response.data.result.fields;

          this.filtersFromReport = response.data.result.filters;
          this.changeColumnVisibility();
        })
        .catch((error) => {
          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: error.response.data.error[0],
            life: 4000,
          });
        })
        .finally(() => this.changeTableVisibility(this.$route.params.id));
      this.fieldsFromReport = [];
      this.filtersFromReport = [];
    },
    showTableWithId() {
      this.loading = true;
      this.result = [];
      this.koloneTabele = [];

      jwtInterceptor
        .post(`${API_URL}/api/reports/test/`, {
          schema_id: this.schemaIDFromEditedReport,
          query_type: 1,
          data: this.dataForTableCreation,
        })
        .then((res) => {
          this.result = res.data;

          this.generateColumnData();

          this.tableData = this.result.result;
          this.tableTestDataFromEdit = res.data.result_stat;

          //this.result.result=[];
          this.greskaResponse = [];
          //var kolone=this.result.result[0]; //Prikupljamo koje sve kolone
        })
        .catch((error) => {
          this.loading = false;
          this.greskaResponse = error.response.data;

          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: this.greskaResponse,
            life: 4000,
          });

          // Manage errors if found any
        })
        .finally(() => ((this.loading = false), this.setColumnSortOrder()));
      this.greskaResponse = [];
      this.koloneTabele = [];
    },
    showTableFromNavbar() {
      this.loading = true;
      this.result = [];
      this.koloneTabele = [];
      jwtInterceptor
        .post(`${API_URL}/api/reports/test/`, {
          schema_id: this.selectedSchemaID,
          query_type: 1,
          data: this.dataForTableCreation,
        })
        .then((res) => {
          this.result = res.data;
          this.tableTestData = res.data.result_stat;

          this.generateColumnData();
          this.tableData = this.result.result;
          this.greskaResponse = [];
          //var kolone=this.result.result[0]; //Prikupljamo koje sve kolone
        })
        .catch((error) => {
          this.loading = false;
          this.koloneTabele = []; //Resetujemo kolone na prazno da se ne bi prikazivale prilikom otvaranja drugog reporta
          //this.result.result=[];
          this.greskaResponse = [];
          this.result = {
            result: [],
          };
          //var kolone=this.result.result[0]; //Prikupljamo koje sve kolone
          if (this.dataForTableCreation.rows == undefined) {
            this.greskaResponse =
              "Default report table is empty. Please, choose custom columns to proceed";
          } else {
            this.greskaResponse = error.response.data.data[0];
          }
          this.greskaZaPrikazPrazneTabele = error.response.statusText;

          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: this.greskaResponse,
            life: 4000,
          });

          // Manage errors if found any
        })
        .finally(() => ((this.loading = false), this.setColumnSortOrder()));
      this.greskaResponse = [];
      this.koloneTabele = [];
      this.result = [];
    },
    executeShowTableWithId() {
      this.isButtonExecuted = true;
      if (this.columnsData.length != 0) {
        this.loading = true;
        this.dataForTableCreationObj.filters.forEach((element) => {
          delete element.column_name;
          delete element.column_description;
          delete element.column_static;
          delete element.testProperty2;
          delete element.testProperty;
        });
        this.dataForTableCreation = JSON.stringify(
          this.dataForTableCreationObj
        );

        jwtInterceptor
          .post(`${API_URL}/api/reports/test/`, {
            schema_id: this.schemaIDFromEditedReport,
            query_type: 1,
            data: this.dataForTableCreation,
          })
          .then((res) => {
            if (
              document.activeElement.toString() == "[object HTMLButtonElement]"
            ) {
              document.activeElement.blur();
            }

            this.result = res.data;

            this.generateColumnData();

            this.tableData = this.result.result;
            this.tableTestDataFromEdit = this.result.result_stat;

            this.greskaResponse = [];
            //var kolone=this.result.result[0]; //Prikupljamo koje sve kolone
            //if(document.activeElement.toString() == '[object HTMLButtonElement]'){ document.activeElement.blur()}
          })
          .catch((error) => {
            var greskaZaExecute = "";
            if (
              JSON.stringify(error.response.data) ===
              '{"data":["Unsupported data schema for querying report."]}'
            ) {
              greskaZaExecute = "Please add columns to your table to proceed.";
            } else {
              greskaZaExecute = error.response.data;
            }
            this.$toast.add({
              severity: "info",
              summary: "Error Message",
              detail: greskaZaExecute,
              life: 4000,
            });

            // Manage errors if found any
          })
          .finally(() => ((this.loading = false), this.setColumnSortOrder()));
        this.greskaResponse = [];
        this.koloneTabele = [];
      } else {
        this.$toast.add({
          severity: "info",
          summary: "Error Message",
          detail: "Execute doesn't have data",
          life: 4000,
        });
      }
    },
    executeShowTableWithoutId() {
      this.isButtonExecuted = true;
      if (this.columnsData.length != 0) {
        this.loading = true;
        this.dataForTableCreationObj.filters.forEach((element) => {
          delete element.column_name;
          delete element.column_description;
          delete element.column_static;
          delete element.testProperty2;
          delete element.testProperty;
        });

        //Logika za ne prikazivanje nekoriscenih filtera. Dakle, ako filter ima neicijalizovanu vrednost,
        //znaci da ne treba da se prikaze, kao i za slucaj da je isti kao vec dodati filter

        this.dataForTableCreation = JSON.stringify(
          this.dataForTableCreationObj
        );

        jwtInterceptor

          .post(`${API_URL}/api/reports/test/`, {
            schema_id: this.selectedSchemaID,
            query_type: 1,
            data: this.dataForTableCreation,
          })
          .then((res) => {
            this.dataForTableCreationObj.filters.forEach((element) => {
              delete element.column_name;
              delete element.column_description;
              delete element.column_static;
              delete element.testProperty2;
              delete element.testProperty;
            });
            if (
              document.activeElement.toString() == "[object HTMLButtonElement]"
            ) {
              document.activeElement.blur();
            }
            this.result = res.data;

            this.generateColumnData();
            this.tableTestData = this.result.result_stat;

            this.tableData = this.result.result;
            this.koloneTabele = []; //Resetujemo kolone na prazno da se ne bi prikazivale prilikom otvaranja drugog reporta
            //this.result.result=[];
            this.greskaResponse = [];
          })
          .catch((error) => {
            var greskaZaExecute = "";

            if (
              JSON.stringify(error.response.data) ===
              '{"data":["Unsupported data schema for querying report."]}'
            ) {
              greskaZaExecute = "Please add columns to your table to proceed";
            } else {
              greskaZaExecute = error.response.data;
            }
            this.$toast.add({
              severity: "info",
              summary: "Error Message",
              detail: greskaZaExecute,
              life: 4000,
            });

            // Manage errors if found any
          })
          .finally(() => ((this.loading = false), this.setColumnSortOrder()));
        this.greskaResponse = [];
      } else {
        this.$toast.add({
          severity: "info",
          summary: "Error Message",
          detail: "Please add columns to your table to proceed.",
          life: 4000,
        });
      }
    },
  },
  beforeUnmount(){
    emitter.off("linkClicked", this.handleLinkClicked);

  },
  
};
</script>

<style></style>
