<template>
  <div class="modalDelete" @click="$emit('close')">
    <div class="modalDeleteDiv"   @click.stop="close">
      <div ref="content" class="modal__details" @keydown.esc="emitToParentReport('Close')" tabindex="0">
        <h1 class="modal__title">Delete report</h1>
        <h4 class="modal__description">
          Are you sure you want to delete this item?
        </h4>

        <div
          style="
                              display: flex;
                              margin: 0.5rem;
                              align-items: center;
                              justify-content: flex-end;
                            "
        >
          <div style="justify-content: center">
            <button
              class="btn btn-destructive btnTabel"
              style="font-size: 1.25rem"
              @click.stop="emitToParentReport('Yes')"
            >
              Yes
            </button>

            <button
              class="btn btn-regular btnTabel hoverTabelBut"
              style="
                                  font-size: 1.25rem;

                                  margin-left: 0.5rem;
                                "
              @click.stop="emitToParentReport('Close')"
            >
              No
            </button>
          </div>
        </div>

        <slot />
      </div>

      <a @click.stop="emitToParentReport('Close')" class="link-2"></a>
    </div>
  </div>
</template>
<script>
//import jwtInterceptor from "../shared/jwtInterceptor";

//import { API_URL } from "../../src/.env.js";
export default {
  name: "DeleteModal",
  components: {},
  props: ["item"],
  data: function() {
    return {
      testObj: this.item,
      listener: (e) => {
        e.key === "Escape" && this.emitToParentReport("Close");
      },
      childEvent: "keydown",
      childEventForEnter:"keydown",
      listenerForEnter:(e)=>{ (e.ctrlKey && e.keyCode==13) && this.emitToParentReport("Yes")}
    };
  },
  
  computed: {},
  methods: {
    emitToParentReport: function(selectedValuesState) {
      var result = {};
      result.state = selectedValuesState;
      if (document.activeElement.toString() == "[object HTMLButtonElement]") {
        document.activeElement.blur();
      }
      this.$emit("selectedValuesState", result);
    },
  },
  mounted() {
    window.addEventListener(this.childEvent, this.listener);
    window.addEventListener(this.childEventForEnter,this.listenerForEnter);
  },
  unmounted() {
    window.removeEventListener(this.childEvent, this.listener);
    window.removeEventListener(this.childEventForEnter,this.listenerForEnter);
  },
};
</script>
<style>
.modalDelete {
  display: inherit; /* Hidden by default */
  position: fixed; /* Stay in place front is invalid - may break your css so removed */
  padding-top: 100px; /* Location of the box - don't know what this does?  If it is to move your modal down by 100px, then just change top below to 100px and remove this*/
  left: 0;
  right: 0; /* Full width (left and right 0) */
  top: 0;
  bottom: 0; /* Full height top and bottom 0 */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.3);
  /* opacity: 0.5; */

  z-index: 9999; /* Sit on top - higher than any other z-index in your site*/
  text-align: center;
}
.modalDeleteDiv {
  background-color: white;
  width: 50%;
  position: absolute;
  left: 50%;
  top: 50% !important;
  border-radius: 0.5rem;

  transform: translate(-50%, -50%);
}
</style>
