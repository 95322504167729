<template>
  <div>
    <template v-if="isLargeScreen">
      <div class="navbar-links staticNav hidden-xs-down">
        <div class="links">
          <router-link class="logo-header" to="/">
            <img src="../../assets\segdb-logo-blue.svg" />
          </router-link>
        </div>

        <div class="header-title">
          <li>
            <router-link
              class="nav-link"
              style="margin-top: 0 !important; color: #5652cb"
              v-bind:to="{ path: '/home', hash: '#home' }"
            ></router-link>
          </li>
          <li class="nav-items-static">
            <router-link
              class="nav-link"
              style="margin-top: 0 !important; color: #5652cb"
              v-bind:to="{ path: '/home', hash: '#home' }"
              ><span @click="handleArrowClick">Home</span></router-link
            >
          </li>
          <li class="nav-items-static">
            <router-link
              class="nav-link"
              style="margin-top: 0 !important; color: #5652cb"
              v-bind:to="{ path: '/home', hash: '#about' }"
              ><span @click="handleArrowClick">About us</span></router-link
            >
          </li>
          <li class="nav-items-static">
            <router-link
              class="nav-link"
              style="margin-top: 0 !important; color: #5652cb"
              v-bind:to="{ path: '/home', hash: '#datasets' }"
              ><span @click="handleArrowClick">Datasets</span></router-link
            >
          </li>
          <li class="nav-items-static">
            <router-link
              class="nav-link"
              style="margin-top: 0 !important; color: #5652cb"
              v-bind:to="{ path: '/home', hash: '#industries' }"
              ><span @click="handleArrowClick">Industries</span></router-link
            >
          </li>
          <li class="nav-items-static">
            <router-link
              class="nav-link"
              style="margin-top: 0 !important; color: #5652cb"
              v-bind:to="{ path: '/home', hash: '#customDatasets' }"
              ><span @click="handleArrowClick">Custom datasets</span></router-link
            >
          </li>
        </div>
        <div class="logout">
          <li
            class="nav-items-static signInButton"
            style="display: inline-block"
          >
            <router-link
              style="
                text-align: center;
                text-decoration: none;
                color: white !important;
                display: block;
                line-height: 43px;
                align-items: center;
              "
              to="/app/login"
              >Log in</router-link
            >
          </li>

          <li
            class="nav-items-static requestADemoButton"
            style="display: inline-block"
          >
            <router-link
              style="
                text-decoration: none;
                color: white !important;
                display: block;
                line-height: 43px;
              "
              v-bind:to="{ path: '/home', hash: '#contact' }"
              ><span @click="handleArrowClick">Request a demo</span></router-link
            >
          </li>
        </div>
      </div>
    </template>
    <!--Navbar-->
    <template v-else>
      <nav class="navbar staticNav" style="border-bottom: none !important">
        <!-- Navbar nav -->
        <ul class="navbar-nav ml-auto">
          <!-- ml-auto = margin-left: auto -->
          <li
            style="display: grid; place-items: center"
            class="nav-item active"
          >
            <router-link
              class="logo-header"
              v-bind:to="{ path: '/home', hash: '#home' }"
            >
              <img
                style="height: 25px !important; display: block; margin: 0 auto"
                src="../../assets\segdb-logo-blue.svg"
              />
            </router-link>
          </li>
        </ul>
        <div class="logout">
          <li class="nav-item dropdown">
            <a
              class="nav-link"
              data-toggle="dropdown"
              id="nav-link-dropdown-toggle"
            >
              <i class="pi pi-bars"></i>

              <!-- ml-5= margin-left: 0.5rem (5px) -->
            </a>
            <div
              class="dropdown-menu dropdown-menu-center"
              aria-labelledby="nav-link-dropdown-toggle"
            >
              <a class="dropdown-item" @click="goToDiv('home')">Home</a>
              <a class="dropdown-item" @click="goToDiv('about')">About us</a>
              <a class="dropdown-item" @click="goToDiv('datasets')">Datasets</a>

              <a class="dropdown-item" @click="goToDiv('customDatasets')">
                Custom Datasets
                <!-- float-right = float: right -->
              </a>
            </div>
          </li>
          <li
            class="nav-items-static signInButton"
            style="display: inline-block"
          >
            <router-link
              style="
                text-align: center;
                text-decoration: none;
                color: white !important;
                display: block;
                line-height: 43px;
                align-items: center;
              "
              to="/app/login"
              >Log in</router-link
            >
          </li>

          <li
            class="nav-items-static requestADemoButton"
            style="display: inline-block"
          >
            <router-link
              style="
                text-decoration: none;
                color: white !important;
                display: block;
                line-height: 43px;
              "
              v-bind:to="{ path: '/home', hash: '#contact' }"
              >Request a demo</router-link
            >
          </li>
        </div>
      </nav>
    </template>
  </div>
</template>
<!--/.Navbar-->


<!--/.Navbar-->


<script>
import emitter from '@/mitt';

export default {
  setup() {},
  watch: {
    $route(to) {
      if (to.hash) {
        const element = document.getElementById(to.hash.slice(1));
        if (element) element.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
  mounted() {},
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  data() {
    return {
      isLargeScreen: true,
    };
  },

  computed: {},
  methods: {
    handleArrowClick() {
      emitter.emit("showArrow");    },
    handleResize() {
      this.isLargeScreen = window.innerWidth >= 768;
    },
    goToDiv(id) {
      const element = document.getElementById(id);
      if (element) element.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>
<style scoped>
/* Icon 1 */

.animated-icon1,
.animated-icon2,
.animated-icon3 {
  width: 30px;
  height: 20px;
  position: relative;
  margin: 0px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.animated-icon1 span,
.animated-icon2 span,
.animated-icon3 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.animated-icon1 span {
  background: #e65100;
}

.animated-icon2 span {
  background: #e3f2fd;
}

.animated-icon3 span {
  background: #f3e5f5;
}

.animated-icon1 span:nth-child(1) {
  top: 0px;
}

.animated-icon1 span:nth-child(2) {
  top: 10px;
}

.animated-icon1 span:nth-child(3) {
  top: 20px;
}

.animated-icon1.open span:nth-child(1) {
  top: 11px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.animated-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.animated-icon1.open span:nth-child(3) {
  top: 11px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

/* Icon 3*/

.animated-icon2 span:nth-child(1) {
  top: 0px;
}

.animated-icon2 span:nth-child(2),
.animated-icon2 span:nth-child(3) {
  top: 10px;
}

.animated-icon2 span:nth-child(4) {
  top: 20px;
}

.animated-icon2.open span:nth-child(1) {
  top: 11px;
  width: 0%;
  left: 50%;
}

.animated-icon2.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.animated-icon2.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.animated-icon2.open span:nth-child(4) {
  top: 11px;
  width: 0%;
  left: 50%;
}

/* Icon 4 */

.animated-icon3 span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.animated-icon3 span:nth-child(2) {
  top: 10px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.animated-icon3 span:nth-child(3) {
  top: 20px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.animated-icon3.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 0px;
  left: 8px;
}

.animated-icon3.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

.animated-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 21px;
  left: 8px;
}

.signInButton {
  width: 96px;
  height: 43px;
  background-color: #5652cb;
  border-radius: 5px;
  color: white;
  margin-top: 0;
  margin-right: 15px;
}

.requestADemoButton {
  width: 152;
  height: 43px;
  background-color: #f89d00;
  border-radius: 5px;
  color: white;
  margin-top: 0;
}

.staticNav {
  display: flex;
  height: 64px;
  /* optional; just for demo */
  background: #f0f4f8;
  position: sticky;
  top: 0;
  z-index: 100;
}

.links {
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.links a {
  margin: 0 5px;
  text-decoration: none;
}

.header-title {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logout {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1rem;
}

a {
  text-decoration: none;
}

.nav-item.active > .nav-link,
.nav-item.show > .nav-link {
  color: white;
  background-color: var(--mainYellow);
}

.nav-item.active > .nav-link:hover,
.nav-item.show > .nav-link:hover {
  color: white;
  background-color: var(--mainYellow);
}

@media all and (max-width: 1020px) {
  .nav-items a {
    font-size: 0.75rem;
  }
}
@media (max-width: 767px) {
  /* styles for screens with width < 768px go here */
  .dropdown:not(.dropup):not(.dropright):not(.dropleft).show
    .dropdown-menu.dropdown-menu-center,
  .dropdown:not(.dropup):not(.dropright):not(.dropleft).toggle-on-hover:hover
    .dropdown-menu.dropdown-menu-center {
    transform: translate(-50%, 108%);
  }
  .dropdown-menu {
    background-color: #5652cb;
  }
  .dropdown-item {
    color: white;
  }
  .nav-item.active > .nav-link[data-v-0c6e84ab],
  .nav-item.show > .nav-link[data-v-0c6e84ab] {
    color: unset;
    background-color: unset;
  }
  .nav-item.active > .nav-link[data-v-0c6e84ab],
  .nav-item.show > .nav-link[data-v-0c6e84ab] {
    color: unset;
    background-color: unset;
  }
  .signInButton {
    width: 65px;
    height: 36px;
    margin-right: 5px;
  }
  .signInButton > a {
    line-height: 38px;
    font-size: 8px;
  }
  .requestADemoButton > a {
    line-height: 38px;
    font-size: 8px;
  }
  .requestADemoButton {
    width: 95px;
    height: 36px;
  }
}
</style>
