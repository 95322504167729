


<template>
<div  @click="$emit('close')" tabindex="-1" >
  
      <template v-if="this.columnType == 'text'">
        <filter-modal-string v-bind:filters="this.middlemanFilters" />
      </template>
      <template v-else-if="this.columnType == 'date'">
        <filter-modal-date v-bind:filters="this.middlemanFilters" />
      </template>
      <template v-else-if="this.columnType=='number'">
        <filter-modal-int v-bind:filters="this.middlemanFilters" />
        </template>
         <template v-else-if="this.columnType=='decimal'">
        <filter-modal-dec v-bind:filters="this.middlemanFilters" />
        </template>
  </div>
</template>
<script>
import FilterModalString from "./FilterModalString.vue";
import FilterModalDate from "./FilterModalDate.vue";
import FilterModalInt from "./FilterModalInt.vue";
import FilterModalDec from "./FilterModalDec.vue"
export default {
  name: "FilterMiddleman",
  components: { FilterModalString, FilterModalDate, FilterModalInt, FilterModalDec },
  props: ["filters"],
  data: function() {
    return {
      middlemanFilters: this.filters,
      columnType:''
    };
  },
    mounted() {

      this.columnType=this.middlemanFilters[0].field_type;
     
  },
};
</script>
