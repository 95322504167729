<template>
  <div class="pasteValuesModal">
    <div class="pasteValuesDiv" ref="content">
      <div
        class="modal__detail"
        style="border-bottom: 2px solid var(--mainBlue)"
      >
        <div>
          <h1 class="modal__title" style="margin-bottom:0!important"></h1>
          <div class="margin-bottom">
            <label>Paste values</label>

            <textarea
              class="
																				form-control
																				
																			"
              style="height:500px;margin-left:10px;margin-right:10px;width:96.5%!important;resize: none"
              placeholder="Paste values"
              v-model="bindedValueString"
              ref="contentTextArea"
            />
            <!-- List by breaks: {{ listByBreaks }} -->
          </div>
        </div>
        <img
          src="../assets/broom.png"
          id="clearPasteValueBox"
          style="width:2rem;height:2rem;float:right;margin-right: 2rem;
    margin-top: -0.5rem;
    vertical-align: bottom;"
          @click="clearPasteValuesBox()"
        />
      </div>

      <div
        style="
          display: flex;
          margin: 0.5rem;
          flex-direction: row;
          align-items: stretch;
          align-content: stretch;
        "
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            flex: 2 0 0;
            justify-content: left;
          "
        >
          <div style="display: flex; justify-content: right">
            <div style="align-self: center">
              <button
                class="btn btn-regular"
                style="margin-right: 1rem; margin-left: 0.5rem; "
                @click.stop="emitToParentFilter('Save')"
              >
                Apply
              </button>
            </div>
          </div>
        </div>

        <div style="display: flex; justify-content: flex-end">
          <div style="align-self: center">
            <button
              class="btn btn-regular btnTabel"
              @click.stop="emitToParentFilter('Close')"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>

      <a class="link-2" @click.stop="emitToParentFilter('Close')"></a>
    </div>
  </div>
</template>
<script>
export default {
  name: "{PasteValues}",
  components: {},
  props: ["modelValue"],
  data: function() {
    return {
      selectedValues: [],
      search: "",
      pasteValues: [],
      bindedValue: this.modelValue, //salje se lista
      bindedValueString: "",
      listener: (e) => {
        e.key === "Escape" && this.emitToParentFilter("Close");
      },
      childEvent: "keydown",
      listenerForEnter: (e) => {
        e.ctrlKey && e.keyCode == 13 && this.emitToParentFilter("Save");
      },
    };
  },
  computed: {
    // listByBreaks(){
    // return this.bindedValue.split("\n");
    // }
  },
  created() {
    this.convertListToString();
    this.$nextTick(() => {
      this.$refs.contentTextArea.focus();
    });
  },
  methods: {
    convertListToString() {
      this.bindedValueString = this.bindedValue.join("\n");
    },
    emitToParentFilter: function(pasteValuesState) {
      var result = {};
      result.state = pasteValuesState;

      result.payload = this.bindedValueString.split("\n");

      this.$emit("pasteValuesState", result);
    },
    clearPasteValuesBox() {
      this.bindedValueString = "";
      this.$nextTick(() => {
        this.$refs.contentTextArea.focus();
      });
    },
  },
  mounted() {
    window.addEventListener(this.childEvent, this.listener);
    window.addEventListener(this.childEvent, this.listenerForEnter);
  },
  unmounted() {
    window.removeEventListener(this.childEvent, this.listener);
    window.removeEventListener(this.childEvent, this.listenerForEnter);
  },
};
</script>
<style>
#clearPasteValueBox:hover {
  cursor: pointer;
}
.pasteValuesModal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place front is invalid - may break your css so removed */
  padding-top: 100px; /* Location of the box - don't know what this does?  If it is to move your modal down by 100px, then just change top below to 100px and remove this*/
  left: 0;
  right: 0; /* Full width (left and right 0) */
  top: 0;
  bottom: 0; /* Full height top and bottom 0 */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.3); /* Black w/ opacity */
  z-index: 9999; /* Sit on top - higher than any other z-index in your site*/
  text-align: center;
}
.pasteValuesDiv {
  background-color: white;
  width: 50%;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 1rem;
  margin-bottom: 2rem;

  transform: translate(-50%, -50%);
}
li {
  list-style-type: none;
}
</style>
