import axios from "axios";
import store from '../store/index';
import { API_URL } from "../../src/.env.js";




const jwtInterceptor = axios.create({

}

);


jwtInterceptor.interceptors.request.use((config) => {
  //menjanje
    const authData = store.getters["auth/getAuthData"];
    // const authData = store.getters["auth/getAuthData"].access;

    if (authData == null) {
      //authData==false
      return config;
    }
    //ovde bilo samo authData
    config.headers.common["Authorization"] = `Bearer ${authData.access}`;
   
    return config;
  });
  
  jwtInterceptor.interceptors.response.use(
    (response) => {
      return response;
   
    },
    async (error) => {
     
      if (error.response.status===401) {
       
        const authData = store.getters["auth/getAuthData"];
      
        const payload = {
          
          refresh: authData.refresh,
        
         
        };
       
  
        var response = await axios.post(
          `${API_URL}/api/profile/token/refresh/`,
          
         
          payload
          
        );

        response.data.refresh=authData.refresh;
        await store.commit("auth/saveTokenData", response.data);
      
        error.config.headers[
            "Authorization"
          ] =`Bearer ${response.data.access}`;
       
        return axios(error.config);
      } else {



     
        return Promise.reject(error);
      }
     
    }
    
  );
  
  export default jwtInterceptor;


  