<template>
  <div
    class="modalSave"
    @click="$emit('close')"
  >
    <div class="modalSaveDiv" ref="content" id="modalDate" @click.stop="close">
      <div
        class="modal__detail"
        style="border-bottom: 2px solid var(--mainBlue)"
      >
        <div>
          <h1 class="modal__title" style="margin-bottom:0!important">
            {{ this.columnName }}
          </h1>
          <p
            style="text-align: left; padding-left: 1rem;font-size:1.5rem;font-weight:bold;margin-bottom:1rem;margin-top:1rem!important"
          >
            {{ this.columnDescription }}
          </p>
        </div>
        <div>
          <p
            style="
                              margin: 0 !important;
                              text-align: left;
                              padding-left: 1rem;
                              font-style: italic;
                              color:#9C9C9C;
                                margin-bottom:0!important;
                            "
          >
            number of records with value:
            <span style="font-weight:bold">
              {{ formatNumber(this.columnStaticNoValue) }}</span
            >
          </p>
          <p
            style="
                              margin: 0 !important;
                              text-align: left;
                              padding-left: 1rem;
                              font-style: italic;
                              color:#9C9C9C
                            "
          >
            number of records without value:
            <span style="font-weight:bold">
              {{ formatNumber(this.columnStaticNoValue) }}</span
            >
          </p>
        </div>
        <hr style="width:97%;text-align:left;background-color:#9C9C9C" />
        <div class="container-fluid">
          <div
            class="row"
            style="margin-top: 1rem;
    margin-bottom: 1rem;"
          >
            <div class="col-4">
              <div
                class="container"
                style="text-align: left; margin-left: 1rem;"
              >
                <label for="include" style="font-size:10px!important"
                  >Include fields without values</label
                >

                <input
                  type="checkbox"
                  id="include"
                  name="include"
                  @change="onChangedFieldWithoutValues()"
                  v-model="fieldWithoutValues"
                  style="display: inline; margin-left: 1rem;vertical-align: middle"
                  class="hoverCheckbox"
                />
              </div>
            </div>
            <div class="col-6">
              <button
                class="btn btn-regular"
                style="float:right"
                @click="addNewFilterToLocalFiltersList()"
              >
                Add
              </button>
            </div>
            <div class="col-2">
              <div>
                <button
                  class="btn btn-danger"
                  style="float:right;margin-right:1rem;"
                  @click="clearAllFilterValues()"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="container-fluid"
          id="customScrollBar"
          style="height: 35rem;
    overflow: scroll;
    overflow-x: hidden;"
        >
          <h3
            style="text-align: left; margin-left: 1rem;margin-bottom:0!important"
          >
            Selected values
          </h3>

          <div class="row" style="margin-left: 0.25rem">
            <ul style="width: 100%; list-style-type: decimal">
              <li
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: left;
                  align-items: center;
                  margin-right: 1rem;
                  
                "
                v-for="(item, index) in this.localFilters"
                v-bind:key="item.id"
                v-show="
                  !(item.value == 'neinicijalizovan') &&
                    !(item.value[0] == null)
                "
              >
                <div
                  class="col-3"
                  v-show="item.option_type != 1 && item.option_type != 2"
                >
                  <date-picker
                    :masks="masks"
                    v-model="item.value[0]"
                    @dayclick="onInputValueChanged(index)"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300;"
                        :value="inputValue"
                        v-on="inputEvents"
                        style="width: 15rem!important;"
                        placeholder="from"
                      />
                    </template>
                  </date-picker>
                </div>
                <div v-show="item.option_type == 1" class="col-3">
                  <button
                    class="btn btn-regular"
                    style="width:8rem;margin-left:-5rem"
                    @click="openSelectedValuesModal(item)"
                  >
                    ...
                    <teleport v-if="item.testProperty2" to="#modals">
                      <selected-values
                        v-on:selectedValuesState="onSelectedValuesChange"
                        v-bind:selectedValues="selectedValues"
                        :modelValue="item.value"
                      />
                    </teleport>
                  </button>
                </div>
                <div v-show="item.option_type == 2" class="col-3">
                  <button
                    class="btn btn-regular"
                    @click="openPasteValuesModal(item)"
                    style="width:8rem;margin-left:-5rem"
                  >
                    ...
                    <teleport v-if="item.testProperty" to="#modals">
                      <paste-values
                        v-on:pasteValuesState="onPasteValuesChange"
                        :modelValue="item.value"
                      />
                    </teleport>
                  </button>
                </div>
                <div class="col-3">
                  <date-picker
                    v-show="item.option_type != 1 && item.option_type != 2"
                    v-model="item.value[1]"
                    :masks="masks"
                    @dayclick="onInputValueChanged(index)"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        style="width: 15rem!important;"
                        class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300;"
                        :value="inputValue"
                        v-on="inputEvents"
                        placeholder="to"
                      />
                    </template>
                  </date-picker>
                </div>
                <div class="col-3" style="margin-right:-1.5rem">
                  <select
                    :key="index"
                    @change="onFilterTypeChanged($event, item)"
                  >
                    <option disabled value="">Please select one</option>
                    <option
                      :selected="setDefaultSelectValue(item, '1')"
                      value="1"
                    >
                      Value range</option
                    >
                    <option
                      v-if="columnVisibility2"
                      :selected="setDefaultSelectValue(item, '2')"
                      value="2"
                      >Select values
                    </option>
                    <option
                      v-if="pasteValues"
                      :selected="setDefaultSelectValue(item, '3')"
                      value="3"
                      >Paste values</option
                    >
                  </select>
                </div>
                <div class="col-2" style="margin-top:0.5rem;margin-left:-1rem;">
                  <label for="exclude" style="font-size:10px!important"
                    >Exclude</label
                  >
                  <input
                    type="checkbox"
                    v-model="item.not_equal"
                    style="display: inline; vertical-align: middle;margin-left:1rem"
                    class="hoverCheckbox"
                  />
                </div>
                <div class="col-1">
                  <button
                    class="btn btn-danger"
                    style="
    font-size:1.15rem;"
                    @click="removeSelectedFilterFromLocalFiltersList(item)"
                  >
                    Remove
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        style="
                          display: flex;
                          margin: 0.5rem;
                          flex-direction: row;
                          align-items: stretch;
                          align-content: stretch;
                        "
      >
        <div
          style="
                            display: flex;
                            flex-direction: column;
                            flex: 2 0 0;
                            justify-content: left;
                          "
        >
          <div style="display: flex; justify-content: right">
            <div style="align-self: center">
              <button
                class="btn btn-regular"
                style="
                                  margin-right: 1rem;
                                  margin-left: 0.5rem;
                                "
                @click.stop="emitToParent('Save')"
              >
                Apply
              </button>
            </div>
          </div>
        </div>

        <div style="display: flex; justify-content: flex-end">
          <div style="align-self: center">
            <button
              class="btn btn-regular btnTabel"
              @click.stop="emitToParent('Close')"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>

      <a class="link-2" @click.stop="emitToParent('Close')"></a>
    </div>
  </div>
</template>

<script>
import { DatePicker } from "v-calendar";
import PasteValues from "./PasteValues.vue";
import SelectedValues from "./SelectedValues.vue";
import jwtInterceptor from "../shared/jwtInterceptor";

import { API_URL } from "../../src/.env.js";
export default {
  name: "FilterModalDate",
  props: ["filters"],
  components: { DatePicker, PasteValues, SelectedValues },
  data: function() {
    return {
      masks: {
        type: "string",
        mask: "YYYY-MM-DD",
        input: ["YYYY-MM-DD"], // Uses 'iso' if missing
      },
      localFilters: this.filters,
      fieldWithoutValues: false,
      newFilterValueFrom: null,
      newFilterValueTo: null,
      columnName: "",
      columnDescription: "",
      columnStaticHaveValue: "",
      columnStaticNoValue: "",
      tmpObjIni: {},
      fieldIdForEmit: "",
      columnDbType: "",
      columnVisibility: false,
      columnVisibility2: false,
      pasteValues: false,
      selectedValues: [],
      listener: (e) => {
        e.key === "Escape" && this.emitToParent("Close");
      },
      eventt: "keydown",
    
      listenerForEnter:(e)=>{ (e.ctrlKey && e.keyCode==13) && this.emitToParent("Save")}
    };
  },
  created() {
    this.getDescriptiveDataFromFilter();
    this.getSelectedValuesByColumnId();
  },
  mounted() {
    this.localFilters.forEach((element) => {
      if (element.value[0] == null) {
        this.fieldWithoutValues = true;
      }
    });
    window.addEventListener(this.eventt, this.listener);
    window.addEventListener(this.eventt, this.listenerForEnter);

  },
  methods: {
    formatNumber(num) {
      return new Intl.NumberFormat("en-US").format(num);
    },
    onSelectedValuesChange(value) {
      window.addEventListener(this.eventt, this.listener);
      window.addEventListener(this.eventt, this.listenerForEnter);

      if (value.state == "Close") {
        this.localFilters.forEach((element) => {
          element.testProperty2 = false;
        });
      } else if (value.state == "Save") {
        const index = this.localFilters
          .map((e) => e.testProperty2)
          .indexOf(true);
        this.localFilters[index].value = value.payload;

        this.localFilters.forEach((element) => {
          element.testProperty2 = false;
        });
      }
    },
    openSelectedValuesModal(item) {
      item.testProperty2 = true;
      window.removeEventListener(this.eventt, this.listener);
      window.removeEventListener(this.eventt, this.listenerForEnter);

      if (document.activeElement.toString() == "[object HTMLButtonElement]") {
        document.activeElement.blur();
      }
    },
    openPasteValuesModal(item) {
      window.removeEventListener(this.eventt, this.listener);
      window.removeEventListener(this.eventt, this.listenerForEnter);
      item.testProperty = true;
         if (document.activeElement.toString() == "[object HTMLButtonElement]") {
        document.activeElement.blur();
      }
    },
    onPasteValuesChange(value) {
            window.addEventListener(this.eventt, this.listener);
            window.addEventListener(this.eventt, this.listenerForEnter);

      if (value.state == "Close") {
        this.localFilters.forEach((element) => {
          element.testProperty = false;
        });
      } else if (value.state == "Save") {
        const index = this.localFilters
          .map((e) => e.testProperty)
          .indexOf(true);
        this.localFilters[index].value = value.payload;
        this.localFilters.forEach((element) => {
          element.testProperty = false;
        });
      }
    },
    removeEmptyValues() {
   
      this.localFilters = this.localFilters.filter(el => {
   return el.value[0] !== "" || el.value[1] !== "";
});
    },
    getDescriptiveDataFromFilter() {
      this.columnName = this.localFilters[0].column_name;
      this.columnDescription = this.localFilters[0].column_description;
      this.columnStaticHaveValue = this.localFilters[0].column_static.have_value;
      this.columnStaticNoValue = this.localFilters[0].column_static.no_value;
      this.tmpObjIni = this.localFilters[0];
      this.fieldIdForEmit = this.localFilters[0].field_id;
      this.columnDbType = this.localFilters[0].field_type;
      this.columnVisibility = this.localFilters[0].column_db_visible;
      this.columnVisibility2 = this.localFilters[0].column_db_select_values;
      this.pasteValues = this.localFilters[0].column_db_paste_values;
    },
    emitToParent: function(modalState) {
      if (modalState === "Save") {
        for (var i = this.localFilters.length - 1; i >= 0; --i) {
          if (
            this.localFilters[i].value == "neinicijalizovan" ||
            this.localFilters[i].value == "neinicijalizovani"
          ) {
            this.localFilters.splice(i, 1);

            this.localFilters[i].value.join("").split("");
          }
        }
      }
      var result = {};
      result.state = modalState;

      this.removeEmptyValues();
      result.payload = this.localFilters;
      result.id = this.fieldIdForEmit;
      result.type = this.columnDbType;

      this.$parent.$emit("modalState", result);
    },
    onChangedFieldWithoutValues: function() {
      if (this.fieldWithoutValues) {
        var tmpObj = {};
        tmpObj = JSON.parse(JSON.stringify(this.tmpObjIni));
        tmpObj.value = [null];
        tmpObj.operator = "=";
        tmpObj.type = 1;
        tmpObj.not_equal = false;
        this.localFilters.push(tmpObj);
      } else {
        var noviIndex = this.localFilters.findIndex((e) => e.value[0] == null);

        this.localFilters.splice(noviIndex, 1);
      }
    },
    onInputValueChanged(index) {
      if (this.localFilters[index].value[0] === "") {
        var date = new Date(this.localFilters[index].value[1]);

        date =
          date.getFullYear().toString() +
          "-" +
          (date.getMonth() > 8
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "-" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate());

        this.localFilters[index].value[0] = "";
        this.localFilters[index].value[1] = date;

        this.localFilters[index].type = 1;
        this.localFilters[index].operator = "<=";
      } else if (this.localFilters[index].value[1] === "") {
        var date2 = new Date(this.localFilters[index].value[0]);
        date2 =
          date2.getFullYear().toString() +
          "-" +
          (date2.getMonth() > 8
            ? date2.getMonth() + 1
            : "0" + (date2.getMonth() + 1)) +
          "-" +
          (date2.getDate() > 9 ? date2.getDate() : "0" + date2.getDate());

        this.localFilters[index].value[1] = "";
        this.localFilters[index].value[0] = date2;

        this.localFilters[index].type = 1;
        this.localFilters[index].operator = ">=";
      } else {
        var date3 = new Date(this.localFilters[index].value[0]);
        date3 =
          date3.getFullYear().toString() +
          "-" +
          (date3.getMonth() > 8
            ? date3.getMonth() + 1
            : "0" + (date3.getMonth() + 1)) +
          "-" +
          (date3.getDate() > 9 ? date3.getDate() : "0" + date3.getDate());
        var date4 = new Date(this.localFilters[index].value[1]);
        date4 =
          date4.getFullYear().toString() +
          "-" +
          (date4.getMonth() > 8
            ? date4.getMonth() + 1
            : "0" + (date4.getMonth() + 1)) +
          "-" +
          (date4.getDate() > 9 ? date4.getDate() : "0" + date4.getDate());
        this.localFilters[index].value[0] = date3;
        this.localFilters[index].value[1] = date4;

        this.localFilters[index].operator = "=";
        this.localFilters[index].type = 2;
      }
    },

    addNewFilterToLocalFiltersList() {
      var tmpObj = {};
      tmpObj = JSON.parse(JSON.stringify(this.tmpObjIni));
      tmpObj.value = ["", ""];

      tmpObj.not_equal = false;
      this.localFilters.push(tmpObj);

      if (document.activeElement.toString() == "[object HTMLButtonElement]") {
        document.activeElement.blur();
      }
    },
    clearAllFilterValues() {
      if (this.fieldWithoutValues) {
        var index = this.localFilters.map((a) => a.value).indexOf(null);
        this.localFilters.splice(index, 1);
        this.fieldWithoutValues = false;
      }
      this.localFilters = [];
      this.newFilterValueFrom = null;
      this.newFilterValueTo = null;
    },
    removeSelectedFilterFromLocalFiltersList(i) {
      var index = this.localFilters.map((a) => a.value).indexOf(i.value);
      this.localFilters.splice(index, 1);
    },
    setDefaultSelectValue(item) {
      if (item.option_type == 1 || item.option_type == 2) {
        return true;
      } else {
        return false;
      }
    },
    onFilterTypeChanged: function(e, item) {
      var filterType = e.target.value;
      if (filterType == 1) {
        item.type = 1;
        item.option_type = 0;
        item.value = ["", ""];
      } else if (filterType == 2) {
        item.type = 3;
        item.option_type = 1;
        item.value = ["neinicijalizovani"];
      } else if (filterType == 3) {
        item.type = 3;
        item.option_type = 2;
        item.value = ["neinicijalizovani"];
      }
    },
    getSelectedValuesByColumnId() {
      jwtInterceptor
        .post(`${API_URL}/api/metadata/column/values/`, {
          column_id: this.fieldIdForEmit,
        })
        .then((response) => {
          this.selectedValues = response.data.result;
          for (var i = 0; i < this.selectedValues.length; i++) {
            if (
              this.selectedValues[i] === null ||
              this.selectedValues[i] === " " ||
              this.selectedValues[i] === ""
            ) {
              var index = this.selectedValues.indexOf(null);
              this.selectedValues.splice(index, 1);
            }
          }
        })
        .catch(() => {});
    },
    unmounted() {
      window.removeEventListener(this.eventt, this.listener);
      window.removeEventListener(this.eventt, this.listenerForEnter);

    },
  },
};
</script>
<style>
.p-datepicker-year {
  display: inline !important;
}
.p-datepicker-month {
  display: inline !important;
}
.vc-container {
  font-size: 0.8rem !important;
  width: 220px !important;
  /* --day-content-height: 50px !important;
  --day-content-width: 50px !important; */
}
@media all and (max-width: 1616px) {
  .vc-container {
    width: 100%;
  }
}
.vc-arrows-container {
  padding: 3px 10px !important;
}
.vc-pane {
  min-width: 220px !important;
}
.vc-pane-container {
  width: 18rem !important;
}
.vc-day {
  min-height: 20px !important;
  padding-top: 4px;
}
.vc-day-content {
  min-height: 0 !important;
  font-size: 0.8 !important;
  width: 0 !important;
  height: 0 !important;
}
.vc-day-layer {
  top: 2px !important;
  bottom: -2px !important;
}

.vc-svg-icon {
  width: 10px !important;
  height: 20px !important;
}
.vc-header {
  padding: 1px !important;
}
.vc-title {
  font-size: 1.5rem !important;
  padding: 0 !important;
}
.vc-weeks {
  padding: 0 !important;
  padding-bottom: 5px !important;
  min-width: 0 !important;
}
.vc-weekday {
  padding: 0 !important;
  font-size: 1rem !important;
  margin: 0 !important;
  padding-top: 0.25rem !important;
}

.vc-container {
  --text-sm: 16px !important;
}
.vc-highlight {
  width: 30px !important;
  height: 30px !important;
  border-radius: 0 !important;
}
</style>
