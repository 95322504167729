<template>
  <div class="statisticValues">
    <div class="statisticValuesDiv" ref="content">
      <div
        class="modal__detail"
        style="border-bottom: 2px solid var(--mainBlue)"
      >
        <div>
          <h1 class="modal__title" style="margin-bottom: 0 !important"></h1>
          <div class="margin-bottom">
            <label>Report statistic</label>

            <table class="styled-table-header">
              <thead>
                <tr>
                  <th style="white-space: nowrap; text-align: left; width: 20%">
                    Column Name
                  </th>
                  <th style="white-space: nowrap; text-align: left; width: 60%">
                    Column value
                  </th>
                  <th style="white-space: nowrap; text-align: left; width: 10%">
                    Value count
                  </th>
                  <th style="white-space: nowrap; text-align: left; width: 10%">
                    Value %
                  </th>
                </tr>
              </thead>
            </table>
            <div
              class="customScrollForHomeTable"
              v-if="this.testDataHeader.length !== 0 && !this.showTopTenValues"
            >
              <table class="styled-table-info" style="width: 100%">
                <tbody id="myTable">
                  <tr
                    v-for="item in this.testDataHeader"
                    :key="item.id"
                    id="testData"
                    style="white-space: pre-linestyle"
                    :class="[item.header ? 'whitebg' : 'graybg']"
                  >
                    <td
                      style="vertical-align: top; text-align: left; width: 20%"
                    >
                      {{ item.header }}
                    </td>
                    <td
                      style="
                        font-style: italic;
                        white-space: pre-line;
                        text-align: left;
                        width: 60%;
                      "
                    >
                      {{ item.value }}
                    </td>
                    <td
                      style="
                        font-style: italic;
                        white-space: pre-line;
                        text-align: left;
                        width: 10%;
                      "
                    >
                      <span v-if="item.count != 0">
                        {{ formatNumber(item.count) }}
                      </span>
                    </td>
                    <td
                      style="
                        font-style: italic;
                        white-space: pre-line;
                        text-align: left;
                        width: 10%;
                      "
                    >
                      {{ item.valuePercentage }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="customScrollForHomeTable"
              v-if="this.testDataHeader.length !== 0 && this.showTopTenValues"
            >
              <table
                class="styled-table-info customValueTable"
                style="width: 100%"
              >
                <tbody id="myTable">
                  <template
                    v-for="(categoryData, category) in categories"
                    :key="category"
                  >
                    <template
                      v-for="(data, index) in categoryData"
                      :key="data.value"
                    >
                      <tr
                        v-if="index === 0 && category !== 'Other'"
                        class="graybg"
                      >
                        <td colspan="4"></td>
                      </tr>
                      <tr style="white-space: pre-linestyle" class="whitebg">
                        <td
                          style="
                            vertical-align: top;
                            text-align: left;
                            width: 20%;
                          "
                        >
                          {{ category }}
                        </td>
                        <td
                          style="
                            font-style: italic;
                            white-space: pre-line;
                            text-align: left;
                            width: 60%;
                          "
                        >
                          {{ data.value }}
                        </td>
                        <td
                          style="
                            font-style: italic;
                            white-space: pre-line;
                            text-align: left;
                            width: 10%;
                          "
                        >
                          {{ formatNumber(data.count) }}
                        </td>
                        <td
                          style="
                            font-style: italic;
                            white-space: pre-line;
                            text-align: left;
                            width: 10%;
                          "
                          v-if="!isNaN(data.valuePercentage)"
                        >
                          {{ formatNumber(data.valuePercentage) }}
                        </td>
                        <td v-else-if="isNaN(data.ValuePecentage)">0</td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </table>
            </div>
            <!-- <div class="container">
                <div class="row">
                  <div class="col-sm-4" id="testDataID">
                    <li>Column Name</li>
                    <ul
                      v-for="item in this.testDataHeader"
                      :key="item.id"
                      id="testData"
                    >
                      <li>{{ item.header }}</li>
                    </ul>
                  </div>
                  <div class="col-sm-4" id="testDataID">
                    <li>Column value</li>
                    <ul v-for="item in this.valueTestData" :key="item.id">
                      <li>{{ item }}</li>
                    </ul>
                  </div>
                  <div class="col-sm-4" id="testDataID">
                    <li>Value count</li>
                    <ul v-for="item in this.countTestData" :key="item.id">
                      <li>{{ item }}</li>
                    </ul>
                  </div>
                </div>
                <div class="row"></div>
              </div> -->
          </div>
        </div>
      </div>

      <div
        style="
          display: flex;
          margin: 0.5rem;
          flex-direction: row;
          align-items: stretch;
          align-content: stretch;
        "
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            flex: 2 0 0;
            justify-content: left;
          "
        >
          <div style="display: flex; justify-content: right">
            <div style="align-self: center">
              <button
                v-if="!this.showTopTenValues"
                class="btn-revertRegular"
                style="margin-right: 1rem; margin-left: 0.5rem"
                @click.stop="calculateTop10Values()"
              >
                Show top 10 values
              </button>
              <button
                v-if="this.showTopTenValues"
                class="btn-revertRegular"
                style="margin-right: 1rem; margin-left: 0.5rem"
                @click.stop="showTopTenValues = false"
              >
                Show all
              </button>
              <button
                class="btn btn-regular"
                style="margin-right: 1rem; margin-left: 0.5rem"
                @click.stop="emitToParent('Close')"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <a class="link-2" @click.stop="emitToParent('Close')"></a>
    </div>
  </div>
</template>
<script>
// import $ from "jquery";
export default {
  props: ["testDataPassed"],
  data: function () {
    return {
      listener: (e) => {
        e.key === "Escape" && this.emitToParent("Close");
      },
      childEvent: "keydown",
      childEventForEnter: "keydown",
      listenerForEnter: (e) => {
        e.ctrlKey && e.keyCode == 13 && this.emitToParent("Save");
      },
      testData: this.testDataPassed,
      testDataHeader: [],
      valueTestData: [],
      countTestData: [],
      currentValue: "",
      lastValue: "",
      valuePercentageCalculated: "",
      showTopTenValues: false,
      categories: {},
      totalCount: {},
      previousCategory: "",
    };
  },
  computed: {
    isNaN: function (value) {
      return isNaN(value);
    },
  },
  mounted() {
    window.addEventListener(this.childEvent, this.listener);
    window.addEventListener(this.childEventForEnter, this.listenerForEnter);

    for (var key in this.testData) {
      var jednaKolona = { header: key, value: this.testData[key] };
      this.lastValue = key;
      for (var value in this.testData[key]) {
        var novaKolona = {
          header: key,
          value: value,
          count: jednaKolona.value[value],
          valuePercentage: this.valuePercentageCalculated,
        };
        this.testDataHeader.push(novaKolona);
        this.testDataHeader.sort(function (a, b) {
          return a.header.localeCompare(b.header) || b.count - a.count;
        });
      }
      // Create a map to store the counts for each header
      const countsByHeader = new Map();

      for (const object of this.testDataHeader) {
        const { header, count } = object;

        // If we have already seen this header, add the current count to the total
        // Otherwise, add the header to the map with the current count as the value
        if (countsByHeader.has(header)) {
          countsByHeader.set(header, countsByHeader.get(header) + count);
        } else {
          countsByHeader.set(header, count);
        }
      }

      // Iterate over the objects again and update the valuePercentage property
      for (const object of this.testDataHeader) {
        const { header, count } = object;
        object.valuePercentage = (
          (count / countsByHeader.get(header)) *
          100
        ).toFixed(2);
      }
    }
    this.addEmptyRows();
  },
  methods: {
    calculateTop10Values() {
      this.showTopTenValues = !this.showTopTenValues;
      this.categories = {};
      // Create an object to store the categories

      // Iterate through the data and group the data by category
      for (let i = 0; i < this.testDataHeader.length; i++) {
        let category = this.testDataHeader[i].header;
        if (!this.categories[category]) {
          this.categories[category] = [];
        }
        this.categories[category].push(this.testDataHeader[i]);
      }

      // Iterate through the categories and sort the data by count
      for (let category in this.categories) {
        this.categories[category] = this.categories[category].filter(
          (x) => x.header !== null
        );

        let sortedData = this.categories[category].sort(function (a, b) {
          return a.header?.localeCompare(b.header) || b.count - a.count;
        });

        // Get the top 10 values and calculate the sum of all other values
        let top10Values = sortedData.slice(0, 10);
        let sumOtherValues = 0;
        for (let i = 10; i < sortedData.length; i++) {
          sumOtherValues += sortedData[i].count;
        }
        let totalCount = {};

        Object.entries(this.categories).forEach(([category, data]) => {
          totalCount[category] = data.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.count;
          }, 0);
        });

        if (top10Values.length >= 10) {
          top10Values.push({
            header: "Other",
            value: "Other",
            count: sumOtherValues,
            valuePercentage:
              (sumOtherValues / totalCount[category].toFixed(2)) * 100,
          });
        }
        this.categories[category] = top10Values;
      }

      Object.entries(this.categories).forEach(([category, data]) => {
        this.categories[category] = data.filter((d) => d.value !== null);
      });
      Object.entries(this.categories).forEach(([category, data]) => {
        this.categories[category] = data.filter((d) => d.valuePercentage !== 0);
      });

      this.categories = Object.fromEntries(Object.entries(this.categories));

      this.$nextTick(() => {
        //     console.log(this.$refs.content);
        //  numberOfRows=this.$refs.content.querySelectorAll("tr");
        //  console.log(numberOfRows)

        const trElements = Array.from(
          this.$refs.content.querySelectorAll("tr")
        );
        trElements[1]?.remove();
        //   trElements.forEach(tr => {
        //     if (!tr.innerText.trim() && tr.cells.length==1 ) {
        //       tr.remove();
        //      }
        //  console.log(tr.cells.length)
        //   });
      });
    },

    addEmptyRows() {
      if (this.testDataHeader.length != 0) {
        this.lastValue = this.testDataHeader[0].header;

        for (var i = 1; i < this.testDataHeader.length; i++) {
          this.currentValue = this.testDataHeader[i].header;
          if (this.lastValue == this.currentValue) {
            //do nothing
          } else {
            var position = this.testDataHeader
              .map((e) => e.header)
              .indexOf(this.currentValue);

            var novaKolona = {
              header: null,
              value: null,
              count: 0,
            };
            this.testDataHeader.splice(position, 0, novaKolona);
          }
          this.lastValue = this.currentValue;
        }
      }
    },
    //Reconsider calling API function here instead in parent
    //because we need two way communication now
    formatNumber(num) {
      return new Intl.NumberFormat("en-US").format(num);
    },
    emitToParent: function (modalState) {
      var result = {};

      result.state = modalState;

      this.$emit("modalState", result);
    },
  },
  unmounted() {
    window.removeEventListener(this.childEvent, this.listener);
    window.removeEventListener(this.childEventForEnter, this.listenerForEnter);
  },
};
</script>

<style>
.statisticValues {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place front is invalid - may break your css so removed */
  padding-top: 100px; /* Location of the box - don't know what this does?  If it is to move your modal down by 100px, then just change top below to 100px and remove this*/
  left: 0;
  right: 0; /* Full width (left and right 0) */
  top: 0;
  bottom: 0; /* Full height top and bottom 0 */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.3); /* Black w/ opacity */
  z-index: 9999; /* Sit on top - higher than any other z-index in your site*/
  text-align: center;
}
.statisticValuesDiv {
  background-color: white;
  width: 90%;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 1rem;
  margin-bottom: 2rem;

  transform: translate(-50%, -50%);
}
.styled-table-info {
  border-collapse: collapse;
  margin: 10px 0;
  font-size: 13px;
  font-family: sans-serif;
  width: 100%;
}
.styled-table-header {
  font-size: 13px;
  font-family: sans-serif;
  width: 100%;
}

.styled-table-header thead tr {
  background-color: var(--mainYellow);
  color: #ffffff;
  text-align: left;
}
.styled-table-info th,
.styled-table-info td {
  padding: 5px 15px;
}
.styled-table-info tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table-info tbody tr:last-of-type {
  /* border-bottom: 2px solid var(--mainBlue); */
}
.styled-table-info tbody tr.active-row {
  font-weight: bold;
  color: var(--mainBlue);
}
.styled-table-header th,
.styled-table-header td {
  padding: 5px 15px;
}
.styled-table-header tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table-header tbody tr:last-of-type {
  /* border-bottom: 2px solid var(--mainBlue); */
}
.styled-table-header tbody tr.active-row {
  font-weight: bold;
  color: var(--mainBlue);
}
.customScrollForHomeTable {
  height: calc(100vh - 260px);
  overflow-y: scroll;
}
div::-webkit-scrollbar {
  /*Your styles here*/
  width: 0.5em;
  height: 1em;
}
div::-webkit-scrollbar-thumb {
  /*Your styles here*/
  background-color: var(--lighterPurple);
  outline: 1px solid var(--Purple);
}
div::-webkit-scrollbar-track {
}

/* 
.styled-table-info tbody tr::nth-child(odd) {
  background: #fff;
} */
.whitebg {
  background: white;
}
.graybg {
  background: var(--lighterPurple);
}
</style>
