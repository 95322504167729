<template>
  <Toast />
  <main id="my-reports" v-if="gettersAuthData === true">
    <!-- <admin-nav-bar></admin-nav-bar> -->
    <aside>
      <div class="report-list">
        <div class="overrideButtons margin-bottom" style="margin-top: 2rem">
          <button
            class="btn btn-regular margin-bottom-small align-left"
            style="width:80%"
            @click="showInfoBoard()"
            v-bind:class="activeButtonInfoBoard()"
          >
            <i class="pi pi-bars"></i>
            InfoBoard
          </button>
        </div>
        <div class="overrideButtons margin-bottom">
          <button
            class="btn btn-regular margin-bottom-small align-left"
            style="width:80%"
            @click="showCompanies()"
            v-bind:class="activeButtonCompanies()"
          >
            <i class="pi pi-bars"></i>
            Companies
          </button>
        </div>
        <div class="overrideButtons margin-bottom">
          <button
            class="btn btn-regular margin-bottom-small align-left"
            style="width:80%"
            @click="showUsers()"
            v-bind:class="activeButtonUsers()"
          >
            <i class="pi pi-bars"></i>
            Users
          </button>
        </div>
        <div class="overrideButtons margin-bottom">
          <button
            class="btn btn-regular margin-bottom-small align-left"
            style="width:80%"
            @click="showPermissions()"
            v-bind:class="activeButtonPermissions()"
          >
            <i class="pi pi-bars"></i>
            Permissions
          </button>
        </div>
        <div class="overrideButtons">
          <button
            class="btn btn-regular margin-bottom-small align-left"
            style="width:80%"
            @click="showSubmenu()"
            v-bind:class="activeButtonReports()"
          >
            <i class="pi pi-bars"></i>
            Reports
          </button>
        </div>
        <div
          class="overrideButtons"
          id="headerDiv"
          style="display:none;    background-image: var(--lm-dropdown-menu-bg-image);
    border: var(--dropdown-menu-border-width) solid var(--lm-dropdown-menu-border-color);
    border-radius: var(--dropdown-menu-border-radius);
    box-shadow: var(--lm-dropdown-menu-box-shadow);width:50%"
        >
          <button
            class="btn btn-regular margin-bottom-small align-left"
            style="width:100%"
            @click="showLogs()"
          >
            <i class="pi pi-chart-line"></i>
            Report I
          </button>
        </div>
      </div>
    </aside>
    <section>
      <div
        class="report-group block overrideSectionDashboard"
        style="height:calc(100vh - 130px)"
        v-if="isShowInfoBoard"
      >
        <div>
          <div class="report-group block overrideSection">
            <div class="row">
              <div class="col-sm-11">
                <h1 style="text-align:left;font-size:24px;margin:0;">
                  Info Board
                </h1>
              </div>
              <div class="col-sm-1">
                <button
                  class="btn btn-regular"
                  style="margin:0.25rem"
                  @click="openAddNewLogModal()"
                >
                  Add
                </button>
              </div>
            </div>
            <table class="styled-table-header">
              <thead>
                <tr>
                  <th style="width:13%;white-space: nowrap;">
                    <i class="pi pi-calendar" style="line-height:unset"></i>Date
                  </th>
                  <th style="width:57%">
                    <i class="pi pi-info-circle" style="line-height:unset"></i
                    >Info
                  </th>
                  <th style="width:10%;white-space: nowrap;">
                    <i class="pi pi-info-circle" style="line-height:unset"></i
                    >Edit
                  </th>
                  <th style="width:10%">
                    <i class="pi pi-info-circle" style="line-height:unset"></i
                    >Delete
                  </th>
                </tr>
              </thead>
            </table>
            <div class="customScrollForHomeTable">
              <table class="styled-table">
                <tbody>
                  <tr
                    v-for="t in tableData"
                    :key="t.id"
                    style="white-space: pre-linestyle;"
                  >
                    <td style="vertical-align:top">{{ t.info_date }}</td>
                    <td style="font-style:italic;white-space: pre-line">
                      {{ t.info_message }}
                      <template v-if="t.info_url.length != 0"
                        ><br />
                        <div style="margin-top:5px">
                          <a :href="t.info_url" target="_blank">{{
                            t.info_url
                          }}</a>
                        </div></template
                      >
                    </td>
                    <td>
                      <button
                        class="btn-regular btn-small"
                        style="    padding: 0.7rem 0.5rem;height:100%"
                        v-on:click.prevent="openEditInfoBoardLog(t)"
                      >
                        <i class="pi pi-pencil"></i>
                      </button>
                    </td>
                    <td>
                      <button
                        class="btn-destructive btn-small"
                        style="    padding: 0.7rem 0.5rem;height:100%"
                        @click="openDeleteLogModal(t.id)"
                      >
                        <i class="pi pi-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        class="report-group block overrideSectionDashboard"
        style="height:calc(100vh - 130px)"
        v-if="isShowCompanies"
      >
        <div>
          <div class="report-group block overrideSection">
            <div class="row">
              <div class="col-sm-11">
                <h1 style="text-align:left;font-size:24px;margin:0;">
                  Companies
                </h1>
              </div>
              <div class="col-sm-1">
                <button
                  class="btn btn-regular"
                  style="margin:0.25rem"
                  @click="openAddNewCompanyModal()"
                >
                  Add
                </button>
              </div>
            </div>
            <table class="styled-table-header">
              <thead>
                <tr>
                  <th style="width:15%;white-space: nowrap;">
                    Company Name
                  </th>
                  <th style="width:34%">
                    Company Description
                  </th>
                  <th>
                    Contact Name
                  </th>
                  <th>
                    Contact Phone Number
                  </th>
                  <th style="width:9%;white-space: nowrap;">
                    <i class="pi pi-info-circle" style="line-height:unset"></i
                    >Edit
                  </th>
                  <th style="width:12%">
                    <i class="pi pi-info-circle" style="line-height:unset"></i
                    >Delete
                  </th>
                </tr>
              </thead>
            </table>
            <div class="customScrollForHomeTable">
              <table class="styled-table">
                <tbody>
                  <tr
                    v-for="t in companies"
                    :key="t.id"
                    style="white-space: pre-linestyle;"
                  >
                    <td style="vertical-align:top">{{ t.name }}</td>
                    <td style="font-style:italic;white-space: pre-line">
                      {{ t.description }}
                    </td>
                    <td style="font-style:italic;white-space: pre-line">
                      {{ t.contact_name }}
                    </td>
                    <td style="font-style:italic;white-space: pre-line">
                      {{ t.contact_phone_number }}
                    </td>
                    <td>
                      <button
                        class="btn-regular btn-small"
                        style="    padding: 0.7rem 0.5rem;height:100%"
                        v-on:click.prevent="openEditCompanyModal(t)"
                      >
                        <i class="pi pi-pencil"></i>
                      </button>
                    </td>
                    <td>
                      <button
                        class="btn-destructive btn-small"
                        style="    padding: 0.7rem 0.5rem;height:100%"
                        @click="openDeleteCompanyModal(t.id)"
                      >
                        <i class="pi pi-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        class="report-group block overrideSectionDashboard"
        style="height:calc(100vh - 130px)"
        v-if="isShowUsers"
      >
        <div>
          <div class="report-group block overrideSection">
            <div class="row">
              <div class="col-sm-1">
                <h1 style="text-align:left;font-size:24px;margin:0;">
                  Users
                </h1>
              </div>
              <div class="col-sm-10" style="text-align:left;">
                <select
                  id="inputChosenCompany"
                  v-model="companyIDForUserFilter"
                  class="form-control"
                  @change="filterUserByCompany($event.target.value)"
                  style="width:25%"
                >
                  <option value="0">Filter users by company</option>
                  <option
                    v-for="t in companies"
                    :key="t.id"
                    :value="t.id"
                    :selected="companyIDForUserFilter === t.id"
                  >
                    {{ t.name }}
                  </option>
                </select>
              </div>
              <div class="col-sm-1">
                <button
                  class="btn btn-regular"
                  style="margin:0.25rem"
                  @click="openAddNewUserModal()"
                >
                  Add
                </button>
              </div>
            </div>
            <table class="styled-table-header">
              <thead>
                <tr>
                  <th style="width:11%;white-space: nowrap;">
                    Username
                  </th>
                  <th style="width:12%;white-space: nowrap;">
                    First Name
                  </th>
                  <th style="width:15%;white-space: nowrap;">
                    Last Name
                  </th>
                  <th style="width:19%;white-space: nowrap;">
                    Email
                  </th>
                  <th style="width:9%;white-space: nowrap;">
                    Admin
                  </th>
                  <th style="white-space: nowrap;">
                    Company
                  </th>
                  <th style="width:10%;white-space: nowrap;">
                    <i class="pi pi-info-circle" style="line-height:unset"></i
                    >Edit
                  </th>
                  <th style="width:10%">
                    <i class="pi pi-info-circle" style="line-height:unset"></i
                    >Delete
                  </th>
                </tr>
              </thead>
            </table>
            <div class="customScrollForHomeTable">
              <table class="styled-table">
                <tbody>
                  <tr
                    v-for="t in users"
                    :key="t.id"
                    style="white-space: pre-linestyle;"
                  >
                    <td style="vertical-align:top">{{ t.username }}</td>
                    <td style="font-style:italic;white-space: pre-line">
                      {{ t.first_name }}
                    </td>
                    <td style="font-style:italic;white-space: pre-line">
                      {{ t.last_name }}
                    </td>
                    <td style="font-style:italic;white-space: pre-line">
                      {{ t.email }}
                    </td>
                    <td
                        style="font-style:italic;white-space: pre-line"
                        :style="[!t.is_superuser ? 'color:red' : 'color:green']"
                    >
                      {{ t.is_superuser ? "Yes" : "No" }}
                    </td>
                    <template v-if="t.company.name!=null">
                      <td style="font-style:italic;white-space: pre-line">
                      {{ t.company.name }}
                    </td>
                    </template>
                    <template v-else>None</template>
                  
                    <td>
                      <button
                        class="btn-regular btn-small"
                        style="    padding: 0.7rem 0.5rem;height:100%"
                        v-on:click.prevent="openEditUserModal(t)"
                      >
                        <i class="pi pi-pencil"></i>
                      </button>
                    </td>
                    <td>
                      <button
                        class="btn-destructive btn-small"
                        style="    padding: 0.7rem 0.5rem;height:100%"
                        @click="openDeleteUserModal(t.id)"
                      >
                        <i class="pi pi-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        class="report-group block overrideSectionDashboard"
        style="height:calc(100vh - 130px)"
        v-if="isShowPermissions"
      >
        <div>
          <div class="report-group block overrideSection">
            <div class="row">
              <div class="col-sm-2">
                <h1 style="text-align:left;font-size:24px;margin:0;">
                  Permissions
                </h1>
              </div>
              <div class="col-sm-9" style="text-align:left;">
                <select
                  id="inputChosenPermission"
                  v-model="companyIDForPermissionFilter"
                  class="form-control"
                  @change="filterPermissionsByCompany($event.target.value)"
                  style="width:30%"
                >
                  <option value="0">Filter permissions by company</option>
                  <option
                    v-for="t in companies"
                    :key="t.id"
                    :value="t.id"
                    :selected="companyIDForPermissionFilter === t.id"
                  >
                    {{ t.name }}
                  </option>
                </select>
              </div>
              <div class="col-sm-1">
                <button
                  class="btn btn-regular"
                  style="margin:0.25rem"
                  @click="openAddNewPermissionModal()"
                >
                  Add
                </button>
              </div>
            </div>
            <table class="styled-table-header">
              <thead>
                <tr>
                  <th style="width:14%;white-space: nowrap;">
                    Company Name
                  </th>
                  <th style="width:16%;white-space: nowrap;">
                    Schema name
                  </th>
                  <th style="width:9%;white-space: nowrap;">
                    Dataset name
                  </th>
                  <th style="white-space: nowrap;">
                    Active
                  </th>
                  <th style="white-space: nowrap;">
                    Visible
                  </th>

                  <th style="width:12%;white-space: nowrap;">
                    Row export limit
                  </th>
                  <th style="width:13%;white-space: nowrap;">
                    Valid until
                  </th>
                  <th style="width:10%;white-space: nowrap;">
                    <i class="pi pi-info-circle" style="line-height:unset"></i
                    >Edit
                  </th>
                  <th style="width:10%">
                    <i class="pi pi-info-circle" style="line-height:unset"></i
                    >Delete
                  </th>
                </tr>
              </thead>
            </table>
            <div class="customScrollForHomeTable">
              <table class="styled-table">
                <tbody>
                  <tr
                    v-for="t in permissions"
                    :key="t.id"
                    style="white-space: pre-linestyle;"
                  >
                    <td style="vertical-align:top">{{ t.company.name }}</td>
                    <td style="font-style:italic;white-space: pre-line">
                      {{ t.schema.name }}
                    </td>
                    <td style="font-style:italic;white-space: pre-line">
                      {{ t.schema.dataset.name }}
                    </td>
                    <td
                      style="font-style:italic;white-space: pre-line"
                      :style="[!t.active ? 'color:red' : 'color:green']"
                    >
                      {{ t.active ? "Yes" : "No" }}
                    </td>
                    <td
                      style="font-style:italic;white-space: pre-line"
                      :style="[!t.visible_only ? 'color:red' : 'color:green']"
                    >
                      {{ t.visible_only ? "Yes" : "No" }}
                    </td>
                    <td style="font-style:italic;white-space: pre-line">
                      {{ t.row_export_limit }}
                    </td>
                    <td style="font-style:italic;white-space: pre-line">
                      {{ t.valid_until }}
                    </td>
                    <td>
                      <button
                        class="btn-regular btn-small"
                        style="    padding: 0.7rem 0.5rem;height:100%"
                        v-on:click.prevent="openEditPermissionModal(t)"
                      >
                        <i class="pi pi-pencil"></i>
                      </button>
                    </td>
                    <td>
                      <button
                        class="btn-destructive btn-small"
                        style="    padding: 0.7rem 0.5rem;height:100%"
                        @click="openPermissionModal(t.id)"
                      >
                        <i class="pi pi-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        class="report-group block overrideSectionDashboard"
        style="height:calc(100vh - 130px)"
        v-if="isShowLogs"
      >
        <div>
          <div class="report-group block overrideSection">
            <div class="row">
              <div class="col-sm-1">
                <h1 style="text-align:left;font-size:24px;margin:0;">
                  Report I
                </h1>
              </div>
              <div class="col-sm-11" style="text-align:left;margin-bottom:5px;">
                <select
                  id="inputChosenCompany"
                  v-model="companyIDForReportFilter"
                  class="form-control"
                  @change="filterReportsByCompany($event)"
                  style="width:25%"
                >
                  <option value="" disabled
                    >Filter report data by company</option
                  >
                  <option
                    v-for="t in companies"
                    :key="t.id"
                    :value="t.id"
                    :selected="companyIDForReportFilter === t.id"
                  >
                    {{ t.name }}
                  </option>
                </select>
              </div>
            </div>
            <table class="styled-table-header">
              <thead>
                <tr>
                  <th style="white-space: nowrap;width:7vw">
                    Dataset
                  </th>
                  <th style="white-space: nowrap;width:13vw">
                    Schema
                  </th>
                  <th style="white-space: nowrap;width:11vw">
                    Activated
                  </th>
                  <th style="white-space: nowrap;width:14vw">
                    Valid untill
                  </th>
                  <th style="white-space: nowrap;width:10vw">
                    Updated
                  </th>
                  <th style="white-space: nowrap">
                    Is Active
                  </th>
                  <th style="white-space: nowrap;">
                    Remaining
                  </th>
                  <th>
                    Log
                  </th>
                </tr>
              </thead>
            </table>
            <div class="customScrollForHomeTable">
              <table class="styled-table">
                <tbody>
                  <tr
                    v-for="t in reports"
                    :key="t.id"
                    style="white-space: pre-linestyle;"
                  >
                    <td style="vertical-align:top">
                      {{ t.schema.dataset.name }}
                    </td>
                    <td style="font-style:italic;white-space: pre-line">
                      {{ t.schema.name }}
                    </td>
                    <td :style="[!t.active ? 'color:red' : 'color:green']">
                      {{ t.active ? "Yes" : "No" }}
                    </td>
                    <td
                      :style="[
                        !t.is_active
                          ? 'color:red'
                          : true
                          ? 'color:green'
                          : false,
                      ]"
                    >
                      {{ t.valid_until }}
                    </td>
                    <td>{{ moment(t.updated_at).format("YYYY-MM-DD") }}</td>
                    <td :style="[!t.is_active ? 'color:red' : 'color:green']">
                      {{ t.is_active ? "Yes" : "No" }}
                    </td>
                    <td>{{ t.prikazTesta }}</td>
                    <template v-if="t.pokreniModal === true">
                      <button
                        style="margin-left: 4rem; margin-top: 0.7rem; margin-bottom:0.5rem;"
                        class="btn btn-regular"
                        v-on:click="showPopUpMonthlyAPI(t)"
                      >
                        Show data
                      </button>
                    </template>
                    <teleport v-if="modalPermission" to="#modals">
                      <div class="modalPermission" @click.stop="close">
                        <div
                          class="modalPermissionDiv"
                          ref="content"
                          style="    border-bottom: 2px solid var(--mainBlue);"
                        >
                          <h1 class="modal__title">Permission usage data</h1>
                          <DataTable
                            :value="responseTabela"
                            :rowHover="true"
                            :scrollable="true"
                            scrollHeight="600px"
                            removableSort
                            style="border-bottom: 2px solid var(--mainBlue);"
                          >
                            <Column
                              v-for="col of koloneTabele"
                              :field="col.field"
                              :header="col.header"
                              :key="col.field"
                              :sortable="true"
                              style="min-width: 10rem"
                            ></Column>
                          </DataTable>

                          <div
                            style="display: flex;
  
    flex-direction: row;
    align-items: stretch;
    align-content: stretch;
    "
                          >
                            <div
                              style="display: flex;
	flex-direction: column;
    flex: 2 0 0;
        justify-content: space-between;margin:0.5rem;
"
                            >
                              <div
                                style="  display: flex;
    justify-content: space-between;"
                              >
                                <div style="align-self: center;"></div>

                                <button
                                  class="btn btn-regular"
                                  style="align-self: center;width:8rem;"
                                  @click.stop="
                                    modalPermission = !modalPermission
                                  "
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </teleport>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <main v-else>
    <error-page></error-page>
  </main>
  <teleport v-if="deleteLogModal" to="#modals">
    <delete-modal
      v-on:selectedValuesState="onReportDeleted"
      @close="deleteLogModal = false"
    />
  </teleport>

  <teleport v-if="openDeletePermissionModal" to="#modals">
    <delete-modal
      v-on:selectedValuesState="onPermissionDeleted"
      @close="openDeletePermissionModal = false"
    />
  </teleport>
  <teleport v-if="deleteCompanyModal" to="#modals">
    <delete-modal
      v-on:selectedValuesState="onCompanyDeleted"
      @close="deleteCompanyModal = false"
    />
  </teleport>
  <teleport v-if="deleteUserModal" to="#modals">
    <delete-modal
      v-on:selectedValuesState="onUserDeleted"
      @close="deleteUserModal = false"
    />
  </teleport>
  <teleport v-if="isAddNewLogOpen == true" to="#modals">
    <add-new-log v-on:modalState="onNewLogModalChange" />
  </teleport>

  <teleport v-if="isNewUserModalOpen == true" to="#modals">
    <add-new-user v-on:modalState="onNewUserChange" />
  </teleport>
  <teleport v-if="isNewCompanyModalOpen == true" to="#modals">
    <add-new-company v-on:modalState="onNewCompanyChange" />
  </teleport>
  <teleport v-if="isNewPermissionModalOpen == true" to="#modals">
    <add-new-permission v-on:modalState="onNewPermissionChange" />
  </teleport>

  <teleport v-if="isEditCurrentLogOpen == true" to="#modals">
    <edit-current-log
      v-bind:postInfoBoardDescription="postInfoBoardDescription"
      v-bind:postInfoBoardMessage="postInfoBoardMessage"
      v-bind:postInfoBoardDate="postInfoBoardDate"
      v-on:modalState="onEditCurrentModalChange"
    />
  </teleport>
  <teleport v-if="isEditCurrentCompanyOpen == true" to="#modals">
    <edit-current-company
      v-bind:postCompanyName="postCompanyName"
      v-bind:postCompanyDescription="postCompanyDescription"
      v-bind:postContactName="postContactName"
      v-bind:postContactPhoneNumber="postContactPhoneNumber"
      v-on:modalState="onEditCompanyChange"
    />
  </teleport>
  <teleport v-if="isEditCurrentUserOpen == true" to="#modals">
    <edit-current-user
      v-bind:postUsername="postUsername"
      v-bind:postFirstName="postFirstName"
      v-bind:postLastName="postLastName"
      v-bind:postEmail="postEmail"
      v-bind:postAdminRights="postAdminRights"
      v-bind:postCompanyID="postCompanyID"
      v-on:modalState="onEditUserChange"
    />
  </teleport>
  <teleport v-if="isEditCurrentPerimissionOpen == true" to="#modals">
    <edit-current-permission
      v-bind:postPermissionActive="postPermissionActive"
      v-bind:postPermissionVisible="postPermissionVisible"
      v-bind:postRowExportLimit="postRowExportLimit"
      v-bind:postValidUntil="postValidUntil"
      v-bind:postPermissionCompany="postPermissionCompany"
      v-bind:postPermissionSchema="postPermissionSchema"
      v-on:modalState="onEditPermissionChange"
    />
  </teleport>
</template>
<script>
// import AdminNavBar from "../components/AdminNavBar";
import moment from "moment";

import { mapGetters, mapActions } from "vuex";
import ErrorPage from "../components/ErrorPage.vue";
import jwtInterceptor from "../shared/jwtInterceptor";
import { API_URL } from "../../src/.env.js";
import DeleteModal from "../components/DeleteModal.vue";
import addNewLog from "../components/adminPanel/addNewLog.vue";
import editCurrentLog from "../components/adminPanel/editcurrentLog.vue";
import addNewCompany from "../components/adminPanel/addNewCompany.vue";
import editCurrentCompany from "../components/adminPanel/editCurrentCompany.vue";
import addNewUser from "../components/adminPanel/addNewUser.vue";
import editCurrentUser from "../components/adminPanel/editCurrentUser.vue";
import addNewPermission from "../components/adminPanel/addNewPermission.vue";
import editCurrentPermission from "../components/adminPanel/editCurrentPermission.vue";

export default {
  data() {
    return {
      info: [],
      AuthStr: "Bearer " + localStorage.getItem("access"),
      isShowUsers: false,
      isShowLogs: false,
      isShowPermissions: false,
      isShowInfoBoard: false,
      isShowCompanies: false,
      tableData: [],
      companies: [],
      users: [],
      deleteLogModal: false,
      oznaceniLogModal: 0,
      greskaResponse: "",
      isAddNewLogOpen: false,
      postInfoBoardDescription: "",
      postInfoBoardMessage: "",
      postInfoBoardDate: "",
      isEditCurrentLogOpen: false,
      oznaceniEditLog: 0,
      oznaceniEditPermission: 0,
      isNewCompanyModalOpen: false,
      postCompanyName: "",
      postCompanyDescription: "",
      postContactName: "",
      postContactPhoneNumber: "",
      deleteCompanyModal: false,
      oznaceniCompanyModal: "",
      isEditCurrentCompanyOpen: false,
      isNewUserModalOpen: false,
      postUsername: "",
      postFirstName: "",
      postLastName: "",
      postEmail: "",
      postAdminRights: false,
      postCompanyID: 0,
      deleteUserModal: false,
      oznaceniUser: "",
      isEditCurrentUserOpen: false,
      oznaceniEditUser: 0,
      postPassword: "",
      permissions: [],
      isNewPermissionModalOpen: false,
      postPermissionActive: false,
      postPermissionVisible: false,
      postRowExportLimit: 5000,
      postValidUntil: new Date().toLocaleDateString("sv").slice(0, 10),
      postPermissionIsActive: false,
      postPermissionCanView: false,
      postPermissionCompany: 0,
      postPermissionSchema: 0,
      openDeletePermissionModal: false,
      oznaceniPermission: 0,
      isEditCurrentPerimissionOpen: false,
      companyIDForUserFilter: 0,
      companyIDForPermissionFilter: 0,
      companyIDForReportFilter: 0,
      reports: [],
      activeColor: "red",
      rowCounter: "",
      modalPermission: false,
      permissionUsageShow: "",
      koloneTabele: [],
      responseTabela: {},
      hasData: false,
      noviArray: [],
    };
  },
  components: {
    // GroupReports,
    ErrorPage,
    DeleteModal,
    addNewLog,
    editCurrentLog,
    addNewCompany,
    editCurrentCompany,
    addNewUser,
    editCurrentUser,
    addNewPermission,
    editCurrentPermission,
  },
  mounted() {
    this.moment = moment;

    this.getNavInfo();
    this.getAllCompanies();
    this.getTableDataInfo();
    this.getAllUsers();
    this.getAllPermissions();
  },
  computed: {
    ...mapGetters("auth", {
      gettersAuthData: "isUserAdminGetter",
    }),
    ...mapGetters("navInfo", {
      gettersNavInfo: "getNavINfo",
    }),

    filteredList() {
      const list = this.reports.filter((item) => item.permission_usage.length);

      return list;
    },
    mainUsage() {
      // to show main_usage just filter out the items that has no permission usage

      const value = this.reports.filter(
        (item) => item.permission_usage.length === 0
      );

      return value;
    },
  },

  methods: {
    ...mapActions("navInfo", {
      actionsGetNavInfo: "getNavInfo",
    }),
    async getNavInfo() {
      await this.actionsGetNavInfo();
    },
    async show() {
      await this.actionsGetAllInfo();
    },
    showInfoBoard() {
      this.isShowCompanies = false;

      this.isShowInfoBoard = true;
      this.isShowLogs = false;
      this.isShowUsers = false;
      this.isShowPermissions = false;
      var x = document.getElementById("headerDiv");
      if (x.style.display === "block") {
        x.style.display = "none";
      } else {
        x.style.display = "none";
      }
    },
    activeButtonInfoBoard() {
      if (this.isShowInfoBoard) {
        return "activeButtonStyling";
      }
    },
    activeButtonCompanies() {
      if (this.isShowCompanies) {
        return "activeButtonStyling";
      }
    },
    activeButtonUsers() {
      if (this.isShowUsers) {
        return "activeButtonStyling";
      }
    },
    activeButtonPermissions() {
      if (this.isShowPermissions) {
        return "activeButtonStyling";
      }
    },
    activeButtonReports() {
      if (this.isShowLogs) {
        return "activeButtonStyling";
      }
    },
    showSubmenu() {
      var x = document.getElementById("headerDiv");
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
    },
    showLogs() {
      this.isShowCompanies = false;

      this.isShowInfoBoard = false;
      this.isShowLogs = true;
      this.isShowUsers = false;
      this.isShowPermissions = false;
      this.filterReportsByCompany(this.companyIDForReportFilter);
    },
    showUsers() {
      this.isShowCompanies = false;

      this.isShowInfoBoard = false;
      this.isShowLogs = false;
      this.isShowUsers = true;
      this.isShowPermissions = false;
      var x = document.getElementById("headerDiv");
      if (x.style.display === "block") {
        x.style.display = "none";
      } else {
        x.style.display = "none";
      }
    },
    showCompanies() {
      this.isShowCompanies = true;
      this.isShowInfoBoard = false;
      this.isShowLogs = false;
      this.isShowUsers = false;
      this.isShowPermissions = false;
      var x = document.getElementById("headerDiv");
      if (x.style.display === "block") {
        x.style.display = "none";
      } else {
        x.style.display = "none";
      }
    },
    showPermissions() {
      this.isShowCompanies = false;

      this.isShowInfoBoard = false;
      this.isShowLogs = false;
      this.isShowUsers = false;
      this.isShowPermissions = true;
      var x = document.getElementById("headerDiv");
      if (x.style.display === "block") {
        x.style.display = "none";
      } else {
        x.style.display = "none";
      }
    },
    getAllUsers() {
      this.loading = true;
      jwtInterceptor
        .get(`${API_URL}/api/users/`)
        .then((res) => {
          this.users = res.data;
        })

        .catch((error) => {
          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: error.response.data.error,
            life: 4000,
          });
          if (
            document.activeElement.toString() == "[object HTMLButtonElement]"
          ) {
            document.activeElement.blur();
          }

          // Manage errors if found any
        });
    },

    filterReportsByCompany(companyID) {
      this.loading = true;

      jwtInterceptor
        .get(
          `${API_URL}/api/admin/reports/permissions/${this.companyIDForReportFilter}`
        )
        .then((res) => {
          this.reports = res.data;
          this.showMonthlyUsageEditor();
        })

        .catch((error) => {
          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: error,
            life: 4000,
          });
          if (
            document.activeElement.toString() == "[object HTMLButtonElement]"
          ) {
            document.activeElement.blur();
          }

          // Manage errors if found any
        });
      if (companyID == 0) {
        this.companyIDForReportFilter = companyID.target.value;
      }
    },
    filterUserByCompany(companyID) {
      this.loading = true;
      jwtInterceptor
        .get(`${API_URL}/api/users/?company_id=${companyID}`)
        .then((res) => {
          this.users = res.data;
          if (companyID == 0) {
            this.getAllUsers();
          }
        })

        .catch((error) => {
          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: error.response.data.error,
            life: 4000,
          });
          if (
            document.activeElement.toString() == "[object HTMLButtonElement]"
          ) {
            document.activeElement.blur();
          }

          // Manage errors if found any
        });
    },

    filterPermissionsByCompany(permissionID) {
      this.loading = true;
      jwtInterceptor
        .get(`${API_URL}/api/permissions/?company_id=${permissionID}`)
        .then((res) => {
          this.permissions = res.data;
          if (permissionID == 0) {
            this.getAllPermissions();
          }
        })

        .catch((error) => {
          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: error.response.data.error,
            life: 4000,
          });
          if (
            document.activeElement.toString() == "[object HTMLButtonElement]"
          ) {
            document.activeElement.blur();
          }

          // Manage errors if found any
        });
    },
    getAllPermissions() {
      this.loading = true;
      jwtInterceptor
        .get(`${API_URL}/api/permissions/`)
        .then((res) => {
          this.permissions = res.data;
        })

        .catch((error) => {
          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: error.response.data.error,
            life: 4000,
          });
          if (
            document.activeElement.toString() == "[object HTMLButtonElement]"
          ) {
            document.activeElement.blur();
          }

          // Manage errors if found any
        });
    },
    getAllCompanies() {
      this.loading = true;
      jwtInterceptor
        .get(`${API_URL}/api/companies/`)
        .then((res) => {
          this.companies = res.data;
          this.companyIDForReportFilter = this.companies[0].id;
        })

        .catch((error) => {
          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: error.response.data.error,
            life: 4000,
          });
          if (
            document.activeElement.toString() == "[object HTMLButtonElement]"
          ) {
            document.activeElement.blur();
          }

          // Manage errors if found any
        });
    },
    getTableDataInfo() {
      this.loading = true;
      jwtInterceptor
        .get(`${API_URL}/api/info/`)
        .then((res) => {
          this.tableData = res.data;
        })

        .catch((error) => {
          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: error.response.data.error,
            life: 4000,
          });
          if (
            document.activeElement.toString() == "[object HTMLButtonElement]"
          ) {
            document.activeElement.blur();
          }

          // Manage errors if found any
        });
    },
    openAddNewCompanyModal() {
      this.isNewCompanyModalOpen = true;
      if (document.activeElement.toString() == "[object HTMLButtonElement]") {
        document.activeElement.blur();
      }
    },
    openAddNewPermissionModal() {
      this.isNewPermissionModalOpen = true;
      if (document.activeElement.toString() == "[object HTMLButtonElement]") {
        document.activeElement.blur();
      }
    },

    openAddNewUserModal() {
      this.isNewUserModalOpen = true;
      if (document.activeElement.toString() == "[object HTMLButtonElement]") {
        document.activeElement.blur();
      }
    },
    openDeleteLogModal(logID) {
      this.deleteLogModal = true;
      this.oznaceniLogModal = logID;
    },
    openDeleteCompanyModal(companyID) {
      this.deleteCompanyModal = true;
      this.oznaceniCompanyModal = companyID;
    },

    openDeleteUserModal(userID) {
      this.deleteUserModal = true;
      this.oznaceniUser = userID;
    },
    openPermissionModal(permissionID) {
      this.openDeletePermissionModal = true;
      this.oznaceniPermission = permissionID;
    },
    onReportDeleted(value) {
      if (value.state == "Close") {
        this.deleteLogModal = false;
      } else if (value.state == "Yes") {
        this.deleteLog();
        this.deleteLogModal = false;
      }
    },
    onCompanyDeleted(value) {
      if (value.state == "Close") {
        this.deleteCompanyModal = false;
      } else if (value.state == "Yes") {
        this.deleteCompany();
        this.deleteCompanyModal = false;
      }
    },
    onPermissionDeleted(value) {
      if (value.state == "Close") {
        this.openDeletePermissionModal = false;
      } else if (value.state == "Yes") {
        this.deletePermission();
        this.openDeletePermissionModal = false;
      }
    },

    onUserDeleted(value) {
      if (value.state == "Close") {
        this.deleteUserModal = false;
      } else if (value.state == "Yes") {
        this.deleteUser();
        this.deleteUserModal = false;
      }
    },
    deleteUser() {
      jwtInterceptor
        .delete(`${API_URL}/api/users/${this.oznaceniUser}/`)
        .then(() => {
          //nakon obrisanog, opet preuzimamo sve da bismo prikazali osvezenu listu
          this.getAllUsers();
          this.$toast.add({
            severity: "success",
            summary: "Success Message",
            detail: "The user has been successfully deleted.",
            life: 3000,
          });
        })
        .catch((error) => {
          this.greskaResponse = error.response.data.error;

          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: "The user wasn't deleted because " + this.greskaResponse,
            life: 4000,
          });
        });
      this.greskaResponse = "";
    },

    deletePermission() {
      jwtInterceptor
        .delete(`${API_URL}/api/permissions/${this.oznaceniPermission}/`)
        .then(() => {
          //nakon obrisanog, opet preuzimamo sve da bismo prikazali osvezenu listu
          this.getAllPermissions();
          this.$toast.add({
            severity: "success",
            summary: "Success Message",
            detail: "The permission has been successfully deleted.",
            life: 3000,
          });
        })
        .catch((error) => {
          this.greskaResponse = error.response.data.error;

          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail:
              "The permission wasn't deleted because " + this.greskaResponse,
            life: 4000,
          });
        });
      this.greskaResponse = [];
    },
    deleteCompany() {
      jwtInterceptor
        .delete(`${API_URL}/api/companies/${this.oznaceniCompanyModal}/`)
        .then(() => {
          //nakon obrisanog, opet preuzimamo sve da bismo prikazali osvezenu listu
          this.getAllCompanies();
          this.$toast.add({
            severity: "success",
            summary: "Success Message",
            detail: "The company has been successfully deleted.",
            life: 3000,
          });
        })
        .catch((error) => {
          this.greskaResponse = error.response.data.error;

          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: "The company wasn't deleted because " + this.greskaResponse,
            life: 4000,
          });
        });
      this.greskaResponse = [];
    },
    deleteLog() {
      jwtInterceptor
        .delete(`${API_URL}/api/info/${this.oznaceniLogModal}/`)
        .then(() => {
          //nakon obrisanog, opet preuzimamo sve da bismo prikazali osvezenu listu
          this.getTableDataInfo();
          this.$toast.add({
            severity: "success",
            summary: "Success Message",
            detail: "The information has been successfully deleted.",
            life: 3000,
          });
        })
        .catch((error) => {
          this.greskaResponse = error.response.data.error;

          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail:
              "The information wasn't deleted because " + this.greskaResponse,
            life: 4000,
          });
        });
      this.greskaResponse = [];
    },
    openAddNewLogModal() {
      this.isAddNewLogOpen = true;

      if (document.activeElement.toString() == "[object HTMLButtonElement]") {
        document.activeElement.blur();
      }
    },
    onNewCompanyChange(value) {
      if (value.state == "Save") {
        //
        this.postCompanyName = value.postCompanyName;
        this.postCompanyDescription = value.postCompanyDescription;
        this.postContactName = value.postContactName;
        this.postContactPhoneNumber = value.postContactPhoneNumber;

        this.addNewCompanyAPI();

        this.isNewCompanyModalOpen = false;
      } else if (value.state == "Close") {
        //

        this.isNewCompanyModalOpen = false;
      }
    },

    onNewPermissionChange(value) {
      if (value.state == "Save") {
        //
        this.postPermissionActive = value.postPermissionActive;
        this.postPermissionVisible = value.postPermissionVisible;
        this.postRowExportLimit = value.postRowExportLimit;
        this.postValidUntil = value.postValidUntil;
        this.postPermissionIsActive = value.postPermissionIsActive;
        this.postPermissionCanView = value.postPermissionCanView;
        this.postPermissionCompany = value.postPermissionCompany;
        this.postPermissionSchema = value.postPermissionSchema;

        this.addNewPermissionAPI();

        this.isNewPermissionModalOpen = false;
      } else if (value.state == "Close") {
        //

        this.isNewPermissionModalOpen = false;
      }
    },
    onNewUserChange(value) {
      if (value.state == "Save") {
        //
        this.postUsername = value.postUsername;
        this.postFirstName = value.postFirstName;
        this.postLastName = value.postLastName;
        this.postEmail = value.postEmail;
        this.postAdminRights = value.postAdminRights;
        this.postCompanyID = value.postCompanyID;
        this.postPassword = value.postPassword;

        this.addNewUserAPI();

        this.isNewUserModalOpen = false;
      } else if (value.state == "Close") {
        //

        this.isNewUserModalOpen = false;
      }
    },
    onNewLogModalChange(value) {
      if (value.state == "Save") {
        //
        this.postInfoBoardMessage = value.postInfoBoardMessage;
        this.postInfoBoardDescription = value.postInfoBoardDescription;
        this.postInfoBoardDate = value.postInfoBoardDate;
        this.addNewInfoBoardLog();

        this.isAddNewLogOpen = false;
      } else if (value.state == "Close") {
        //

        this.isAddNewLogOpen = false;
      }
    },
    openEditInfoBoardLog(currentLog) {
      this.isEditCurrentLogOpen = true;
      this.oznaceniEditLog = currentLog.id;
      this.postInfoBoardMessage = currentLog.info_message;
      this.postInfoBoardDescription = currentLog.info_url;
      this.postInfoBoardDate = currentLog.info_date;
      if (document.activeElement.toString() == "[object HTMLButtonElement]") {
        document.activeElement.blur();
      }
    },

    openEditPermissionModal(currentPermission) {
      this.isEditCurrentPerimissionOpen = true;
      this.oznaceniEditPermission = currentPermission.id;
      this.postPermissionActive = currentPermission.active;
      this.postPermissionVisible = currentPermission.visible_only;
      this.postRowExportLimit = currentPermission.row_export_limit;
      this.postValidUntil = currentPermission.valid_until;
      this.postPermissionCompany = currentPermission.company.id;
      this.postPermissionSchema = currentPermission.schema.id;
      if (document.activeElement.toString() == "[object HTMLButtonElement]") {
        document.activeElement.blur();
      }
    },
    openEditUserModal(currentUser) {
      this.isEditCurrentUserOpen = true;
      this.oznaceniEditUser = currentUser.id;
      this.postUsername = currentUser.username;
      this.postFirstName = currentUser.first_name;
      this.postLastName = currentUser.last_name;
      this.postEmail = currentUser.email;
      this.postAdminRights = currentUser.is_superuser;
      this.postCompanyID = currentUser.company.id;
      if (document.activeElement.toString() == "[object HTMLButtonElement]") {
        document.activeElement.blur();
      }
    },
    openEditCompanyModal(currentCompany) {
      this.isEditCurrentCompanyOpen = true;
      this.oznaceniEditCompany = currentCompany.id;
      this.postCompanyName = currentCompany.name;
      this.postCompanyDescription = currentCompany.description;
      this.postContactName = currentCompany.contact_name;
      this.postContactPhoneNumber = currentCompany.contact_phone_number;

      if (document.activeElement.toString() == "[object HTMLButtonElement]") {
        document.activeElement.blur();
      }
    },
    onEditCurrentModalChange(value) {
      if (value.state == "Save") {
        //
        this.postInfoBoardMessage = value.postInfoBoardMessage;
        this.postInfoBoardDescription = value.postInfoBoardDescription;
        this.postInfoBoardDate = value.postInfoBoardDate;

        this.editCurrentInfoBoardLog();

        this.isEditCurrentLogOpen = false;
      } else if (value.state == "Close") {
        //

        this.isEditCurrentLogOpen = false;
      }
    },

    onEditPermissionChange(value) {
      if (value.state == "Save") {
        //
        this.postPermissionActive = value.postPermissionActive;
        this.postPermissionVisible = value.postPermissionVisible;
        this.postRowExportLimit = value.postRowExportLimit;
        this.postValidUntil = value.postValidUntil;
        this.postPermissionCompany = value.postPermissionCompany;
        this.postPermissionSchema = value.postPermissionSchema;
        this.companyIDForPermissionFilter=value.postPermissionCompany;

        this.editCurrentPermissionAPI();

        this.isEditCurrentPerimissionOpen = false;
      } else if (value.state == "Close") {
        //

        this.isEditCurrentPerimissionOpen = false;
      }
    },
    onEditUserChange(value) {
      if (value.state == "Save") {
        //
        this.postUsername = value.postUsername;
        this.postFirstName = value.postFirstName;
        this.postLastName = value.postLastName;
        this.postEmail = value.postEmail;
        this.postAdminRights = value.postAdminRights;
        this.postCompanyID = value.postCompanyID;
        this.companyIDForUserFilter=value.postCompanyID;
        this.editCurrentUser();

        this.isEditCurrentUserOpen = false;
      } else if (value.state == "Close") {
        //

        this.isEditCurrentUserOpen = false;
      }
    },
    onEditCompanyChange(value) {
      if (value.state == "Save") {
        //
        this.postCompanyName = value.postCompanyName;
        this.postCompanyDescription = value.postCompanyDescription;
        this.postContactName = value.postContactName;
        this.postContactPhoneNumber = value.postContactPhoneNumber;

        this.editCurrentCompany();

        this.isEditCurrentCompanyOpen = false;
      } else if (value.state == "Close") {
        //

        this.isEditCurrentCompanyOpen = false;
      }
    },

    editCurrentPermissionAPI() {
      jwtInterceptor
        .patch(`${API_URL}/api/permissions/${this.oznaceniEditPermission}/`, {
          active: this.postPermissionActive,
          visible_only: this.postPermissionVisible,
          row_export_limit: this.postRowExportLimit,
          valid_until: this.postValidUntil,
          company: this.postPermissionCompany,
          schema: this.postPermissionSchema,
        })
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Success Message",
            detail: "The permission has been successfully edited.",
            life: 3000,
          });
          this.filterPermissionsByCompany(this.postPermissionCompany);
        })
        .catch((error) => {
          this.greskaResponse = error.response.data.error;
          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: this.greskaResponse,
            life: 4000,
          });
        });
    },
    editCurrentUser() {
      jwtInterceptor
        .patch(`${API_URL}/api/users/${this.oznaceniEditUser}/`, {
          username: this.postUsername,
          first_name: this.postFirstName,
          last_name: this.postLastName,
          email: this.postEmail,
          is_superuser: this.postAdminRights,
          company: this.postCompanyID,
        })
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Success Message",
            detail: "The user has been successfully edited.",
            life: 3000,
          });
          this.filterUserByCompany(this.postCompanyID);
          
          // this.companyIDForUserFilter=this.postCompanyID;
        })
        .catch((error) => {
          this.greskaResponse = error.response.data.error;
          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: this.greskaResponse,
            life: 4000,
          });
        });
    },
    editCurrentCompany() {
      jwtInterceptor
        .patch(`${API_URL}/api/companies/${this.oznaceniEditCompany}/`, {
          name: this.postCompanyName,
          description: this.postCompanyDescription,
          contact_name: this.postContactName,
          contact_phone_number: this.postContactPhoneNumber,
        })
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Success Message",
            detail: "The company has been successfully edited.",
            life: 3000,
          });
          this.getAllCompanies();
        })
        .catch((error) => {
          this.greskaResponse = error.response.data.error;
          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: this.greskaResponse,
            life: 4000,
          });
        });
    },
    editCurrentInfoBoardLog() {
      jwtInterceptor
        .patch(`${API_URL}/api/info/${this.oznaceniEditLog}/`, {
          info_date: this.postInfoBoardDate,
          info_message: this.postInfoBoardMessage,
          info_url: this.postInfoBoardDescription,
        })
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Success Message",
            detail: "The information has been successfully edited.",
            life: 3000,
          });
          this.getTableDataInfo();
        })
        .catch((error) => {
          this.greskaResponse = error.response.data.error;
          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: this.greskaResponse,
            life: 4000,
          });
        });
    },

    async addNewPermissionAPI() {
      await jwtInterceptor
        .post(
          `${API_URL}/api/permissions/`,

          {
            active: this.postPermissionActive,
            visible_only: this.postPermissionVisible,
            row_export_limit: this.postRowExportLimit,
            valid_until: this.postValidUntil,
            company: this.postPermissionCompany,
            schema: this.postPermissionSchema,
          }
        )
        .then(() => {
          this.postPermissionActive = false;
          this.postPermissionVisible = false;
          this.postRowExportLimit = "";
          this.postValidUntil = "";
          this.postPermissionCompany = 0;
          this.postPermissionSchema = 0;

          this.$toast.add({
            severity: "success",
            summary: "Success Message",
            detail: "New permission has been successfully added.",
            life: 3000,
          });

          this.getAllPermissions();
        })
        .catch((error) => {
          this.greskaResponse = error.response.data.non_field_errors[0];
          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: this.greskaResponse,
            life: 4000,
          });
        });
    },
    async addNewUserAPI() {
      await jwtInterceptor
        .post(
          `${API_URL}/api/users/`,

          {
            username: this.postUsername,
            password: this.postPassword,
            first_name: this.postFirstName,
            last_name: this.postLastName,
            email: this.postEmail,
            is_superuser: this.postAdminRights,
            company: this.postCompanyID,
          }
        )
        .then(() => {
          this.postUsername = "";
          this.postPassword = "";
          this.postFirstName = "";
          this.postLastName = "";
          this.postEmail = "";

          this.postAdminRights = false;

          this.$toast.add({
            severity: "success",
            summary: "Success Message",
            detail: "New user has been successfully added.",
            life: 3000,
          });

          this.getAllUsers();
        })
        .catch((error) => {
          this.greskaResponse = error.response.data;
          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: this.greskaResponse,
            life: 4000,
          });
        });
    },
    async addNewCompanyAPI() {
      await jwtInterceptor
        .post(
          `${API_URL}/api/companies/`,

          {
            name: this.postCompanyName,
            description: this.postCompanyDescription,
            contact_name: this.postContactName,
            contact_phone_number: this.postContactPhoneNumber,
          }
        )
        .then(() => {
          this.postCompanyName = "";
          this.postCompanyDescription = "";
          this.postContactName = "";
          this.postContactPhoneNumber = "";

          this.$toast.add({
            severity: "success",
            summary: "Success Message",
            detail: "New company has been successfully added.",
            life: 3000,
          });

          this.getAllCompanies();
        })
        .catch((error) => {
          this.greskaResponse = error.response.data;
          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: this.greskaResponse,
            life: 4000,
          });
        });
    },
    async addNewInfoBoardLog() {
      await jwtInterceptor
        .post(
          `${API_URL}/api/info/`,

          {
            info_date: this.postInfoBoardDate,
            info_message: this.postInfoBoardMessage,
            info_url: this.postInfoBoardDescription,
          }
        )
        .then(() => {
          this.postInfoBoardMessage = "";
          this.postInfoBoardDescription = "";
          this.postInfoBoardDate = "";
          this.$toast.add({
            severity: "success",
            summary: "Success Message",
            detail: "New info has been successfully added.",
            life: 3000,
          });
          this.getTableDataInfo();
        })
        .catch((error) => {
          this.greskaResponse = error.response.data;
          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: this.greskaResponse,
            life: 4000,
          });
        });
    },
    clearCol() {
      this.koloneTabele = [];
      this.responseTabela = [];
    },
    showPopUpMonthlyAPI(refToTable) {
      this.modalPermission = true;

      this.koloneTabele = [];

      this.noviArray = refToTable.permission_usage;

      this.noviArray.forEach((element) => {
        if (
          element.year === this.currentYear() &&
          element.month === this.currentMonth()
        ) {
          this.responseTabela = element.permission_usage_log;
        }
      });

      for (var key in this.responseTabela[0]) {
        var jednaKolona = { field: key, header: key };

        this.koloneTabele.push(jednaKolona);
      }
    },

    showMonthlyUsageEditor() {
      console.log(this.reports);
      this.reports.forEach((element) => {
        element.pokreniModal = false;
        element.prikazTesta = "TESTIRANJE";
        if (element.active === true) {
          this.rowCounter = element.row_export_limit;
          element.prikazTesta = element.row_export_limit;
        } else {
          this.rowCounter = "None";
          element.prikazTesta = "None";
        }

        //element.can_view = "TESTIRANJE";
        element.permission_usage.forEach((elementIspod) => {
          if (
            elementIspod.year === this.currentYear() &&
            elementIspod.month === this.currentMonth() &&
            element.active === true &&
            elementIspod.permission_usage_log != 0
          ) {
            element.pokreniModal = true;

            element.prikazTesta = elementIspod.row_export_usage;
            this.rowCounter = elementIspod.row_export_usage;
          } else if (element.active === false) {
            element.prikazTesta = "None";
            this.rowCounter = "none";
          }
        });
      });
    },

    currentYear() {
      const current = new Date();
      const year = current.getFullYear();

      return year;
    },
    currentMonth() {
      const current = new Date();
      const month = current.getMonth() + 1;

      return month;
    },
  },
};
</script>
<style scoped>
.center {
  text-align: center;
}
.iconsHome {
  font-size: 30px;
  color: var(--mainBlue);
  text-align: center;
  position: relative;
}
div.chart-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.styled-table {
  border-collapse: collapse;
  margin: 10px 0;
  font-size: 13px;
  font-family: sans-serif;
  width: 100%;
}
.styled-table-header {
  font-size: 13px;
  font-family: sans-serif;
  width: 100%;
}

.styled-table-header thead tr {
  background-color: var(--mainYellow);
  color: #ffffff;
  text-align: left;
}
.styled-table th,
.styled-table td {
  padding: 5px 15px;
}
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:last-of-type {
  /* border-bottom: 2px solid var(--mainBlue); */
}
.styled-table tbody tr.active-row {
  font-weight: bold;
  color: var(--mainBlue);
}
.styled-table-header th,
.styled-table-header td {
  padding: 5px 15px;
}
.styled-table-header tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table-header tbody tr:last-of-type {
  /* border-bottom: 2px solid var(--mainBlue); */
}
.styled-table-header tbody tr.active-row {
  font-weight: bold;
  color: var(--mainBlue);
}
.overrideSection {
  margin: 15px;
  position: relative;
}
.bottomDashboard {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin-bottom: 5px;
  /* line-height: 180%; */
}
div::-webkit-scrollbar {
  /*Your styles here*/
  width: 0.5em;
  height: 1em;
}
div::-webkit-scrollbar-thumb {
  /*Your styles here*/
  background-color: var(--lighterPurple);
  outline: 1px solid var(--Purple);
}
div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.customScrollForHomeTable {
  height: calc(100vh - 260px);
  overflow-y: scroll;
}
.activeButtonStyling {
  background-color: var(--mainYellow) !important;
  color: white !important;
  border: none;
  outline: none;
  box-shadow: none;
}
</style>
