import jwtInterceptor from '../../shared/jwtInterceptor';
import {API_URL} from '../../.env';


const state=()=>({
    reports:[]
});


const getters={
    getReports(state){
        return state.reports
    }
};

const actions={
    async getReports({commit}){
        var response=await jwtInterceptor.get(`${API_URL}/api/reports/public/`);
        if(response && response.data){
            commit('setPublicReports', response.data);
        }
    }

};

const mutations={
    setPublicReports(state, payload){
        state.reports=payload
    }
};


export default{
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}