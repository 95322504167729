<template>
  <div class="modalSave" @click.stop="close">
    <div class="modalSaveDiv" ref="content">
      <div
        class="modal__detail"
        style="border-bottom: 2px solid var(--mainBlue)"
      >
        <h1 class="modal__title" v-if="isSaveChangesToReportOpen">
          Save changes
        </h1>
        <h1 class="modal__title" v-else>Save as new report</h1>

        <div class="row-bre" style="margin: 0.8rem">
          <form>
            <div class="margin-bottom">
              <label for="inputReportName">Report Name</label>
              <input
                type="text"
                placeholder="Report Name"
                class="form-control inputOverrideQueryBuilder marginBetween"
                v-model="reportName"
              />
            </div>
            <div class="margin-bottom">
              <label for="reportDescription">Report description</label>
              <textarea
                class="
                  form-control
                  textAreaDescription
                  reportDescriptionCheck
                  marginBetween
                "
                placeholder="Report Description"
                v-model="reportDescription"
              ></textarea>
            </div>
            <div class="float-right">
              <label for="inputUser">User</label>

              <select
                id="inputUser"
                class="inputOverride inputOverrideQueryBuilder marginBetween"
                v-model="user"
                @change="onUserChangeGetReportGroups()"
              >
                <option
                  v-for="t in listOfUsers"
                  :key="t.id"
                  :value="t.id"
                  :selected="user === t.id"
                >
                  {{ t.label }}
                </option>
              </select>
            </div>
            <div>
              <label for="inputSchema">Schema</label>

              <select
                disabled
                id="inputSchema"
                v-model="selectedSchemaID"
                class="inputOverride inputOverrideQueryBuilder marginBetween"
              >
                <option
                  v-for="t in gettersUserPermissions"
                  :key="t.schema.id"
                  :value="t.schema.id"
                  :selected="selectedSchemaID === t.schema.id"
                >
                  ({{ t.schema.dataset.name }})
                  {{ t.schema.name }}
                </option>
              </select>
            </div>
            <label for="inputReport">Report group</label>
            <div
              style="
                display: flex;

                flex-direction: row;
                align-items: stretch;
                align-content: stretch;
              "
            >
              <div style="display: flex; justify-content: space-between">
                <select
                  id="inputReport"
                  class="
                    inputOverrideWeight
                    inputOverrideQueryBuilder
                    marginBetween
                  "
                  v-model="reportGroup"
                >
                  <option
                    v-for="group in groupResponse"
                    :key="group.id"
                    :value="group.id"
                  >
                    <span v-if="reportTypeChanged">{{ group.group_name }}</span>
                    <span v-else>{{ group.name }}</span>
                  </option>
                </select>
                <button
                  title="Add new group"
                  class="btn btn-regular btn-small marginButtonModal tooltip"
                  style="margin-top: 0.3rem"
                  v-on:click.prevent="this.openNewReportGroupModal()"
                >
                  <i
                    class="ikonicaPlus pi pi-plus-circle"
                    style="margin-bottom: 0.5rem"
                  ></i>

                  <span class="tooltiptext">Add new group</span>
                </button>
              </div>
            </div>

            <teleport v-if="isReportGroupModalOpen == true" to="#modals">
              <add-new-report-group-modal
                v-on:modalState="onReportGroupModalStateChange"
              />
            </teleport>
            <div class="margin-bottom float-right">
              <label for="InputReportType">Report type</label>

              <select
                class="inputOverride inputOverrideQueryBuilder marginBetween"
                id="InputReportType"
                v-model="reportType"
                :disabled="!this.gettersAllInfo.is_superuser"
                @change="onReportTypeChangeGetReportGroups()"
              >
                <option selected disabled hidden>Ucitavanje ...</option>

                <option value="0">Public</option>
                <option value="1">Private</option>
              </select>
            </div>
            <div class="margin-bottom">
              <label for="inputQuery">Query type</label>

              <select
                disabled
                class="inputOverride inputOverrideQueryBuilder marginBetween"
                id="inputReportQueryType"
                v-model="queryType"
              >
                <option selected disabled hidden>Ucitavanje ...</option>

                <option value="1">Tool</option>
                <option value="0">SQL</option>
              </select>
            </div>
          </form>
        </div>
      </div>
      <div
        style="
          display: flex;
          margin: 0.5rem;
          flex-direction: row;
          align-items: stretch;
          align-content: stretch;
        "
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            flex: 2 0 0;
            justify-content: left;
          "
        >
          <div style="display: flex; justify-content: right">
            <div style="align-self: center">
              <button
                class="btn btn-regular"
                style="margin-right: 1rem; margin-left: 0.5rem; width: 15rem"
                @click.stop="saveReportChanges()"
              >
                Save
              </button>
            </div>
          </div>
        </div>

        <div style="display: flex; justify-content: flex-end">
          <div style="align-self: center">
            <button
              class="btn btn-regular btnTabel"
              @click.stop="emitToParent('Close')"
            >
              Close
            </button>
          </div>
        </div>
      </div>

      <a @click.stop="emitToParent('Close')" class="link-2"></a>
    </div>
  </div>
</template>

<script>
import AddNewReportGroupModal from "./AddNewReportGroupModal.vue";
import jwtInterceptor from "../shared/jwtInterceptor";
import { mapActions, mapGetters } from "vuex";
import { API_URL } from "../../src/.env.js";
export default {
  name: "SaveNewReport",
  components: { AddNewReportGroupModal },
  props: [
    "dataForTableCreationPassedObj",
    "selectedChildButtonPassed",
    "reportNamePassed",
    "reportDescriptionPassed",
    "reportGroupPassed",
    "reportTypePassed",
    "isSaveChangesToReportOpenPassed",
    "editedReportQueryIDPassed",
    "userPassed",
  ],
  data() {
    return {
      postGroupName: "",
      postGroupDescription: "",
      user: this.userPassed,
      reportGroup: this.reportGroupPassed,
      greskaResponse: [],
      reportName: this.reportNamePassed,
      reportDescription: this.reportDescriptionPassed,
      listOfUsers: "",
      groupResponse: "",
      isReportGroupModalOpen: false,
      reportType: this.reportTypePassed,
      queryType: 1,
      dataForTableCreationObj: this.dataForTableCreationPassedObj,
      selectedSchemaID: this.selectedChildButtonPassed,
      reports: [],
      queryID: this.editedReportQueryIDPassed,
      isSaveChangesToReportOpen: this.isSaveChangesToReportOpenPassed,
      listener: (e) => {
        e.key === "Escape" && this.emitToParent("Close");
      },
      eventt: "keydown",
      listenerForEnter: (e) => {
        e.ctrlKey && e.keyCode == 13 && this.saveReportChanges();
      },
      reportTypeChanged: false,
    };
  },
  mounted() {
    if (!this.gettersAllInfo.is_superuser) {
      this.reportType = 1;
    }
    window.addEventListener(this.eventt, this.listener);
    window.addEventListener(this.eventt, this.listenerForEnter);
  },
  created() {
    this.getUserPermissions();
    this.getOwnedUsers();
    this.getReportGroup();
  },
  computed: {
    ...mapGetters("information", {
      gettersAllInfo: "getAllInfo",
    }),
    ...mapGetters("permissions", {
      gettersUserPermissions: "getUserPermissions",
    }),
    ...mapGetters("auth", {
      getUserIdFromToken: "getUserId",
    }),
  },
  methods: {
    ...mapActions("permissions", {
      actionsGetUserPermissions: "getUserPermissions",
    }),
    async getUserPermissions() {
      await this.actionsGetUserPermissions();
    },
    ...mapActions("information", {
      actionsGetAllInfo: "getAllInfo",
    }),
    async getAllInfo() {
      await this.actionsGetAllInfo();
    },
    onReportTypeChangeGetReportGroups() {
      this.reportTypeChanged = true;
      if (this.reportType == 1) {
        this.getPrivateReports();
      } else {
        this.getPublicReports();
      }
    },
    getPublicReports() {
      jwtInterceptor
        .get(`${API_URL}/api/reports/public/`)
        .then((res) => {
          this.groupResponse = res.data;
          // this.reportGroup = this.groupResponse[0].group_id;
        })
        .catch(() => {
          // Manage errors if found any
        });
    },
    getPrivateReports() {
      jwtInterceptor
        .get(`${API_URL}/api/reports/private/`)
        .then((res) => {
          //smestanje svih rezultata u reports
          //odvajanje group liste
          this.groupResponse = res.data;
        })
        .catch(() => {
          // Manage errors if found any
        });
    },
    onUserChangeGetReportGroups() {
      this.reportTypeChanged = false;
      jwtInterceptor
        .get(`${API_URL}/api/groups/?user_id=${this.user}`)
        .then((res) => {
          this.groupResponse = res.data;
          this.reportGroup = res.data[0].id;
        })
        .catch(() => {});
    },
    openNewReportGroupModal() {
      window.removeEventListener(this.eventt, this.listener);
      window.removeEventListener(this.eventt, this.listenerForEnter);

      this.isReportGroupModalOpen = true;

      if (document.activeElement.toString() == "[object HTMLButtonElement]") {
        document.activeElement.blur();
      }
    },
    onReportGroupModalStateChange(value) {
      window.addEventListener(this.eventt, this.listener);
      window.addEventListener(this.eventt, this.listenerForEnter);
      if (value.state == "Save") {
        //
        this.postGroupName = value.postGroupName;
        this.postDescriptionName = value.postDescriptionName;
        this.addNewReportGroup();

        this.isReportGroupModalOpen = false;
      } else if (value.state == "Close") {
        //

        this.isReportGroupModalOpen = false;
      }
    },
    async addNewReportGroup() {
      await jwtInterceptor
        .post(
          `${API_URL}/api/groups/`,

          {
            name: this.postGroupName,
            description: this.postDescriptionName,
            user: this.user,
          }
        )
        .then((response) => {
          this.postGroupName = "";
          this.postDescriptionName = "";
          this.reportGroup = response.data.id;
          this.$toast.add({
            severity: "success",
            summary: "Success Message",
            detail: "New report group has been successfully added.",
            life: 3000,
          });
          this.getReportGroup();
        })
        .catch((error) => {
          this.greskaResponse = error.response.data.error;
          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: this.greskaResponse,
            life: 4000,
          });
        });
    },
    getReportGroup() {
      //TODO: pitati saleta da li //?user_id=${this.user} da li ovaj opcioni query treba prilikom pozivanja ovog API-ja?
      jwtInterceptor
        .get(`${API_URL}/api/groups/?user_id=${this.user}`)
        .then((res) => {
          this.groupResponse = res.data;
        })
        .catch(() => {});
    },
    getOwnedUsers() {
      jwtInterceptor
        .get(`${API_URL}/api/profile/users/`, {})
        .then((res) => {
          this.listOfUsers = res.data;
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          // Manage errors if found any
        });
    },
    saveReportChanges() {
      if (this.isSaveChangesToReportOpen) {
        this.saveChangesToReport();
      } else {
        this.addNewReport();
      }
    },
    saveChangesToReport() {
      this.dataForTableCreationObj.filters.forEach((element) => {
        delete element.column_name;
        delete element.column_description;
        delete element.column_static;
        delete element.testProperty2;
        delete element.testProperty;
      });
      jwtInterceptor
        .patch(`${API_URL}/api/reports/${this.queryID}/`, {
          name: this.reportName,
          description: this.reportDescription,
          type: this.reportType,
          query_type: this.queryType,
          group: this.reportGroup,
          user: this.user,
          schema: this.selectedSchemaID,
          user_by: this.getUserIdFromToken,
          data: JSON.stringify(this.dataForTableCreationObj),
        })
        .then(() => {
          // clear form inputs
          this.reportName = this.reportDescription = "";
          this.emitToParent("NewReportAddedSuccessfully");
          this.$toast.add({
            severity: "success",
            summary: "Success Message",
            detail: "The report has been successfully edited.",
            life: 3000,
          });
        })
        .catch((error) => {
          var patchGreska = "";

          if (
            JSON.stringify(error.response.data.name) ===
            '["This field may not be blank."]'
          ) {
            patchGreska =
              "In order to Save report, you need to enter Report Name Field";
          } else {
            this.emitToParent("NewReportAddedUnSuccessfully");
            patchGreska = error.response.data.non_field_errors[0];
          }
          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: patchGreska,
            life: 4000,
          });

          if (
            document.activeElement.toString() == "[object HTMLButtonElement]"
          ) {
            document.activeElement.blur();
          }
        });
    },
    addNewReport() {
      this.dataForTableCreationObj.filters.forEach((element) => {
        delete element.column_name;
        delete element.column_description;
        delete element.column_static;
        delete element.testProperty2;
        delete element.testProperty;
      });
      jwtInterceptor
        .post(`${API_URL}/api/reports/`, {
          name: this.reportName,
          description: this.reportDescription,
          type: this.reportType,
          query_type: this.queryType,
          group: this.reportGroup,
          user: this.user,
          user_by: this.getUserIdFromToken,
          schema: this.selectedSchemaID,
          data: JSON.stringify(this.dataForTableCreationObj),
        })
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          // clear form inputs
          this.queryID = response.data.id;
          this.$toast.add({
            severity: "success",
            summary: "Success Message",
            detail: "The report has been successfully saved.",
            life: 3000,
          });

          if (
            document.activeElement.toString() == "[object HTMLButtonElement]"
          ) {
            document.activeElement.blur();
          }
          this.emitToParent("NewReportAddedSuccessfully");
        })
        .catch((error) => {
          // clear form inputs

          if (
            JSON.stringify(error.response.data.name) ===
              '["This field may not be blank."]' ||
            JSON.stringify(error.response.data.non_field_errors) ===
              '["The fields user, name must make a unique set."]'
          ) {
            this.greskaResponse =
              "In order to Save report, you need to enter unique Report Name Field";
          } else {
            this.greskaResponse = error.response.data.non_field_errors[0];
            this.emitToParent("NewReportAddedUnSuccessfully");
          }

          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: this.greskaResponse,
            life: 4000,
          });
          this.greskaResponse = [];
          if (
            document.activeElement.toString() == "[object HTMLButtonElement]"
          ) {
            document.activeElement.blur();
          }
        });
      console.log(this.isReportGroupModalOpen);
    },

    emitToParent: function (modalState) {
      var result = {};

      result.state = modalState;
      result.patchID = this.queryID;

      this.$emit("modalState", result);
    },
  },
  unmounted() {
    window.removeEventListener(this.eventt, this.listener);
    window.removeEventListener(this.eventt, this.listenerForEnter);
  },
};
</script>

<style></style>
