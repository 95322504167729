import jwtInterceptor from '../../shared/jwtInterceptor';
import {API_URL} from '../../.env';


const state=()=>({
    groups:[]
});


const getters={
    getReportGroups(state){
        return state.groups
    }
};

const actions={
    async getReportGroups({commit}){
        var response=await jwtInterceptor.get(`${API_URL}/api/groups/`);
        if(response && response.data){
            commit('setReportGroups', response.data);
        }
    }

};

const mutations={
    setReportGroups(state, payload){
        state.groups=payload
    }
};


export default{
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}