import jwtInterceptor  from '../../shared/jwtInterceptor';
import { API_URL } from '../../.env';


const state = () => ({
    info:[],
    informationData:{
    firstName:"",
    username:"",
    lastName:"",
    email:"",
    companyName:"",
contact_name:"",
description:"",
phoneNumber:""}
});

const getters = {
    getAllInfo(state){
        return state.info;
    },
    getSeparateInfo(state){
        return state.informationData;
    }
};

const actions = {
    async getAllInfo({commit}){
        var response = await jwtInterceptor.get(`${API_URL}/api/profile/info/`);
        if(response && response.data){
            commit('setInfo', response.data);
           

        }
    },
    async getSeparateInfo({commit}){
        var response = await jwtInterceptor.get(`${API_URL}/api/profile/info/`);
        if(response && response.data){
                   commit('setAllInfo', response.data);

        }
    },

};

const mutations = {
    setInfo(state, payload){
        state.info = payload

    },
    setAllInfo(state,data){
        const newInformationData={
            firstName:data.first_name,
            username:data.username,
            lastName:data.last_name,
            email:data.email,
            companyName:data.company.name,
            contact_name:data.company.contact_name,
            description:data.company.description,
            phoneNumber:data.company.contact_phone_number
        }
        state.informationData=newInformationData
    }
};

export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}