
export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const LOGOUT_ACTION='[actions] Logout users'


// export const SIGNUP_ACTION = '[actions] signup user';
// export const LOGIN_ACTION = '[actions] login user';
// export const LOGOUT_ACTION = '[actions] Logout User';
// export const AUTO_LOGOUT_ACTION = '[actions] auto logout';
// export const AUTH_ACTION = '[actions] DO User Auth';
// export const AUTO_LOGIN_ACTION = '[actions] Auth AUto Login';
 export const SET_USER_TOKEN_DATA_MUTATION =
     '[mutations] set user token data';
// export const LOADING_SPINNER_SHOW_MUTATION =
//     '[mutations] show loading spinner';
// export const SET_AUTO_LOGOUT_MUTATION = '[mutations] set auto logout';

 export const GET_USER_TOKEN_GETTER = '[getters] auth user token';
 export const GET_USER_ROLE='[getters] user role';
 export const IS_USER_AUTHENTICATE_GETTER =
     '[getters] is user authenticated';