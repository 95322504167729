import { createApp } from 'vue'
import App from './App.vue'
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

import * as appRouter from './appRouter'
import store from './store/index'
import 'bootstrap';
import 'halfmoon';
import 'tabulator-tables';
import VueApexCharts from "vue3-apexcharts";
import PrimeVue from 'primevue/config'
import Dialog from 'primevue/dialog'
import ButtonP from 'primevue/button'
import InputText from 'primevue/inputtext'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ProgressSpinner from 'primevue/progressspinner'
import ProgressBar from 'primevue/progressbar'
import ContextMenu from 'primevue/contextmenu';
import Tabulator from "tabulator-tables";
import Calendar from 'primevue/calendar'
// import ColumnGroup from 'primevue/columngroup';
import 'primevue/resources/themes/saga-blue/theme.css'       //theme
import 'primevue/resources/primevue.min.css'              //core css
import 'primeicons/primeicons.css'                           //icons
import './style/style.css'
import 'tabulator-tables/dist/js/tabulator.min.js'
import 'tabulator-tables/dist/css/tabulator.min.css'
import emitter from './mitt';
const app = createApp(App)

app.use(appRouter.routeConfig)
app.use(store)
app.use(PrimeVue)
app.use(VueApexCharts)
app.use(ToastService)

app.component('Dialog', Dialog)
app.component('ProgressSpinner', ProgressSpinner)
app.component('ProgressBar', ProgressBar)
app.component('DataTable', DataTable)
app.component('ContextMenu',ContextMenu)
app.component('Column', Column)
app.component('ButtonP', ButtonP)
app.component('InputText', InputText)
app.component('Toast', Toast)
app.component("Tabulator", Tabulator)
app.component("Calendar", Calendar)

window.emitter = emitter;

// We add a global $mitt property
app.config.globalProperties.$mitt = emitter;

app.mount('#app')