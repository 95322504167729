<template>
  <div class="margin-bottom">
    <div class="modalInformation" @click.stop="close" tabindex="-1">
      <div
        ref="content"
        class="modalDivInformation"
        style="border-bottom: 2px solid var(--mainBlue)"
      >
        <slot />
        <h1 class="modal__title" style="text-align: left; margin-left: 1rem">
          Add new permission
        </h1>
        <form>
          <div class="row margin-bottom">
            <div class="col-sm-3">
              <label for="postGroupName">Company</label>
            </div>
            <div class="col-sm-8">
              <select
                id="inputSchema"
                v-model="postPermissionCompany"
                class="form-control"
              >
                <option
                  v-for="t in companies"
                  :key="t.id"
                  :value="t.id"
                  :selected="postPermissionCompany === t.id"
                >
                  {{ t.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row margin-bottom">
            <div class="col-sm-3">
              <label for="postGroupName">Schema</label>
            </div>
            <div class="col-sm-8">
              <select
                id="inputSchema"
                v-model="postPermissionSchema"
                class="form-control"
              >
                <option
                  v-for="t in schemas"
                  :key="t.id"
                  :value="t.id"
                  :selected="postPermissionSchema === t.id"
                >
                  {{ t.schemaLabel }} - {{ t.label }}
                </option>
              </select>
            </div>
          </div>
          <div class="row margin-bottom">
            <div class="col-sm-3">
              <label for="postGroupName">Active</label>
            </div>
            <div class="col-sm-8">
              <input
                id="postGroupName"
                v-model="postPermissionActive"
                class="hoverCheckbox"
                type="checkbox"
                style="display: inline; margin-left: 1rem;vertical-align: middle"
              />
            </div>
          </div>
          <div class="row margin-bottom">
            <div class="col-sm-3">
              <label for="postGroupName">Visible</label>
            </div>
            <div class="col-sm-8">
              <input
                id="postGroupName"
                v-model="postPermissionVisible"
                class="hoverCheckbox"
                type="checkbox"
                style="display: inline; margin-left: 1rem;vertical-align: middle"
              />
            </div>
          </div>
          <div class="row margin-bottom">
            <div class="col-sm-3">
              <label for="postGroupName">Row export limit</label>
            </div>
            <div class="col-sm-8">
              <input
                id="postGroupName"
                v-model="postRowExportLimit"
                type="text"
                class="form-control modalOverride"
              />
            </div>
          </div>
          <div class="row margin-bottom">
            <div class="col-sm-3">
              <label for="postGroupName">Valid until</label>
            </div>
            <div class="col-sm-8">
              <date-picker :modelConfig="modelConfig" v-model="postValidUntil">
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300;"
                    :value="inputValue"
                    v-on="inputEvents"
                    style="width: 15rem!important;"
                    placeholder="from"
                  />
                </template>
              </date-picker>
            </div>
          </div>

          <a @click.stop="emitToParent('Close')" class="link-2"></a>
          <div
            style="
                      display: flex;
                      margin: 0.5rem;
                      flex-direction: row;
                      align-items: stretch;
                      align-content: stretch;
                    "
          >
            <div
              style="
                        display: flex;
                        flex-direction: column;
                        flex: 2 0 0;
                        justify-content: left;
                      "
            >
              <div style="display: flex; justify-content: flex-end">
                <div style="align-self: center">
                  <button
                    class="btn btn-regular btnTabel"
                    @click.stop="emitToParent('Save')"
                  >
                    Add
                  </button>
                  <button
                    class="btn btn-regular btnTabel"
                    style="margin-left: 0.5rem"
                    @click.stop="emitToParent('Close')"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import jwtInterceptor from "../../shared/jwtInterceptor";
import { API_URL } from "../../.env";

import { DatePicker } from "v-calendar";
export default {
  data: function() {
    return {
      companies: [],
      schemas: [],
      postPermissionCompany: 0,
      postPermissionSchema: 0,
      postPermissionActive: false,
      postPermissionVisible: false,
      postRowExportLimit: 5000,
      postValidUntil: new Date().toLocaleDateString("sv").slice(0, 10),

      listener: (e) => {
        e.key === "Escape" && this.emitToParent("Close");
      },
      childEvent: "keydown",
      childEventForEnter: "keydown",
      listenerForEnter: (e) => {
        e.ctrlKey && e.keyCode == 13 && this.emitToParent("Save");
      },
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD", // Uses 'iso' if missing
      },
    };
  },
  components: {
    DatePicker,
  },
  mounted() {
    this.getAllCompanies();
    this.getAllSchema();
    window.addEventListener(this.childEvent, this.listener);
    window.addEventListener(this.childEventForEnter, this.listenerForEnter);
  },
  methods: {
    getAllCompanies() {
      this.loading = true;
      jwtInterceptor
        .get(`${API_URL}/api/companies/`)
        .then((res) => {
          this.companies = res.data;
          this.postPermissionCompany = this.companies[0].id;
        })

        .catch((error) => {
          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: error.response.data,
            life: 4000,
          });
          if (
            document.activeElement.toString() == "[object HTMLButtonElement]"
          ) {
            document.activeElement.blur();
          }

          // Manage errors if found any
        });
    },
    getAllSchema() {
      jwtInterceptor
        .post(`${API_URL}/api/metadata/schemas/`)
        .then((response) => {
          var schemas = response.data.result;

          schemas.forEach((element) => {
            element.schemas.forEach((podElement) => {
              podElement.schemaLabel = element.label;
              this.schemas.push(podElement);
            });
          });

          this.postPermissionSchema = this.schemas[0].id;
        })
        .catch(() => {});
    },
    //Reconsider calling API function here instead in parent
    //because we need two way communication now

    emitToParent: function(modalState) {
      var result = {};
      result.postPermissionActive = this.postPermissionActive;
      result.postPermissionVisible = this.postPermissionVisible;
      result.postRowExportLimit = this.postRowExportLimit;
      result.postValidUntil = this.postValidUntil;
      result.postPermissionCompany = this.postPermissionCompany;
      result.postPermissionSchema = this.postPermissionSchema;
      result.state = modalState;

      this.$emit("modalState", result);
    },
  },
  unmounted() {
    window.removeEventListener(this.childEvent, this.listener);
    window.removeEventListener(this.childEventForEnter, this.listenerForEnter);
  },
};
</script>

<style></style>
