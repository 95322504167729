<template>
  <main id="my-reports" v-on:keydown.esc="close">
    <aside>
      <div class="report-list">
        <div class="overrideButtons margin-bottom" style="margin-top: 2rem">
          <button
            class="btn btn-regular width-100 margin-bottom-small align-left"
            @click="openClose()"
          >
            <i class="pi pi-bars"></i>
            Info
          </button>
        </div>
        <div class="overrideButtons margin-bottom">
          <button
            class="btn btn-regular width-100 margin-bottom-small align-left"
            @click="
              openClose3();
              showMonthlyUsageEditor();
            "
          >
            <i class="pi pi-bars"></i>
            Permissions
          </button>
        </div>
      </div>
    </aside>
    <section>
      <template v-if="isOpen">
        <div class="container">
          <div class="report-group">
            <div class="dashboardBlock" style="margin-right: 3rem;">
              <div class="tabs">
                <input
                  type="radio"
                  name="tab-btn"
                  id="tab-btn-1"
                  value=""
                  checked
                />
                <label for="tab-btn-1" style="margin-bottom:2rem"
                  ><i class="pi pi-users"></i>User info</label
                >

                <input type="radio" name="tab-btn" id="tab-btn-2" value="" />
                <label for="tab-btn-2" style="margin-bottom:2rem">
                  <i class="pi pi-cog"></i>Change password</label
                >

                <div id="content-1">
                  <div class="margin-bottom ">
                    <label>Username</label>

                    <label class="informationalLabel">
                      {{ gettersSeparateInfo.username }}</label
                    >
                  </div>
                  <div class="margin-bottom">
                    <label>First Name</label>
                    <label class="informationalLabel">{{
                      gettersSeparateInfo.firstName
                    }}</label>
                  </div>
                  <div class="margin-bottom">
                    <label>Last Name</label>
                    <label class="informationalLabel">{{
                      gettersSeparateInfo.lastName
                    }}</label>
                  </div>
                  <div class="margin-bottom">
                    <label>email</label>
                    <label class="informationalLabel">{{
                      gettersSeparateInfo.email
                    }}</label>
                  </div>
                </div>
                <div id="content-2">
                  <label>Change password</label>
                  <change-password
                    v-on:modalState="onModalStateChanged"
                  ></change-password>
                </div>
              </div>
            </div>

            <div class="dashboardBlock">
              <div class="tabs">
                <input
                  type="radio"
                  name="tab-btn"
                  id="tab-btn-3"
                  value=""
                  disabled
                />
                <label for="tab-btn-3" style="background-color:white">
                  <i class="pi pi-info-circle"></i>Licence info</label
                >
              </div>
              <div id="content-3">
                <div class="margin-bottom">
                  <label>Company name</label>

                  <label class="informationalLabel">{{
                    gettersSeparateInfo.companyName
                  }}</label>
                </div>
                <div class="margin-bottom">
                  <label>Contact name</label>
                  <label class="informationalLabel">{{
                    gettersSeparateInfo.contact_name
                  }}</label>
                </div>
                <div class="margin-bottom">
                  <label>Contact phone name</label>
                  <label class="informationalLabel">{{
                    gettersSeparateInfo.phoneNumber
                  }}</label>
                </div>
                <div class="margin-bottom">
                  <label>Company description</label>
                  <label class="informationalLabel">{{
                    gettersSeparateInfo.description
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <div class="report-group block overrideSectionDashboard" v-if="isOpen3">
        <template v-if="SpremanZaPrikaz === true">
          <h1>Permissions</h1>
          <div class="fixTableHead">
            <table class="styled-table">
              <thead>
                <tr>
                  <th>Dataset</th>
                  <th>Schema</th>
                  <th>Activated</th>
                  <th>Valid untill</th>
                  <th>Updated</th>
                  <th>Is Active</th>
                  <th>Monthly usage left</th>
                  <th>Permission usage log</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="t in gettersUserPermissions" :key="t.id">
                  <td>{{ t.schema.dataset.name }}</td>
                  <td>{{ t.schema.name }}</td>
                  <td :style="[!t.active ? 'color:red' : true]">
                    {{ t.active ? "Yes" : "No" }}
                  </td>
                  <td
                    :style="[
                      !t.is_active ? 'color:red' : true ? 'color:green' : false,
                    ]"
                  >
                    {{ t.valid_until }}
                  </td>
                  <td>{{ moment(t.updated_at).format("YYYY-MM-DD") }}</td>
                  <td :style="[!t.is_active ? 'color:red' : true]">
                    {{ t.is_active ? "Yes" : "No" }}
                  </td>
                  <td>{{ t.prikazTesta }}</td>
                  <template v-if="t.pokreniModal === true">
                    <button
                      style="margin-left: 4rem; margin-top: 0.7rem; margin-bottom:0.5rem;"
                      class="btn btn-regular"
                      v-on:click="showPopUpMonthlyAPI(t)"
                    >
                      Show data
                    </button>
                  </template>
                  <teleport v-if="modalPermission" to="#modals">
                    <div class="modalPermission" @click.stop="close">
                      <div
                        class="modalPermissionDiv"
                        ref="content"
                        style="    border-bottom: 2px solid var(--mainBlue);"
                      >
                        <h1 class="modal__title">Permission usage data</h1>
                        <DataTable
                          :value="responseTabela"
                          :rowHover="true"
                          :scrollable="true"
                          scrollHeight="600px"
                          removableSort
                          style="border-bottom: 2px solid var(--mainBlue);"
                        >
                          <Column
                            v-for="col of koloneTabele"
                            :field="col.field"
                            :header="col.header"
                            :key="col.field"
                            :sortable="true"
                            style="min-width: 10rem"
                          ></Column>
                        </DataTable>

                        <div
                          style="display: flex;
  
    flex-direction: row;
    align-items: stretch;
    align-content: stretch;
    "
                        >
                          <div
                            style="display: flex;
	flex-direction: column;
    flex: 2 0 0;
        justify-content: space-between;margin:0.5rem;
"
                          >
                            <div
                              style="  display: flex;
    justify-content: space-between;"
                            >
                              <div style="align-self: center;"></div>

                              <button
                                class="btn btn-regular"
                                style="align-self: center;width:8rem;"
                                @click.stop="modalPermission = !modalPermission"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </teleport>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </div>

      <div>{{ permissionUsageShow }}</div>
    </section>
  </main>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
// const axios = require("axios");

// import jwtInterceptor from "../shared/jwtInterceptor";

// import { API_URL } from "../../src/.env.js";
import { LOGOUT_ACTION } from "../store/actions/auth";

import moment from "moment";

import ChangePassword from "./ChangePassword.vue";

export default {
  data() {
    return {
      info: [],
      text: null,
      permissions: [],
      isOpen: false,
      isOpen3: false,
      activeColor: "red",
      rowCounter: "",
      SpremanZaPrikaz: false,
      modalPermission: false,
      permissionUsageShow: "",
      koloneTabele: [],
      responseTabela: {},
      modalChangePassword: false,
      noviArray: [],
    };
  },

  created() {
    this.getUserPermissions();

    this.getSeparateInfo();
    this.moment = moment;
    this.btnChange();
  },

  components: {
    ChangePassword,
  },
  computed: {
    filteredList() {
      const list = this.gettersUserPermissions.filter(
        (item) => item.permission_usage.length
      );

      return list;
    },
    mainUsage() {
      // to show main_usage just filter out the items that has no permission usage

      const value = this.gettersUserPermissions.filter(
        (item) => item.permission_usage.length === 0
      );

      return value;
    },

    ...mapGetters("information", {
      gettersAllInfo: "getAllInfo",
    }),
    ...mapGetters("information", {
      gettersSeparateInfo: "getSeparateInfo",
    }),
    ...mapGetters("auth", {
      gettersAuthData: "getAuthData",
    }),
    ...mapGetters("permissions", {
      gettersUserPermissions: "getUserPermissions",
    }),
  },
  methods: {
    onModalStateChanged(value) {
      if (value.state == "Open") {
        //do nothing, stay on the page
      } else if (value.state == "Close") {
        this.changePasswordVisibilityWithDelay();
      }
    },
    changePasswordVisibilityWithDelay() {
      setTimeout(
        () => (document.getElementById("tab-btn-1").checked = true),
        3000
      );
    },

    close(e) {
      if (this.$refs?.content?.contains(e.target)) return;

      this.$emit("close");
      this.modalPermission = false;
      if (document.activeElement.toString() == "[object HTMLButtonElement]") {
        document.activeElement.blur();
      }
    },
    btnChange() {
      if (document.activeElement.toString() == "[object HTMLButtonElement]") {
        document.activeElement.blur();
      }
    },
    clearCol() {
      this.koloneTabele = [];
      this.responseTabela = [];
    },
    showPopUpMonthlyAPI(refToTable) {
      this.modalPermission = true;

      this.koloneTabele = [];

      this.noviArray = refToTable.permission_usage;

      this.noviArray.forEach((element) => {
        if (
          element.year === this.currentYear() &&
          element.month === this.currentMonth()
        ) {
          this.responseTabela = element.permission_usage_log;
        }
      });

      for (var key in this.responseTabela[0]) {
        var jednaKolona = { field: key, header: key };

        this.koloneTabele.push(jednaKolona);
      }
    },

    showMonthlyUsageEditor() {
      this.gettersUserPermissions.forEach((element) => {
        element.pokreniModal = false;
        element.prikazTesta = "TESTIRANJE";
        if (element.active === true) {
          this.rowCounter = element.row_export_limit;
          element.prikazTesta = element.row_export_limit;
        } else {
          this.rowCounter = "None";
          element.prikazTesta = "None";
        }

        //element.can_view = "TESTIRANJE";
        element.permission_usage.forEach((elementIspod) => {
          if (
            elementIspod.year === this.currentYear() &&
            elementIspod.month === this.currentMonth() &&
            element.active === true &&
            elementIspod.permission_usage_log != 0
          ) {
            element.pokreniModal = true;

            element.prikazTesta = elementIspod.row_export_usage;
            this.rowCounter = elementIspod.row_export_usage;
          } else if (element.active === false) {
            element.prikazTesta = "None";
            this.rowCounter = "none";
          }
        });
      });

      this.SpremanZaPrikaz = true;
    },

    currentYear() {
      const current = new Date();
      const year = current.getFullYear();

      return year;
    },
    currentMonth() {
      const current = new Date();
      const month = current.getMonth() + 1;

      return month;
    },
    ...mapActions("auth", {
      logout: LOGOUT_ACTION,
    }),
    ...mapActions("information", {
      actionsGetAllInfo: "getAllInfo",
    }),
    ...mapActions("information", {
      actionsGetSeparateInfo: "getSeparateInfo",
    }),
    ...mapActions("permissions", {
      actionsGetUserPermissions: "getUserPermissions",
    }),

    onLogout() {
      this.logout();
      location.reload();
      this.$router.push("/login");
    },
    openClose() {
      this.isOpen = true;

      this.isOpen3 = false;
    },
    openClose3() {
      this.isOpen3 = true;

      this.isOpen = false;
    },

    async show() {
      await this.actionsGetAllInfo();
    },
    async getSeparateInfo() {
      await this.actionsGetSeparateInfo();
    },
    async getUserPermissions() {
      await this.actionsGetUserPermissions();
    },
  },
};
</script>
<style scoped>
.fixTableHead {
  overflow-y: auto;
  height: 85%;
  border-bottom: 2px solid var(--mainBlue);
}
.fixTableHead thead th {
  position: sticky;
  top: 0;
}
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 15px;
}
th {
  background-color: var(--mainBlue);
}
@media all and (min-width: 1600px) {
  .dashboardBlock {
    min-height: 28rem !important;
  }
}
.dashboardBlock {
  display: inline-block;
  padding: 8px 0 8px 8px;
  background: white;
  box-shadow: -1px -1px 3px rgba(240, 243, 255, 0.7),
    1px 1px 3px rgba(200, 207, 232, 0.7);
  border-radius: 3px;
  margin-top: 2rem;
  width: 48%;
  height: auto;
  min-height: 32rem;
  vertical-align: top;
}
.informationalLabel {
  color: var(--mainYellow);
  display: block;
  margin-left: 1rem;
  font-size: 14px !important;
  text-transform: none;
}
.tabs {
  /* margin: 0 auto;
  max-width: 1000px;
  font-size: 0; */
  /* margin-top: 2rem; */
  margin-bottom: 2rem;
}

.tabs > input[type="radio"] {
  display: none;
}

.tabs > div {
  display: none;
  font-size: 16px;
}

#tab-btn-1:checked ~ #content-1,
#tab-btn-2:checked ~ #content-2,
#tab-btn-3:checked ~ #content-3 {
  display: block;
}

.tabs > label {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: var(--lighterPurple);
  border: 1px solid var(--lightestPurple);
  padding: 10px 15px;
  font-size: 14px !important;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  cursor: pointer;
  position: relative;
  top: 1px;
}

.tabs > label:not(:first-of-type) {
  border-left: none;
}

.tabs > input[type="radio"]:checked + label {
  background-color: #fff;
  border-bottom: 1px solid #fff;
}

input {
  background-color: var(--lighterPurple) !important;
  color: var(--mainBlue) !important;
}
.p-datatable-scrollable .p-datatable-thead > tr,
.p-datatable-scrollable .p-datatable-tbody > tr,
.p-datatable-scrollable .p-datatable-tfoot > tr {
  height: 3rem !important;
}
.p-datatable .p-datatable-thead > tr > th {
  padding: 0.75rem 1rem !important;
}
.ikonicaDashboard {
  font-size: 4rem;
  color: var(--mainBlue);
}
input {
  height: auto;
  width: auto;
}
.overrideButtons {
  text-align: center;

  font-size: 1.5rem;
}
.activeColor {
  color: red;
}
.modalPermission {
  display: inherit; /* Hidden by default */
  position: fixed; /* Stay in place front is invalid - may break your css so removed */
  padding-top: 100px; /* Location of the box - don't know what this does?  If it is to move your modal down by 100px, then just change top below to 100px and remove this*/
  left: 0;
  right: 0; /* Full width (left and right 0) */
  top: 0;
  bottom: 0; /* Full height top and bottom 0 */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.3); /* Black w/ opacity */
  z-index: 9999; /* Sit on top - higher than any other z-index in your site*/
  text-align: center;
}
.modalPermissionDiv {
  background-color: white;
  width: 80%;
  border-radius: 0.5rem;
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);
}
.modalPermissionDiv div::-webkit-scrollbar {
  width: 1em;
  height: 1em;
}

.modalPermissionDiv div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.modalPermissionDiv div::-webkit-scrollbar-thumb {
  background-color: var(--lighterPurple);
  outline: 1px solid var(--Purple);
}
.p-datatable .p-datatable-tbody > tr > td {
  border: 1px solid #f0f3ff !important;
}
.p-datatable-thead th {
  background-color: #dfe4f3 !important;
}
.ui-datatable,
.ui-datatable .ui-datatable-tablewrapper table {
  font-size: 12px !important;
}

/* For Paginator */
.ui-datatable .ui-paginator .ui-paginator-first,
.ui-datatable .ui-paginator .ui-paginator-prev,
.ui-datatable .ui-paginator .ui-paginator-pages .ui-paginator-page,
.ui-datatable .ui-paginator .ui-paginator-next,
.ui-datatable .ui-paginator .ui-paginator-last,
.ui-datatable .ui-paginator .ui-paginator-rpp-options {
  font-size: 10px !important;
}

.ui-datatable .ui-paginator .ui-paginator-current {
  font-size: 10px !important;
}
thead th {
  font-size: 1em;
  padding: 0.7rem 2rem !important;
  height: 3rem;
  font-weight: unset;
}
.styled-table {
  border-collapse: collapse;
  font-size: 0.9em;
  font-family: sans-serif;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.styled-table thead tr {
  background-color: var(--mainBlue);
  color: #ffffff;
  text-align: left;
}
.styled-table th,
.styled-table td {
  padding: 1.5rem 2rem;
}
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.modal__title {
  font-size: 2rem;
  text-align: left;
  margin-left: 1rem;
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: var(--mainBlue);
}
.overrideSectionDashboard {
  height: calc(100vh - 13rem);
  margin-right: 1.5rem;
}
.overrideSectionHalfDashboard {
  width: 50%;
  height: calc(100vh - 60rem);
}
.form-control.disabled,
.form-control.disabled:hover,
.form-control:disabled,
.form-control:disabled:hover {
  cursor: pointer;
  opacity: 1;
}
input[data-v-aae30ed8] {
  width: 100%;
}
.p-datatable .p-datatable-thead > tr > th {
  font-size: 1.4rem !important;
  font-weight: normal !important;
}
.modalPassword {
  display: inherit; /* Hidden by default */
  position: fixed; /* Stay in place front is invalid - may break your css so removed */
  padding-top: 100px; /* Location of the box - don't know what this does?  If it is to move your modal down by 100px, then just change top below to 100px and remove this*/
  left: 0;
  right: 0; /* Full width (left and right 0) */
  top: 0;
  bottom: 0; /* Full height top and bottom 0 */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.3);

  z-index: 9999; /* Sit on top - higher than any other z-index in your site*/
  text-align: center;
}
.modalPasswordDiv {
  background-color: white;
  width: 50%;
  position: absolute;
  left: 50%;
  top: 50% !important;

  transform: translate(-50%, -50%);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
