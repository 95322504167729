<template>
  <div class="nav mobilenav">
    <div class="links">
      <router-link class="logo-header" to="/home"
        ><img src="../assets/segdb-logo.svg"
      /></router-link>
    </div>

    <div class="header-title">
      <li v-if="isAuthenticated">
        <router-link
          class="nav-link"
          style="margin-top: 0 !important"
          to="/app/login"
        ></router-link>
      </li>
      <li class="nav-items" v-if="isAuthenticated">
        <router-link
          class="nav-link"
          style="margin-top: 0 !important"
          to="/app/home"
          >Home</router-link
        >
      </li>
      <li class="nav-items" v-if="isAuthenticated">
        <router-link
          class="nav-link"
          style="margin-top: 0 !important"
          to="/app/myreports"
          >My reports</router-link
        >
      </li>
      <li class="nav-items" v-if="isAuthenticated">
        <router-link
          class="nav-link"
          style="margin-top: 0 !important"
          to="/app/publicreports"
          >Public reports</router-link
        >
      </li>
      <li class="nav-items" v-if="isAuthenticated">
        <router-link
          class="nav-link"
          style="margin-top: 0 !important"
          to="/app/reportbuilder"
         
          ><span  @click="handleClick">Report builder</span></router-link
        >
      </li>
      <li class="nav-items" v-if="isAuthenticated && gettersAuthData === true">
        <router-link
          class="nav-link"
          style="margin-top: 0 !important"
          to="/app/reportquery"
          ><span  @click="handleClick">Report query</span></router-link
        >
      </li>
    </div>
    <div class="logout">
      <li class="nav-items dropdown" v-if="isAuthenticated">
        <router-link
          class="nav-link"
          style="margin-top: 0 !important"
          to=""
          id="nav-link-dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          ><i i class="pi pi-user"></i
          >{{ gettersAllInfo.username }}</router-link
        >
        <div class="dropdown-menu" style="height: 75px">
          <router-link
            to="/app/dashboard"
            style="margin-top: 0 !important"
            class="dropdown-item"
            >Profile</router-link
          >
          <a
            class="dropdown-item"
            style="margin-top: 0 !important"
            href="#"
            @click.prevent="onLogout()"
            >Logout</a
          >
        </div>
      </li>

      <li
        class="nav-items"
        id="admin-panel"
        v-if="isAuthenticated && gettersAuthData === true"
      >
        <router-link
          style="
            margin-top: 0 !important;
            text-decoration: none;
            margin-left: 10px;
          "
          to="/app/admin"
          ><i class="pi pi-cog"></i>Admin</router-link
        >
        <!-- dodati sasin admin panel, al srediti onda logout -->
        <!-- <a href="http://localhost:8000/admin/">Admin</a> -->
      </li>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  IS_USER_AUTHENTICATE_GETTER,
  LOGOUT_ACTION,
} from "../store/actions/auth";
import emitter from '@/mitt';

export default {
  created() {
    this.getAllInfo();
  },
  props: ["username"],
  data() {
    return {
      navBarUsername: "",
    };
  },

  computed: {
    ...mapGetters("auth", {
      isAuthenticated: IS_USER_AUTHENTICATE_GETTER,
    }),
    ...mapGetters("auth", {
      gettersAuthData: "isUserAdminGetter",
    }),
    ...mapGetters("information", {
      gettersAllInfo: "getAllInfo",
    }),
  },
  methods: {
    handleClick() {
      emitter.emit("linkClicked");    },
    ...mapActions("auth", {
      logout: LOGOUT_ACTION,
    }),
    ...mapActions("information", {
      actionsGetAllInfo: "getAllInfo",
    }),
    async getAllInfo() {
      await this.actionsGetAllInfo();
    },
    onLogout() {
      localStorage.removeItem("storedPrivateReportID");
      localStorage.removeItem("storedPublicReportID");

      this.logout();
      location.reload();
    },

  },
};
</script>
<style scoped>
#admin-panel > a.router-link-active {
  background-color: #ff2e47;
}
#admin-panel > a.router-link-exact-active {
  background-color: #ff2e47;
}
.nav {
  display: flex;
  height: 64px;
  /* optional; just for demo */
  background: var(--mainBlue);
  position: sticky;
  top: 0;
  z-index: 100;
}

.links {
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.links a {
  margin: 0 5px;
  text-decoration: none;
}

.header-title {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logout {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1rem;
}

a {
  text-decoration: none;
}
.nav-item.active > .nav-link,
.nav-item.show > .nav-link {
  color: white;
  background-color: var(--mainYellow);
}
.nav-item.active > .nav-link:hover,
.nav-item.show > .nav-link:hover {
  color: white;
  background-color: var(--mainYellow);
}
@media all and (max-width: 1020px) {
  .nav-items a {
    font-size: 0.75rem;
  }
}
</style>
