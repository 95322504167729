import { jwtDecrypt, tokenAlive, tokenRefreshAlive } from "../../shared/jwtHelper";
import axios from "axios";
import { API_URL } from '../../.env';
import {
  LOGOUT_ACTION,AUTH_ERROR, SET_USER_TOKEN_DATA_MUTATION, GET_USER_TOKEN_GETTER,IS_USER_AUTHENTICATE_GETTER, GET_USER_ROLE} from '../actions/auth';

const state = () => ({
  authData: {
    access: null,
    refresh: null,
    tokenExp: "",
    tokenExpRefresh:"",
    role:"",
    userId: "",
  },
  loginStatus: "",
});

const getters = {
  getLoginStatus(state) {
    return state.loginStatus;
  },
  getAuthData(state) {
    return state.authData;
  },
  isTokenActive(state) {
    if (!state.authData.tokenExp) {
      return false;
    }
    return tokenAlive(state.authData.tokenExp);
  },
  isRefreshTokenActive(state) {
    if (!state.authData.tokenExpRefresh) {
      return false;
    }
    return tokenRefreshAlive(state.authData.tokenExpRefresh);
  },
  isUserAdminGetter(state){
    if(state.authData.role==="admin"){
      return true;
     
    } 

  },
  
  getUserId(state){
    return state.authData.userId;
  },
  

[GET_USER_TOKEN_GETTER]: (state) => {
   
    return state.authData.access;
},
[AUTH_ERROR]: (state) => {
   
  return state.authData.error;
},

[IS_USER_AUTHENTICATE_GETTER](state) {
 
    return !!state.authData.access;
},
[GET_USER_ROLE]:(state)=> {
  return state.authData.role;
 
},

};




const actions = {




  async login({ commit }, payload) {
    const response = await axios
      .post(`${API_URL}/api/profile/token/`, payload)
              // eslint-disable-next-line no-unused-vars
      .catch((err) => {
        commit('AUTH_ERROR',err)
        localStorage.removeItem('access')
        localStorage.removeItem('refresh')
        
       
      });
    if (response && response.data) {
      commit("saveTokenData", response.data);
      commit("setLoginStatu", "success");
    } else {
      commit("setLoginStatu", "failed");
    }
  },


  [LOGOUT_ACTION](context) {
    context.commit(SET_USER_TOKEN_DATA_MUTATION, {
        
      access: null,
        refresh: null,
        tokenExp:null,
        userId: null,
        role:null
    });
    localStorage.removeItem('refresh');
    localStorage.removeItem('access');
   
    delete axios.defaults.headers.common['Authorization'];
  
   
  
  },
}



const mutations = {

  [AUTH_ERROR]: state => {
    state.status = "error";
    state.hasLoadedOnce = true;
  },
  saveTokenData(state, data) {
    

    localStorage.setItem('access', data.access);
    localStorage.setItem('refresh', data.refresh);
   

    const jwtDecodedValue = jwtDecrypt(data.access);
   
     const jwtDecodedValueRefresh=jwtDecrypt(data.refresh);
   
  
    
    const newTokenData = {
      //what token returns?
      access: data.access,
      refresh: data.refresh,
      tokenExpRefresh:jwtDecodedValueRefresh.exp,
      tokenExp: jwtDecodedValue.exp,
      userId: jwtDecodedValue.user_id,
      role:jwtDecodedValue.role
      
    };
    
    
   

    state.authData = newTokenData;
    
  },
  setLoginStatu(state, value) {
    state.loginStatus = value;
  },
  

  
 
  [SET_USER_TOKEN_DATA_MUTATION](state, payload) {
   
    state.access = payload.access;
    state.tokenExp = payload.tokenExp;
    state.refresh = payload.refresh;
    state.userId = payload.user_id;
    state.role=payload.role;
  
}
  
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

