<template>
  <div class="margin-bottom">
    <div class="modalInformation" @click.stop="close" tabindex="-1">
      <div
        ref="content"
        class="modalDivInformation"
        style="border-bottom: 2px solid var(--mainBlue)"
      >
        <slot />
        <h1 class="modal__title" style="text-align: left; margin-left: 1rem">
          Edit current company information
        </h1>
        <form>
          <div class="row margin-bottom">
            <div class="col-sm-3">
              <label for="postGroupName">Company Name</label>
            </div>
            <div class="col-sm-8">
              <input
                id="postGroupName"
                v-model="companyName"
                type="text"
                class="form-control modalOverride"
              />
            </div>
          </div>
          <div class="container-fluid lineFooter">
            <div class="row margin-bottom">
              <div class="col-sm-3">
                <label for="postGroupName">Company description</label>
              </div>
              <div class="col-sm-8">
                <textarea
                  id="postDescriptionName"
                  v-model="companyDescription"
                  class="form-control modalOverride"
                ></textarea>
              </div>
            </div>
            <div class="row margin-bottom">
              <div class="col-sm-3">
                <label for="postGroupName">Contact Name</label>
              </div>
              <div class="col-sm-8">
                <input
                  id="postGroupName"
                  v-model="contactName"
                  type="text"
                  class="form-control modalOverride"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <label for="postGroupName">Contact Phone Number</label>
              </div>
              <div class="col-sm-8">
                <input
                  id="postGroupName"
                  v-model="contactPhoneNumber"
                  type="text"
                  class="form-control modalOverride"
                />
              </div>
            </div>
          </div>
          <a @click.stop="emitToParent('Close')" class="link-2"></a>
          <div
            style="
                  display: flex;
                  margin: 0.5rem;
                  flex-direction: row;
                  align-items: stretch;
                  align-content: stretch;
                "
          >
            <div
              style="
                    display: flex;
                    flex-direction: column;
                    flex: 2 0 0;
                    justify-content: left;
                  "
            >
              <div style="display: flex; justify-content: flex-end">
                <div style="align-self: center">
                  <button
                    class="btn btn-regular btnTabel"
                    @click.stop="emitToParent('Save')"
                  >
                    Edit
                  </button>
                  <button
                    class="btn btn-regular btnTabel"
                    style="margin-left: 0.5rem"
                    @click.stop="emitToParent('Close')"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "postCompanyName",
    "postCompanyDescription",
    "postContactName",
    "postContactPhoneNumber",
  ],
  data: function() {
    return {
      companyName: this.postCompanyName,
      companyDescription: this.postCompanyDescription,
      contactName: this.postContactName,
      contactPhoneNumber: this.postContactPhoneNumber,
      listener: (e) => {
        e.key === "Escape" && this.emitToParent("Close");
      },
      childEvent: "keydown",
      childEventForEnter: "keydown",
      listenerForEnter: (e) => {
        e.ctrlKey && e.keyCode == 13 && this.emitToParent("Save");
      },
    };
  },

  mounted() {
    window.addEventListener(this.childEvent, this.listener);
    window.addEventListener(this.childEventForEnter, this.listenerForEnter);
  },
  methods: {
    //Reconsider calling API function here instead in parent
    //because we need two way communication now

    emitToParent: function(modalState) {
      var result = {};
      result.postCompanyName = this.companyName;
      result.postCompanyDescription = this.companyDescription;
      result.postContactName = this.contactName;
      result.postContactPhoneNumber = this.contactPhoneNumber;

      result.state = modalState;

      this.$emit("modalState", result);
    },
  },
  unmounted() {
    window.removeEventListener(this.childEvent, this.listener);
    window.removeEventListener(this.childEventForEnter, this.listenerForEnter);
  },
};
</script>

<style></style>
