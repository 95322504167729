<template style="overflow-y:hidden">
  <main
    id="report-query"
    v-if="gettersAuthData === true"
    v-on:keydown.esc="close"
  >
    <Toast v-if="greskaResponse || greskaResponse.length" />

    <section class="overrideBlock">
      <div class="row">
        <div class="col-6">
          <h2 class="marginBottomMedia" style="font-size:16px;margin:0;">
            Report Information
          </h2>
          <form style="width:98%;height:100%;">
            <div class="marginBottomForReportQuery">
              <label
                for="inputReportName"
                style="line-height:unset;margin-bottom:0;"
                >Report Name</label
              >
              <input
                type="text"
                class="form-control inputOverrideQuery marginBetween"
                id="inputReportName"
                placeholder="Report Name"
                v-model="reportName"
              />
            </div>

            <div class="marginBottomForReportQuery">
              <label
                for="reportDescription"
                style="line-height:unset;margin-bottom:0;"
                >Report description</label
              >
              <textarea
                class="
                form-control
                textAreaDescription
                reportDescriptionCheck
                marginBetween
              "
                id="reportDescription"
                placeholder="Report Description"
                v-model="reportDescription"
                style="line-height:unset;"
              ></textarea>
            </div>
            <div class="marginBottomForReportQuery">
              <label style="line-height:unset;margin-bottom:0" for="inputSchema"
                >Schema</label
              >

              <select
                id="inputSchema"
                class="inputOverrideQuery marginBetween"
                v-model="schemaSelected"
              >
                <option
                  v-for="t in gettersUserPermissions"
                  :key="t.schema.id"
                  :value="t.schema.id"
                  :selected="schemaSelected === t.schema.id"
                >
                  ({{ t.schema.dataset.name }}) {{ t.schema.name }}
                </option>
              </select>
            </div>
            <div class="marginBottomForReportQuery">
              <label style="line-height:unset;margin-bottom:0" for="inputReport"
                >Report group</label
              >
              <div
                style="
              display: flex;

              flex-direction: row;
              align-items: stretch;
              align-content: stretch;
            "
              >
                <div
                  style="
                display: flex;

                justify-content: space-between;
              "
                >
                  <select
                    id="inputReport"
                    class="inputOverrideWeight inputOverrideQuery marginBetween"
                    v-model="reportGroup"
                  >
                    <option
                      v-for="group in groupResponse"
                      :key="group.id"
                      :value="group.id"
                      :selected="reportGroup === group.id"
                    >
                      {{ group.name }}
                    </option>
                  </select>
                  <button
                    title="Add new group"
                    class="btn btn-regular btn-small marginButtonModal tooltip"
                    v-on:click.prevent="modal1 = !modal1"
                  >
                    <i
                      class="ikonicaPlus pi pi-plus-circle"
                      style="margin-bottom: 0.5rem"
                    ></i>

                    <span class="tooltiptext">Add new group</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="marginBottomForReportQuery">
              <div
                class="modalInformation"
                v-if="modal1"
                to="#modals"
                @click.stop="close"
              >
                <div
                  ref="content"
                  class="modalDivInformation"
                  style="border-bottom: 2px solid var(--mainBlue)"
                >
                  <slot />
                  <h1
                    class="modal__title"
                    style="text-align: left; margin-left: 1rem"
                  >
                    Add new report group
                  </h1>
                  <form>
                    <div class="container-fluid lineFooter">
                      <div class="row marginBottomForReportQuery">
                        <div class="col-sm-3">
                          <label for="postGroupName">Name</label>
                        </div>
                        <div class="col-sm-8">
                          <input
                            id="postGroupName"
                            v-model="postGroupName"
                            type="text"
                            class="form-control modalOverride"
                            style="padding:0"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-3">
                          <label for="postGroupName">Description</label>
                        </div>
                        <div class="col-sm-8">
                          <textarea
                            id="postDescriptionName"
                            v-model="postDescriptionName"
                            class="form-control modalOverride"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <a @click.stop="modal1 = !modal1" class="link-2"></a>
                    <div
                      style="
                      display: flex;
                      margin: 0.5rem;
                      flex-direction: row;
                      align-items: stretch;
                      align-content: stretch;
                    "
                    >
                      <div
                        style="
                        display: flex;
                        flex-direction: column;
                        flex: 2 0 0;
                        justify-content: left;
                      "
                      >
                        <div style="display: flex; justify-content: flex-end">
                          <div style="align-self: center">
                            <button
                              class="btn btn-regular btnTabel"
                              v-on:click="addNewReportGroup()"
                              @click="modal1 = !modal1"
                            >
                              Add
                            </button>
                            <button
                              class="btn btn-regular btnTabel"
                              style="margin-left: 0.5rem"
                              @click.stop="modal1 = !modal1"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div class="marginBottomForReportQuery float-right">
              <label
                style="line-height:unset;margin-bottom:0"
                for="InputReportType"
                >Report type</label
              >

              <select
                class="inputOverride inputOverrideQuery marginBetween"
                id="InputReportType"
                v-model="reportType"
              >
                <option selected disabled hidden>Ucitavanje ...</option>

                <option value="0">Public</option>
                <option value="1">Private</option>
              </select>
            </div>
            <div class="marginBottomForReportQuery">
              <label style="line-height:unset;margin-bottom:0" for="inputQuery"
                >Query type</label
              >

              <select
                disabled
                class="inputOverride inputOverrideQuery marginBetween"
                id="inputReportQueryType"
                v-model="queryType"
              >
                <option selected disabled hidden>Ucitavanje ...</option>

                <option value="1">Builder</option>
                <option value="0">SQL</option>
              </select>
            </div>

            <div>
              <label style="line-height:unset;margin-bottom:0" for="inputUser"
                >User</label
              >

              <select
                id="inputUser"
                class="inputOverrideQuery marginBetween"
                v-model="user"
                @change="onUserChangeGetReportGroups()"
              >
                <option
                  v-for="t in ownedUsers"
                  :key="t.id"
                  :value="t.id"
                  :selected="user === t.id"
                >
                  {{ t.label }}
                </option>
              </select>
            </div>

            <teleport v-if="modalSave" to="#modals">
              <div class="modalSave" @click.stop="close">
                <div class="modalSaveDiv" ref="content">
                  <div
                    class="modal__detail"
                    style="border-bottom: 2px solid var(--mainBlue)"
                  >
                    <h1 class="modal__title">Save changes</h1>
                    <p class="modal__descriptioned">
                      How would you like to save your report?
                    </p>
                  </div>
                  <div class="margin-bottom">
                    <div
                      style="
                    display: flex;
                    margin: 0.5rem;
                    flex-direction: row;
                    align-items: stretch;
                    align-content: stretch;
                  "
                    >
                      <div
                        style="
                      display: flex;
                      flex-direction: column;
                      flex: 2 0 0;
                      justify-content: left;
                    "
                      >
                        <div style="display: flex; justify-content: right">
                          <div style="align-self: center">
                            <button
                              class="btn btn-regular"
                              style="
                            margin-right: 1rem;
                            margin-left: 0.5rem;
                            width: 15rem;
                          "
                              v-on:click.prevent="editNewReport"
                              @click.prevent="modalSave = !modalSave"
                            >
                              Save changes
                            </button>
                            <button
                              class="btn btn-regular"
                              v-on:click.prevent="addNewReport"
                              @click.prevent="modalSave = !modalSave"
                              style="margin-right:1rem"
                            >
                              Save as new report
                            </button>
                          </div>
                        </div>
                      </div>

                      <div style="display: flex; justify-content: flex-end">
                        <div style="align-self: center">
                          <button
                            class="btn btn-regular btnTabel"
                            v-on:click.stop="modalSave = !modalSave"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>

                    <a @click.stop="modalSave = !modalSave" class="link-2"></a>
                  </div>
                </div>
              </div>
            </teleport>
          </form>
        </div>

        <div class="col-6">
          <h4
            class="marginBottomMediaRight"
            style="font-size:16px;line-height:unset;margin:0"
          >
            Query editor
          </h4>
          <div style="margin-bottom:3.5vh">
            <!-- TODO: UI for sql code -->
            <label style="line-height:unset;margin-bottom:0">SQL code</label>
            <textarea
              name="text"
              class="form-control textareaChecked"
              id="inputSqlCode"
              placeholder="Sql code"
              v-model="sqlObj.sql"
              style="line-height:unset;"
            ></textarea>
          </div>

          <div
            style="
              display: flex;
              margin-right: 0.5rem;
              flex-direction: row;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: column;
                flex: 2 0 0;
                justify-content: left;
              "
            >
              <div style="display: flex; justify-content: flex-end">
                <div style="align-self: flex-end">
                  <input
                    type="radio"
                    id="includeDb"
                    name="includeDb"
                    value="0"
                    v-model="sqlObj.db"
                    style="display: inline; margin-left: 1rem;vertical-align: middle"
                    class="hoverCheckbox"
                  />
                  <label
                    for="includeDb"
                    style="font-size:14px!important;margin-right:1rem;"
                    >DB - dw</label
                  >

                  <input
                    type="radio"
                    id="includeDW"
                    name="includeDW"
                    value="1"
                    v-model="sqlObj.db"
                    style="display: inline; margin-left: 1rem;vertical-align: middle"
                    class="hoverCheckbox"
                  />
                  <label for="includeDW" style="font-size:14px!important"
                    >DB - analytic</label
                  >
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              style="
              display: flex;
              margin-right: 0.5rem;
              flex-direction: row;
            "
            >
              <div>
                <div>
                  <div>
                    <div
                      style="
                  display: flex;
                  flex-direction: column-reverse;
              
                "
                    >
                      <div style="display: flex; justify-content: flex-end">
                        <div style="align-self: flex-end">
                          <button
                            v-if="
                              this.$route.name === 'reportquery' &&
                                !this.isReportQueryResaved
                            "
                            class="btn btn-regular btnTabel"
                            v-on:click.prevent="addNewReport"
                            style="line-height:unset;padding:5px;height:40px"
                          >
                            <i i class="pi pi-save"></i>Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style="
                display: flex;

                flex-direction: row;
              "
                >
                  <div
                    style="
                  display: flex;
                  flex-direction: column;
                  flex: 2 0 0;
                  justify-content: left;
                "
                  >
                    <div style="display: flex; justify-content: flex-end">
                      <div style="align-self: flex-end;">
                        <button
                          v-if="
                            this.$route.name === 'reportqueryid' ||
                              (this.$route.name === 'reportquery' &&
                                this.isReportQueryResaved)
                          "
                          class="btn btn-regular btnTabel"
                          v-on:click.prevent="modalSave = !modalSave"
                          style="line-height:unset;height:40px;padding:5px;"
                        >
                          <i i class="pi pi-save"></i>
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style="
                display: flex;
                flex-direction: column;
                flex: 2 0 0;
                justify-content: left;
              "
              >
                <div style="display: flex; justify-content: flex-end">
                  <div style="align-self: flex-end">
                    <button
                      @click.prevent="testPostSql()"
                      class="btn btn-regular btnTabel marginTopForButton"
                      style="line-height:unset;height:40px;padding:5px;"
                    >
                      Test
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <span>
      <div class="modalDownload" v-if="loading">
        <div class="modalDownloadDiv">
          <ProgressBar mode="indeterminate" />
        </div>
      </div>
      <teleport v-if="modalSqlTest" to="#modals">
        <div class="modalSqlTest" @click.stop="close">
          <div class="modalSqlTestDiv" ref="content">
            <!-- scrollable or paginator? -->
            <DataTable
              :value="result.result"
              :rowHover="true"
              :scrollable="true"
              scrollHeight="600px"
              removableSort
              style="border-bottom: 2px solid var(--mainBlue)"
            >
              <Column
                v-for="col of koloneTabele"
                :field="col.field"
                :header="col.header"
                :key="col.field"
                :sortable="true"
                style="min-width: 10rem"
              ></Column>
            </DataTable>
            <div
              style="
                display: flex;

                flex-direction: row;
                align-items: stretch;
                align-content: stretch;
              "
            >
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  flex: 2 0 0;
                  justify-content: space-between;
                "
              >
                <div style="display: flex; justify-content: space-between">
                  <div style="align-self: center">
                    <p style="margin-left: 1rem; font-weight: bold">
                      Report row count: {{ result.result_count }}
                    </p>
                  </div>

                  <button
                    class="btn btn-regular"
                    style="align-self: center; width: 8rem; margin-right: 1rem"
                    v-on:click="koloneTabele = !koloneTabele"
                    @click.stop="modalSqlTest = !modalSqlTest"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </teleport>
    </span>
  </main>

  <main v-else>
    <error-page></error-page>
  </main>
</template>

<script>
//TODO: post request PK returns, should input be by get?
import jwtInterceptor from "../shared/jwtInterceptor";
import { API_URL } from "../../src/.env.js";
import emitter from '@/mitt';
import { mapGetters, mapActions } from "vuex";
import ErrorPage from "../components/ErrorPage.vue";
export default {
  name: "ReportInformation",

  components: { ErrorPage },
  data() {
    return {
      reports: [],
      reportName: "",
      reportDescription: "",
      schemaSelected: 1,
      group: 1,
      reportGroup: 1,
      reportGroupName: "",
      reportType: 0,
      queryType: 0,
      user: 1,
      userEmail: "",
      sqlCode: "",
      groupResponse: "",
      groupResponseID: 0,
      groupResponseName: "",
      modal1: false,
      postGroupName: "",
      postDescriptionName: "",
      prosledjeniId: this.$route.params.id,
      modalSave: false,
      sqlCodeStringifyNew: "",
      sqlObj: { sql: "", db: 0 },
      greskaResponse: [],
      ownedUsers: "",
      userBy: this.getUserIdFromToken,
      modalSqlTest: false,
      result: [],
      koloneTabele: null,
      loading: false,
      isReportQueryResaved: false,
    };
  },
  created() {
    if (this.$route.name === "reportqueryid") {
      this.getReports();
    }

    this.getReportGroup();
    this.getUserPermissions();
    this.getOwnedUsers();
  },
  mounted(){
    emitter.on("linkClicked", this.handleClick);

  },
  computed: {
    ...mapGetters("auth", {
      gettersAuthData: "isUserAdminGetter",
    }),
    ...mapGetters("auth", {
      getUserIdFromToken: "getUserId",
    }),
    ...mapGetters("permissions", {
      gettersUserPermissions: "getUserPermissions",
    }),
  },

  methods: {
    handleClick(){
      this.$router.push('/reportquery')
    },
    close(e) {
      if (this.$refs?.content?.contains(e.target)) return;

      this.$emit("close");
      this.modal1 = false;
      this.modalSave = false;
      this.modalSqlTest = false;

      if (document.activeElement.toString() == "[object HTMLButtonElement]") {
        document.activeElement.blur();
      }
    },

    ...mapActions("permissions", {
      actionsGetUserPermissions: "getUserPermissions",
    }),
    async getUserPermissions() {
      await this.actionsGetUserPermissions();
    },
    getOwnedUsers() {
      jwtInterceptor
        .get(`${API_URL}/api/profile/users/`, {})
        .then((res) => {
          this.ownedUsers = res.data;
        })
        .catch(() => {
          // Manage errors if found any
        });
    },
    addNewReport() {
      this.isReportQueryResaved = true;
      jwtInterceptor
        .post(`${API_URL}/api/reports/`, {
          name: this.reportName,
          description: this.reportDescription,
          type: this.reportType,
          query_type: this.queryType,
          group: this.reportGroup,
          user: this.user,
          user_by: this.getUserIdFromToken,
          schema: this.schemaSelected,

          data: JSON.stringify(this.sqlObj),
        })

        .then((res) => {
          // clear form inputs
          // this.reportName = this.reportDescription = this.type = this.queryType = this.reportGroup = this.user = this.schemaSelected = this.sqlObj =
          //   "";
          this.prosledjeniId = res.data.id;

          this.$toast.add({
            severity: "success",
            summary: "Success Message",
            detail: "The report has been successfully saved.",
            life: 3000,
          });
          this.$router.push("/reportquery");
          if (
            document.activeElement.toString() == "[object HTMLButtonElement]"
          ) {
            document.activeElement.blur();
          }
        })
        .catch((error) => {
          // clear form inputs

          this.greskaResponse = [];
          if (
            JSON.stringify(error.response.data.name) ===
            '["This field may not be blank."]'
          ) {
            this.greskaResponse =
              "In order to Save report, you need to enter Report Name Field";
          } else {
            this.greskaResponse = error.response.data.non_field_errors[0];
          }

          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: this.greskaResponse,
            life: 4000,
          });
          this.greskaResponse = [];
          if (
            document.activeElement.toString() == "[object HTMLButtonElement]"
          ) {
            document.activeElement.blur();
          }
        });
    },
    testPostSql() {
      this.loading = true;
      if (this.sqlObj.sql != "") {
        jwtInterceptor
          .post(`${API_URL}/api/reports/test/`, {
            schema_id: this.schemaSelected,
            query_type: 0,
            data: JSON.stringify(this.sqlObj),
          })
          .then((response) => {
            // clear form inputs

            this.modalSqlTest = true;

            this.result = response.data;
            this.koloneTabele = []; //Resetujemo kolone na prazno da se ne bi prikazivale prilikom otvaranja drugog reporta
            //this.result.result=[];
            this.greskaResponse = [];
            //var kolone=this.result.result[0]; //Prikupljamo koje sve kolone

            for (var key in this.result.result[0]) {
              //Iz rezultata izvlacimo moguce kolone, pravimo objekat koji punimo imenom kolone i dodajemo ga u listu kolona tabele. Ponavljamo dok ima novih kolona s`API-ja.

              var jednaKolona = { field: key, header: key };

              this.koloneTabele.push(jednaKolona);
            }
          })
          .catch((error) => {
            // clear form inputs

            var greskaZaTabelu = "";
            if (
              JSON.stringify(error.response.data.error) ===
              '["FROM expression, such as a Table or alias() object expected, got None."]'
            ) {
              greskaZaTabelu =
                "SQL code is currently empty. Please enter SQL code to continue.";
            } else {
              greskaZaTabelu = error.response.data.error;
            }

            this.$toast.add({
              severity: "info",
              summary: "Error Message",
              detail: greskaZaTabelu,
              life: 4000,
            });
            if (
              document.activeElement.toString() == "[object HTMLButtonElement]"
            ) {
              document.activeElement.blur();
            }
          })
          .finally(() => (this.loading = false));
        this.greskaResponse = [];
        this.koloneTabele = [];
      } else {
        this.loading = false;
        this.$toast.add({
          severity: "info",
          summary: "Error Message",
          detail: "Query editor is empty. Please enter SQL Code to proceed",
          life: 4000,
        });
        if (document.activeElement.toString() == "[object HTMLButtonElement]") {
          document.activeElement.blur();
        }
      }
    },
    editNewReport() {
      this.isReportQueryResaved = true;
      //this.prosledjeniId moram da preuzmem kad ga napravi tamo

      jwtInterceptor
        .patch(`${API_URL}/api/reports/${this.prosledjeniId}/`, {
          name: this.reportName,
          description: this.reportDescription,
          type: this.reportType,
          query_type: this.queryType,
          group: this.reportGroup,
          user: this.user,
          schema: this.schemaSelected,
          user_by: this.getUserIdFromToken,
          data: JSON.stringify(this.sqlObj),
        })
        .then(() => {
          // clear form inputs
          // this.reportName = this.reportDescription = this.type = this.queryType = this.reportGroup = this.user = this.schemaSelected = this.sqlObj =
          //   "";

          this.$toast.add({
            severity: "success",
            summary: "Success Message",
            detail: "The report has been successfully edited.",
            life: 3000,
          });
          this.$router.push("/reportquery");
        })
        .catch((error) => {
          var patchGreska = error.response.data.non_field_errors[0];

          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: patchGreska,
            life: 4000,
          });
        });
    },
    getReports() {
      jwtInterceptor
        .get(`${API_URL}/api/reports/` + this.$route.params.id)
        .then((res) => {
          this.reports = res.data;

          this.reportName = this.reports.name;
          this.reportDescription = this.reports.description;
          this.schemaSelected = this.reports.schema.id;

          this.reportGroup = this.reports.group.id;
          this.reportGroupName = this.reports.group.name;
          this.reportType = this.reports.type;
          this.user = this.reports.user.id;
          this.userEmail = this.reports.user.email;
          this.sqlCode = this.reports.data;
          this.queryType = this.reports.query_type;

          this.sqlObj = JSON.parse(this.sqlCode);

          let exists = "db" in this.sqlObj;
          if (exists) {
            //neka ostane koji ima podrazumevan
          } else {
            //ako nema podrazumevan neka bude nula
            this.sqlObj.db = 0;
          }

          // this.sqlObj.db=0;
        })
        .catch(() => {});
    },
    getReportGroup() {
      jwtInterceptor
        .get(`${API_URL}/api/groups/?user_id=${this.user}`)
        .then((res) => {
          this.groupResponse = res.data;
          this.groupResponseID = this.groupResponse.id;
          this.groupResponseName = this.groupResponse.name;
        })
        .catch(() => {});
    },
    onUserChangeGetReportGroups() {
      jwtInterceptor
        .get(`${API_URL}/api/groups/?user_id=${this.user}`)
        .then((res) => {
          this.groupResponse = res.data;
          this.reportGroup = res.data[0].id;
        })
        .catch(() => {});
    },

    async addNewReportGroup() {
      await jwtInterceptor
        .post(
          `${API_URL}/api/groups/`,

          {
            name: this.postGroupName,
            description: this.postDescriptionName,
            user: this.user,
          }
        )
        .then((response) => {
          this.postGroupName = "";
          this.postDescriptionName = "";
          this.reportGroup = response.data.id;
          this.$toast.add({
            severity: "success",
            summary: "Success Message",
            detail: "New report group has been successfully added.",
            life: 3000,
          });
          this.getReportGroup();
        })
        .catch((error) => {
          this.greskaResponse = error.response.data.error;
          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: this.greskaResponse,
            life: 4000,
          });
        });
    },
    beforeUnmount(){
      emitter.off("linkClicked", this.handleClick);

    }
  },
};
</script>
<style>
.marginBottomForReportQuery {
  margin-bottom: 3vh;
}
@media all and (max-width: 1950px) {
  .textareaChecked {
    height: 63vh !important;
  }
}

.inputOverrideQuery {
  height: 4vh;
  min-height: 30px;
}
.inputOverrideQueryBuilder {
  height: 4vh;
}
/* @media all and (max-width: 1910px) {
  
  .marginBottomMedia {
    margin-bottom: 1rem !important;
  }
  .marginBottomMediaRight {
    margin-bottom: 1rem !important;
  }
  .btnMarginTop {
    margin-top: 10rem;
  }
}
@media all and (max-width: 1599px) {
  .inputOverrideQuery {
    height: 4rem !important;
    font-size: 15px !important;
    margin-bottom: 1rem !important;
  }
  label {
    font-size: 1.25rem !important;
  }
  textarea {
    font-size: 1.5rem !important;
  }
.inputOverride {
  width:30vh!important;
}
  .marginBottomMedia {
    margin-bottom: 4rem;
  }
  .marginTopForButton {
    margin-top: 4.25rem !important;
  }
  .marginBottomMediaRight {
    margin-bottom: 5rem;
  }
  .reportDescriptionCheck {
    height: 9rem !important;
  }
 
  .marginBetween {
    margin-bottom: 2.8rem !important;
  }
}
@media all and (max-width: 1296px) { 
   .marginBetween {
    margin-bottom: 0 !important;
  }
 } */
label {
  font-size: 12px !important;
}
.inputOverride {
  width: 30rem;
}
.inputOverrideWeight {
  width: 46rem;
}
.overrideBlock {
  padding: 8px;
  background: white;
  box-shadow: -1px -1px 3px rgba(240, 243, 255, 0.7),
    1px 1px 3px rgba(200, 207, 232, 0.7);
  border-radius: 3px;
  height: 86vh;
}
.reportDescriptionCheck {
  height: 8vh;
}
.modalInformation {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place front is invalid - may break your css so removed */
  padding-top: 100px; /* Location of the box - don't know what this does?  If it is to move your modal down by 100px, then just change top below to 100px and remove this*/
  left: 0;
  right: 0; /* Full width (left and right 0) */
  top: 0;
  bottom: 0; /* Full height top and bottom 0 */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  z-index: 9999; /* Sit on top - higher than any other z-index in your site*/
  text-align: center;
}
.textAreaDescription {
  min-height: auto !important;
}
.modalDivInformation {
  background-color: white;
  width: 50%;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 1rem;

  transform: translate(-50%, -50%);
}

.modalSave {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place front is invalid - may break your css so removed */
  padding-top: 100px; /* Location of the box - don't know what this does?  If it is to move your modal down by 100px, then just change top below to 100px and remove this*/
  left: 0;
  right: 0; /* Full width (left and right 0) */
  top: 0;
  bottom: 0; /* Full height top and bottom 0 */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  z-index: 9999; /* Sit on top - higher than any other z-index in your site*/
  text-align: center;
}
.modalSaveDiv {
  background-color: white;
  width: 50%;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 1rem;
  margin-bottom: 2rem;

  transform: translate(-50%, -50%);
}
.textareaChecked {
  height: 63vh;
  overflow-y: scroll;
}
.modal__detail {
  text-align: center;
  padding-bottom: 2rem;
}

.modal__title {
  font-size: 2rem;
  text-align: left;
  margin-left: 1rem;
}

.modal__descriptioned {
  margin-top: 2rem;

  font-size: 1.6rem;
  font-style: italic;
}

.modal__text {
  padding: 0 4rem;

  font-size: 1.6rem;
  line-height: 2;
}
a,
a:link {
  font-family: inherit;
  text-decoration: none;
  cursor: pointer;
}
.modal__text::before {
  content: "";

  position: absolute;
  top: 0%;
  left: 100%;
  transform: translate(-50%, -50%);

  width: 18rem;
  height: 18rem;
  border: 1px solid hsla(0, 0%, 100%, 0.2);
  border-radius: 100rem;

  pointer-events: none;
}
.link-2 {
  width: 5rem;
  height: 5rem;

  border-radius: 100rem;

  color: inherit;
  font-size: 2.2rem;

  position: absolute;
  top: 0rem;
  right: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: 0.2s;
  color: var(--mainBlue);
}

.link-2::before {
  content: "×";
}
a:hover {
  text-decoration: none;
  color: var(--mainYellow);
}
.link-2:hover,
.link-2:focus {
  border-color: white;
  text-decoration: none;
  color: var(--mainBlue);
}
.tooltip {
  position: relative;
  display: inline-block;
  width: 3rem !important;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: var(--mainBlue);
  color: #fff;
  text-align: center;
  font-size: 1rem;
  padding: 10px 5px;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: relative;
  left: 1rem;
  top: -5px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent var(--mainBlue) transparent transparent;
}
.modalSqlTest {
  display: inherit; /* Hidden by default */
  position: fixed; /* Stay in place front is invalid - may break your css so removed */
  padding-top: 100px; /* Location of the box - don't know what this does?  If it is to move your modal down by 100px, then just change top below to 100px and remove this*/
  left: 0;
  right: 0; /* Full width (left and right 0) */
  top: 0;
  bottom: 0; /* Full height top and bottom 0 */
  overflow: auto; /* Enable scroll if needed */

  background-color: rgba(0, 0, 0, 0.3);

  z-index: 9999; /* Sit on top - higher than any other z-index in your site*/
  text-align: center;
}
.modalSqlTestDiv {
  background-color: white;
  width: 90%;
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);
}

.modalSqlTestDiv div::-webkit-scrollbar {
  width: 1em;
  height: 1em;
}

.modalSqlTestDiv div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.modalSqlTestDiv div::-webkit-scrollbar-thumb {
  background-color: var(--lighterPurple);
  outline: 1px solid var(--Purple);
}
.p-datatable .p-datatable-thead > tr > th {
  font-size: 1.4rem !important;
  font-weight: normal !important;
}
.btnModal {
  width: 4.5rem !important;
}
.btnTabel {
  width: 8rem !important;
}
.lineFooter {
  border-bottom: 2px solid var(--mainBlue);
  padding-bottom: 2rem;
}
.marginButtonModal {
  margin-top: 0.4rem;
  margin-left: 2.5rem;
}
.ikonicaPlus {
  position: relative !important;
  left: -4% !important;
  bottom: 9% !important;
}
#report-query {
  font-size: 15px !important;
  padding-top: 3.5vh;
  padding-bottom: 0;
  gap: unset;
  overflow-y: hidden;
}
</style>
