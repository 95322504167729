<template>
  <div class="errorDiv">
    <div class="block">
      <div class="errorDivI">
        <i class="pi pi-cog"></i>
      </div>
      <div style="text-align: center">
        <h2>You don't have access to view this page</h2>
        <h2>Check your admin status and try again</h2>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  setup() {},
};
</script>
<style scoped>
.errorDiv {
  position: absolute;
  width: 300px;
  height: 200px;
  z-index: 15;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -150px;
  background: var(--mainBlue);
}
.errorDivI {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
i {
  font-size: 3rem;
  color: var(--mainBlue);
}
</style>