<template>
  <Toast />

  <form @submit.prevent="changePassword">
    <div class="input-group margin-bottom">
      <input
        class="labelOverride"
        v-bind:type="[showPassword ? 'text' : 'password']"
        placeholder="Current password"
        name="password"
        v-model="password"
      />
      <div class="input-group-append">
        <span class="input-group-text" @click="showPassword = !showPassword"
          ><i
            :class="[showPassword ? 'pi pi-eye' : 'pi pi-eye-slash']"
            aria-hidden="true"
            style="color:white"
          ></i
        ></span>
      </div>
    </div>

    <div class="input-group margin-bottom">
      <input
        class="labelOverride"
        v-bind:type="[showPassword ? 'text' : 'password']"
        placeholder="New password"
        name="newPassword"
        v-model="newPassword"
      />
      <div class="input-group-append">
        <span class="input-group-text" @click="showPassword = !showPassword"
          ><i
            :class="[showPassword ? 'pi pi-eye' : 'pi pi-eye-slash']"
            aria-hidden="true"
            style="color:white"
          ></i
        ></span>
      </div>
    </div>

    <div class="input-group margin-bottom">
      <input
        class="labelOverride"
        placeholder="Confirm new password"
        v-bind:type="[showPassword ? 'text' : 'password']"
        name="confirmPassword"
        v-model="confirmPassword"
      />
      <div class="input-group-append">
        <span class="input-group-text" @click="showPassword = !showPassword"
          ><i
            :class="[showPassword ? 'pi pi-eye' : 'pi pi-eye-slash']"
            aria-hidden="true"
            style="color:white"
          ></i
        ></span>
      </div>
    </div>

    <button
      class="btn btn-regular"
      style="float:right;margin-right:2rem;margin-top:2rem;"
      type="submit"
    >
      Update password
    </button>
  </form>
</template>
<script>
import moment from "moment";
import jwtInterceptor from "../shared/jwtInterceptor";

import { API_URL } from "../../src/.env.js";

export default {
  name: "ChangePassword",
  emits: ["modalState"],
  components: {},
  data() {
    return {
      password: "",
      newPassword: "",
      confirmPassword: "",
      modal1: false,
      showPassword: false,
    };
  },
  created() {
    this.moment = moment;
  },
  computed: {},
  beforeRouteEnter(to, from, next) {
    const token = localStorage.getItem("access");

    return token ? next() : next("/login");
  },
  methods: {
    emitToParent: function(modalState) {
      var result = {};
      result.state = modalState;
      this.$emit("modalState", result);
    },
    changePassword() {
      jwtInterceptor
        .post(`${API_URL}/api/profile/change-password/`, {
          old_password: this.password,
          new_password: this.newPassword,
        })
        .then(() => {
          // clear form inputs

          this.password = this.newPassword = this.confirmPassword = "";

          this.$toast.add({
            severity: "success",
            summary: "Success Message",
            detail: "Your password is successfully changed",
            life: 3000,
          });
          if (
            document.activeElement.toString() == "[object HTMLButtonElement]"
          ) {
            document.activeElement.blur();
          }
          this.emitToParent("Close");
          // display success notification
        })
        .catch((error) => {
          // clear form inputs
          this.password = this.newPassword = this.confirmPassword = "";
          if (
            document.activeElement.toString() == "[object HTMLButtonElement]"
          ) {
            document.activeElement.blur();
          }
          this.emitToParent("Open");
          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: error.response.data.new_password,
            life: 3000,
          });
        });
    },
  },
};
</script>
<style scoped>
form {
  width: 100%;
}
.labelOverride {
  width: 50%;
}
.input-group {
  width: 170% !important;
}
.labelOverride::-webkit-input-placeholder {
  color: var(--purple);
}
.labelOverride::-moz-placeholder {
  color: var(--purple);
}
.labelOverride:-ms-input-placeholder {
  color: var(--purple);
}
.labelOverride:-moz-placeholder {
  color: var(--purple);
}
form input {
  width: 100%;
}
input[i] {
  width: 55%;
}
.input-group-text {
  background-color: var(--mainBlue) !important;
}
</style>
