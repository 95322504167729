<template>
  <!-- progress bar koji se loaduje dok se ne dobije odgovor sa API-ja, na osnovu bool loadingProgressBar -->
  <div v-if="loadingProgressBar">
    <ProgressBar mode="indeterminate" />
  </div>
  <main id="my-reports" v-on:keydown.esc="close">
    <aside class="container-fluid">
      <div class="report-list col-sm-12">
        <label class="overrideLabel">Choose report group</label>

        <select
          class="form-control-reports margin-bottom overrideSelect"
          id="selected_id"
          v-model="oznacenaGrupa"
          @change="saveReportIdInLocalStorage($event)"
        >
          <option
            v-for="item of reports"
            :key="item._id"
            :value="item.group_id"
          >
            &#9776; {{ item.group_name }}
          </option>
        </select>
      </div>
    </aside>
    <section>
      <Toast v-if="greskaResponse || greskaResponse.length" />

      <div>
        <div v-for="item of reports" :key="item._id">
          <div class="report-group" v-if="oznacenaGrupa === item.group_id">
            <div
              class="report-block block margin-bottom"
              v-for="item of item.group_reports"
              :key="item._id"
            >
              <div class="report-heading">
                <h2>{{ item.report_name }}</h2>

                <div class="toolbar">
                  <button
                    class="btn-regular btn-small"
                    @click="openDownloadModal(item)"
                    :disabled="!item.user_can_view"
                    :class="{ disabled: !item.user_can_view }"
                  >
                    <i
                      class="pi pi-download"
                      :disabled="!item.user_can_view"
                      :class="{ disabledIcon: !item.user_can_view }"
                    ></i>
                  </button>

                  <button
                    class="btn-regular btn-small"
                    @click="openPreviewModal(item)"
                    :disabled="!item.user_can_view"
                    :class="{ disabled: !item.user_can_view }"
                  >
                    <i
                      class="pi pi-eye"
                      :disabled="!item.user_can_view"
                      :class="{ disabledIcon: !item.user_can_view }"
                    ></i>
                  </button>

                  <router-link
                    v-if="
                      item.report_query_type === 0 &&
                        gettersAuthData &&
                        item.user_can_edit
                    "
                    :to="{
                      name: 'reportqueryid',
                      params: { id: item.report_id },
                    }"
                    tag="Button"
                    ><button
                      id="btn"
                      class="btn-regular btn-small"
                      :disabled="!gettersAuthData"
                    >
                      <i class="pi pi-pencil"></i></button
                  ></router-link>

                  <router-link
                    v-if="item.report_query_type === 1 && item.user_can_edit"
                    :to="{
                      name: 'reportbuilderedit',
                      params: { id: item.schema_id },
                      query: {
                        report: item.report_id,
                        edit: item.user_can_delete,
                      },
                    }"
                    tag="Button"
                    ><button class="btn-regular btn-small">
                      <i class="pi pi-pencil"></i></button
                  ></router-link>

                  <button
                    :disabled="!item.user_can_delete"
                    class="btn-destructive btn-small"
                    :class="{ disabled: !item.user_can_delete }"
                    @click="openDeleteModal(item)"
                  >
                    <i
                      class="pi pi-trash"
                      :class="{ disabled: !item.user_can_delete }"
                    ></i>
                  </button>
                </div>
              </div>

              <div
                class="report-description margin-bottom"
                style="white-space: pre"
              >
                {{ item.report_description }}
              </div>

              <div class="report-footer">
                <div class="report-footer-section">
                  <div>
                    <span>DATASET</span><span>{{ item.dataset_name }}</span>
                  </div>
                  <div>
                    <span>SCHEMA</span><span>{{ item.schema_name }}</span>
                  </div>
                </div>

                <div class="report-footer-section">
                  <div>
                    <span>OWNED BY</span><span  :style="[!item.user_can_delete||item.user_owner!==gettersAllInfo.username  ? 'color:orange' : 'color:green']">{{ item.user_owner }}</span>
                  </div>
                  <div>
                    <span>CREATED BY</span
                    ><span>{{ item.user_created_by }}</span>
                  </div>
                  <div>
                    <span>UPDATED</span
                    ><span>{{
                      moment(item.report_updated_at).format("DD-MM-YYYY")
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <div class="modalDownload" v-if="loadingDownload">
    <div class="modalDownloadDiv">
      <ProgressBar mode="indeterminate" />
    </div>
  </div>
  <teleport v-if="modalDelete" to="#modals">
    <delete-modal
      v-on:selectedValuesState="onReportDeleted"
      v-bind:item="item"
      @close="modalDelete = false"
    />
  </teleport>
  <teleport v-if="modalDownload" to="#modals">
    <download-modal
      v-on:selectedValuesState="onReportDownloaded"
      v-bind:item="item"
      @close="modalDownload = false"
    />
  </teleport>
  <teleport v-if="modalPreview" to="#modals">
    <preview-reports-modal
      v-on:selectedValuesState="onReportPreviewed"
      v-bind:oznaceniReport="oznaceniReport"
      @close="modalPreview = false"
    />
  </teleport>
  <ul>
    <li v-for="t in this.groupReportsList" :key="t.id">
      {{ t }}
    </li>
  </ul>
</template>

<script>
import jwtInterceptor from "../shared/jwtInterceptor";
import moment from "moment";
import { API_URL } from "../../src/.env.js";
import { mapGetters, mapActions } from "vuex";
import DeleteModal from "../components/DeleteModal.vue";
import DownloadModal from "../components/DownloadModal.vue";
import PreviewReportsModal from "../components/PreviewReportsModal.vue";

export default {
  components: { DeleteModal, DownloadModal, PreviewReportsModal },
  data() {
    return {
      reports: [],
      oznacenaGrupa: JSON.parse(localStorage.getItem("storedPrivateReportID")),
      oznaceniDownload: "",
      modalPreview: false,
      modalDelete: false,
      oznaceniDelete: "",
      greskaResponse: [],
      loadingDownload: false,
      groupReportsList: [],
      loadingProgressBar: false,
      modalDownload: false,
      oznaceniReport: null,
    };
  },
  computed: {
    //Zarad drugacije prikaza na osnovu user-a, preuzima auth data i proverava da li je korisnik admin
    ...mapGetters("auth", {
      gettersAuthData: "isUserAdminGetter",
    }),
    ...mapGetters("information", {
      gettersAllInfo: "getAllInfo",
    }),
  },
  beforeMount() {},
  created() {
    //deklarisemo moment obj, da bismo ispravno formatirali datum
    this.moment = moment;
    //ciscenje sivh gresaka
    this.greskaResponse = [];
    //pozivanje API f-je koja prezuima sve report-e i smesta ih u this.reports
    this.getPrivateReportsDesc();
    this.getAllInfo();
  },

  methods: {
    ...mapActions("information", {
      actionsGetAllInfo: "getAllInfo",
    }),
    async getAllInfo() {
      await this.actionsGetAllInfo();
    },
    openDeleteModal(item) {
      this.modalDelete = true;
      this.oznaceniDelete = item.report_id;
    },
    openDownloadModal(item) {
      this.modalDownload = true;
      this.oznaceniDownload = item.report_id;
    },
    openPreviewModal(item) {
      this.modalPreview = true;
      this.oznaceniReport = item.report_id;
    },
    onReportDownloaded(value) {
      if (value.state == "Close") {
        this.modalDownload = false;
      } else if (value.state == "Yes") {
        this.downloadReports();
        this.modalDownload = false;
      }
    },
    onReportDeleted(value) {
      if (value.state == "Close") {
        this.modalDelete = false;
      } else if (value.state == "Yes") {
        this.deleteReport();
        this.modalDelete = false;
      }
    },
    onReportPreviewed(value) {
      if (value.state == "Close") {
        this.modalPreview = false;
      } else if (value.state == "Download") {
        this.oznaceniDownload = value.reportId;
        this.downloadReports();
        this.modalPreview = false;
      }
    },
    close(e) {
      if (this.$refs?.content?.contains(e.target)) return;

      this.$emit("close");
      this.modalDelete = false;
      this.modalPreview = false;
      this.modalDownload = false;
      //deo koda koji sredjuje aktivne dugmice
      if (document.activeElement.toString() == "[object HTMLButtonElement]") {
        document.activeElement.blur();
      }
    },

    //API f-ja koja preuzima sve izvestaje
    getPrivateReportsDesc() {
      //dok se ne preuzmu svi, progress bar se prikazuje
      this.loadingProgressBar = true;
      jwtInterceptor
        .get(`${API_URL}/api/reports/private/`)
        .then((res) => {
          //smestanje svih rezultata u reports
          this.reports = res.data;
          //odvajanje group liste
          this.groupReportsList = this.reports.group_reports;
          
          // this.oznacenaGrupa = this.reports[0].group_id;
          var result = this.reports.filter(obj => {
  return obj.group_id === this.oznacenaGrupa
})
          if (!JSON.parse(localStorage.getItem("storedPrivateReportID")) || result.length==0 ) {
            this.oznacenaGrupa = this.reports[0].group_id;
          }
        })
        .catch(() => {
          // Manage errors if found any
        })
        //finally kada se sve preuzme tada se vise ne prikazuje progres bar
        .finally(() => (this.loadingProgressBar = false));
    },

    //API f-ja za preuzimanje izvestaja
    downloadReports() {
      this.loadingDownload = true;
      jwtInterceptor
        .get(`${API_URL}/api/reports/${this.oznaceniDownload}/download/`, {
          responseType: "blob",
        })
        .then(
          (response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", "report.csv");
            document.body.appendChild(fileLink);
            fileLink.click();

            this.$toast.add({
              severity: "success",
              summary: "Success Message",
              detail: "The report has been successfully downloaded.",
              life: 3000,
            });

            if (
              document.activeElement.toString() == "[object HTMLButtonElement]"
            ) {
              document.activeElement.blur();
            }
          },
          async (error) => {
            this.greskaResponse = JSON.parse(await error.response.data.text());
            this.greskaResponse = this.greskaResponse.error;

            this.$toast.add({
              severity: "info",
              summary: "Error Message",
              detail: this.greskaResponse,
              life: 4000,
            });
            if (
              document.activeElement.toString() == "[object HTMLButtonElement]"
            ) {
              document.activeElement.blur();
            }
          }
        )
        .finally(() => (this.loadingDownload = false));
      this.greskaResponse = [];
    },
    //API f-ja za brisanje izvestaja
    deleteReport() {
      jwtInterceptor
        .delete(`${API_URL}/api/reports/${this.oznaceniDelete}/`)
        .then(() => {
          //nakon obrisanog, opet preuzimamo sve da bismo prikazali osvezenu listu
          this.getPrivateReportsDesc();
          this.$toast.add({
            severity: "success",
            summary: "Success Message",
            detail: "The report has been successfully deleted.",
            life: 3000,
          });
        })
        .catch((error) => {
          this.greskaResponse = error.response.data.error;

          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: "The report wasn't deleted because " + this.greskaResponse,
            life: 4000,
          });
        });
      this.greskaResponse = [];
    },
    saveReportIdInLocalStorage(event) {
      localStorage.setItem("storedPrivateReportID", event.target.value);
    },
  },
};
</script>
<style>
@media all and (max-width: 1296px) {
}
select option:checked,
select option:hover {
  box-shadow: 0 0 10px 100px var(--mainYellow) inset;
}
select:focus > option:checked,
option:hover {
  background: var(--mainYellow) !important;
}

.overrideSelect {
  width: 100%;
  text-align: left;
  margin-left: 1rem;
  font-size: 1.5rem;
  border-radius: 0.5rem;
}
.overrideLabel {
  width: 75%;
  text-align: left;
  margin-left: 1.5rem;
  font-size: 1rem;
}
i {
  width: 2rem;
}
.modalDownload {
  display: inherit; /* Hidden by default */
  position: fixed; /* Stay in place front is invalid - may break your css so removed */
  padding-top: 100px; /* Location of the box - don't know what this does?  If it is to move your modal down by 100px, then just change top below to 100px and remove this*/
  left: 0;
  right: 0; /* Full width (left and right 0) */
  top: 0.3rem;
  bottom: 0; /* Full height top and bottom 0 */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.3);
  /* opacity: 0.5; */

  z-index: 9999; /* Sit on top - higher than any other z-index in your site*/
  text-align: center;
}
.modalDownloadDiv {
  position: absolute;
  top: 6rem;
}
.modalTabel {
  display: inherit; /* Hidden by default */
  position: fixed; /* Stay in place front is invalid - may break your css so removed */
  padding-top: 100px; /* Location of the box - don't know what this does?  If it is to move your modal down by 100px, then just change top below to 100px and remove this*/
  left: 0;
  right: 0; /* Full width (left and right 0) */
  top: 0.3rem;
  bottom: 0; /* Full height top and bottom 0 */
  overflow: auto; /* Enable scroll if needed */

  background-color: rgba(0, 0, 0, 0.3);

  z-index: 9999; /* Sit on top - higher than any other z-index in your site*/
  text-align: center;
}
.modalDiv {
  background-color: white;
  width: 100%;
  position: absolute;

  top: 0;
}
.modalDiv div::-webkit-scrollbar {
  width: 1em;
  height: 1em;
}

.modalDiv div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.modalDiv div::-webkit-scrollbar-thumb {
  background-color: var(--lighterPurple);
  outline: 1px solid var(--Purple);
}
.modal__details {
  text-align: left;
}

.modal__title {
  font-size: 2rem;
  text-align: left;
  margin-left: 1rem;
}

.modal__description {
  margin: 0 !important;
  font-size: 1.6rem;
  font-style: italic;
  text-align: center;
  font-weight: unset;
  border-bottom: 2px solid var(--mainBlue);
  padding-bottom: 2rem;
}

.modal__text {
  padding: 0 4rem;
  margin-bottom: 4rem;

  font-size: 1.6rem;
  line-height: 2;
}
a,
a:link {
  font-family: inherit;
  text-decoration: none;
  cursor: pointer;
}
.modal__text::before {
  content: "";

  position: absolute;
  top: 0%;
  left: 100%;
  transform: translate(-50%, -50%);

  width: 18rem;
  height: 18rem;
  border: 1px solid hsla(0, 0%, 100%, 0.2);
  border-radius: 100rem;

  pointer-events: none;
}
.link-2 {
  width: 5rem;
  height: 5rem;

  border-radius: 100rem;

  color: inherit;
  font-size: 2.2rem;

  position: absolute;
  top: 0rem;
  right: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: 0.2s;
  color: var(--mainBlue);
}

.link-2::before {
  content: "×";
}
a:hover {
  text-decoration: none;
  color: var(--mainYellow);
}
.link-2:hover,
.link-2:focus {
  border-color: white;
}
.p-datatable .p-datatable-thead > tr > th {
  font-size: 1.4rem !important;
  font-weight: normal !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  border: 0.1rem solid #dfe4f3 !important;
  font-size: 1rem !important;
}
.p-datatable-thead th {
  background-color: var(--mainBlue) !important;
  color: white !important;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: white !important;
}
.ui-datatable,
.ui-datatable .ui-datatable-tablewrapper table {
  font-size: 12px !important;
}

/* For Paginator */
.ui-datatable .ui-paginator .ui-paginator-first,
.ui-datatable .ui-paginator .ui-paginator-prev,
.ui-datatable .ui-paginator .ui-paginator-pages .ui-paginator-page,
.ui-datatable .ui-paginator .ui-paginator-next,
.ui-datatable .ui-paginator .ui-paginator-last,
.ui-datatable .ui-paginator .ui-paginator-rpp-options {
  font-size: 10px !important;
}

.ui-datatable .ui-paginator .ui-paginator-current {
  font-size: 10px !important;
}
.btnModal {
  width: 4.5rem !important;
}
.btnTabel {
  width: 8rem !important;
}
</style>
