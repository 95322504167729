<template>
  <template v-if="loading">
    <div class="modalDownload" tabindex="-1">
      <div class="modalDownloadDiv">
        <ProgressBar mode="indeterminate" />
      </div>
    </div>
  </template>
  <template v-else>
    <div
      class="modalPreviewed"
      @click="$emit('close')"
      @keydown.esc="emitToParentReport('Close')"
    >
      <div
        class="modalPreviewedDiv"
        style="background-color: white;
  width: 90%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);"
        @click.stop="close"
        tabindex="0"
      >
        <div ref="content">
          <!-- scrollable or paginator? -->
          <DataTable
            :value="result.result"
            :rowHover="true"
            :scrollable="true"
            scrollHeight="600px"
            removableSort
            style="border-bottom: 2px solid var(--mainBlue)"
          >
            <Column
              v-for="col of koloneTabele"
              :field="col.field"
              :header="col.header"
              :key="col.field"
              :sortable="true"
              style="min-width: 10rem"
            ></Column>
          </DataTable>
          <div
            style="
                                  display: flex;

                                  flex-direction: row;
                                "
          >
            <div style="align-self: center">
              <p
                style="
                                          margin-left: 1rem;
                                          font-weight: bold;
                                        "
              >
                Report row count:
                {{ formatNumber(result.result_count) }}
              </p>
            </div>

            <div
              style="
                                      display: flex;
                                      margin-left: auto;
                                    "
            >
              <button
                class="btn-revertRegular"
                style="
                                        align-self: center;
                                        width: 8rem;
                                        margin-right: 1rem;
                                      "
                @click.stop="emitToParentReport('Download')"
              >
                Download
              </button>
              <button
                class="btn-blue-regular"
                style="
                                        align-self: center;
                                        width: 8rem;
                                        margin-right: 1rem;
                                      "
                @click.stop="emitToParentReport('Close')"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>
<script>
import jwtInterceptor from "../shared/jwtInterceptor";

import { API_URL } from "../../src/.env.js";
export default {
  name: "PreviewReportsModal",
  components: {},
  props: ["oznaceniReport"],
  data: function() {
    return {
      koloneTabele: [],
      greskaResponse: null,
      result: [],
      oznaceniReports: this.oznaceniReport,
      loading: false,
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getReportId();

    document.addEventListener(
      "keydown",
      (e) => {
        this.emitToParentReport(e);
      },

      false
    );
  },
  methods: {
    // close(e) {
    //   if (this.$refs?.content?.contains(e.target)) return;

    //   this.$emit("close");
    //   if (document.activeElement.toString() == "[object HTMLButtonElement]") {
    //     document.activeElement.blur();
    //   }
    //},

    formatNumber(num) {
      return new Intl.NumberFormat("en-US").format(num);
    },
    getReportId() {
      this.loading = true;
      jwtInterceptor
        .get(`${API_URL}/api/reports/${this.oznaceniReports}/preview/`)
        .then((res) => {
          this.result = res.data;
          //Resetujemo kolone na prazno da se ne bi prikazivale prilikom otvaranja drugog reporta
          this.koloneTabele = [];
          this.greskaResponse = [];
          //this.result.result=[];
          //var kolone=this.result.result[0]; //Prikupljamo koje sve kolone

          if (
            document.activeElement.toString() == "[object HTMLButtonElement]"
          ) {
            document.activeElement.blur();
          }
          for (var key in this.result.result[0]) {
            //Iz rezultata izvlacimo moguce kolone, pravimo objekat koji punimo imenom kolone i dodajemo ga u listu kolona tabele. Ponavljamo dok ima novih kolona s`API-ja.

            var jednaKolona = { field: key, header: key };

            this.koloneTabele.push(jednaKolona);
          }
        })

        .catch((error) => {
          this.greskaResponse = error.response.data.error;

          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: this.greskaResponse,
            life: 4000,
          });
          if (
            document.activeElement.toString() == "[object HTMLButtonElement]"
          ) {
            document.activeElement.blur();
          }

          // Manage errors if found any
        })
        .finally(() => (this.loading = false));
      this.greskaResponse = [];
      this.koloneTabele = [];
    },
    emitToParentReport: function(selectedValuesState) {
      var result = {};
      result.state = selectedValuesState;
      result.reportId = this.oznaceniReport;

      if (selectedValuesState.key === "Escape") {
        result.state = "Close";
      }
      this.$emit("selectedValuesState", result);
    },
  },
};
</script>
<style>
.modalPreview {
  display: inherit; /* Hidden by default */
  position: fixed; /* Stay in place front is invalid - may break your css so removed */
  padding-top: 100px; /* Location of the box - don't know what this does?  If it is to move your modal down by 100px, then just change top below to 100px and remove this*/
  left: 0;
  right: 0; /* Full width (left and right 0) */
  top: 0.3rem;
  bottom: 0; /* Full height top and bottom 0 */
  overflow: auto; /* Enable scroll if needed */

  background-color: rgba(0, 0, 0, 0.3);

  z-index: 9999; /* Sit on top - higher than any other z-index in your site*/
  text-align: center;
}
.modalPreviewDiv {
  background-color: white;
  width: 100%;
  position: absolute;

  top: 0;
}
.modalPreviewed {
  display: inherit; /* Hidden by default */
  position: fixed; /* Stay in place front is invalid - may break your css so removed */
  padding-top: 100px; /* Location of the box - don't know what this does?  If it is to move your modal down by 100px, then just change top below to 100px and remove this*/
  left: 0;
  right: 0; /* Full width (left and right 0) */
  top: 0;
  bottom: 0; /* Full height top and bottom 0 */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.3);
  /* opacity: 0.5; */

  z-index: 9999; /* Sit on top - higher than any other z-index in your site*/
  text-align: center;
}
.modalPreviewedDiv {
  background-color: white;
  width: 50%;
  position: absolute;
  left: 50%;
  top: 50% !important;
  border-radius: 0.5rem;

  transform: translate(-50%, -50%);
}
</style>
