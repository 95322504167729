<template>
  <div
    class="modalSave"
    @click="$emit('close')"
  >
    <div
      class="modalSaveDiv"
      ref="content"
      id="modalNumber"
      @click.stop="close"
    >
      <div
        class="modal__detail"
        style="border-bottom: 2px solid var(--mainBlue)"
      >
        <div>
          <h1 class="modal__title" style="margin-bottom:0!important">
            {{ this.columnName }}
          </h1>
          <p
            style="text-align: left; padding-left: 1rem;font-size:1.5rem;font-weight:bold;margin-bottom:1rem;margin-top:1rem!important"
          >
            {{ this.columnDescription }}
          </p>
        </div>
        <div>
          <p
            style="
                              margin: 0 !important;
                              text-align: left;
                              padding-left: 1rem;
                              font-style: italic;
                              color:#9C9C9C;
                                margin-bottom:0!important;
                            "
          >
            number of records with value:
            <span style="font-weight:bold">
              {{ formatNumber(this.columnStaticHaveValue) }}</span
            >
          </p>
          <p
            style="
                              margin: 0 !important;
                              text-align: left;
                              padding-left: 1rem;
                              font-style: italic;
                              color:#9C9C9C
                            "
          >
            number of records without value:
            <span style="font-weight:bold">{{ formatNumber(this.columnStaticNoValue) }}</span>
          </p>
        </div>
        <hr style="width:97%;text-align:left;background-color:#9C9C9C" />
        <div class="container-fluid">
          <div
            class="row"
            style="margin-top: 1rem;
    margin-bottom: 1rem;"
          >
            <div class="col-4">
              <div
                class="container"
                style="
                                  text-align: left;
                                  margin-left: 1rem;
                                  
                                "
              >
                <label for="exclude" style="font-size:10px!important"
                  >Include fields without values</label
                >
                <input
                  type="checkbox"
                  id="includeCheckbox"
                  name="include"
                  @change="onChangedFieldWithoutValues()"
                  v-model="fieldWithoutValues"
                  style="display: inline; margin-left: 1rem;vertical-align: middle"
                  class="hoverCheckbox"
                />
              </div>
            </div>

            <div class="col-6">
              <button
                class="btn btn-regular"
                style="float:right"
                @click="addNewFilterToLocalFiltersList()"
              >
                Add
              </button>
            </div>
            <div class="col-2">
              <div>
                <button
                  class="btn btn-danger"
                  style="float:right;margin-right:1rem;"
                  @click="clearAllFilterValues()"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="container-fluid"
          id="customScrollBar"
          style="height: 35rem;
    overflow: scroll;
    overflow-x: hidden;"
        >
          <h3
            style="text-align: left; margin-left: 1rem;margin-bottom:0!important"
          >
            Selected values
          </h3>

          <div class="row" style="margin-left: 0.25rem">
            <ul style="width: 100%; list-style-type: decimal">
              <li
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: left;
                  align-items: center;
                  margin-right: 1rem;
                "
                v-for="(item, index) in this.localFilters"
                v-bind:key="item.id"
                v-show="
                  !(item.value == 'neinicijalizovan') &&
                    !(item.value[0] == null)
                "
              >
                <div class="col-2">
                  <input
                    v-show="item.option_type != 1 && item.option_type != 2"
                    style="width:100%;margin-left:1rem"
                    type="number"
                    v-model="item.value[0]"
                    placeholder="from"
                    @change="onInputValueChanged(index)"
                  />
                  <button
                    class="btn btn-regular"
                    v-show="item.option_type == 1"
                    style="width:8rem;margin-left:1rem;"
                    @click="openSelectedValuesModal(item)"
                  >
                    ...
                    <teleport v-if="item.testProperty2" to="#modals">
                      <selected-values
                        v-on:selectedValuesState="onSelectedValuesChange"
                        v-bind:selectedValues="selectedValues"
                        :modelValue="item.value"
                      />
                    </teleport>
                  </button>
                  <button
                    class="btn btn-regular"
                    v-if="item.option_type == 2"
                    style="width:8rem;margin-left:1rem;"
                    @click="openPasteValuesModal(item)"
                  >
                    ...
                    <teleport v-if="item.testProperty" to="#modals">
                      <paste-values
                        v-on:pasteValuesState="onPasteValuesChange"
                        :modelValue="item.value"
                      />
                    </teleport>
                  </button>
                </div>

                <div class="col-2">
                  <input
                    v-show="item.option_type != 1 && item.option_type != 2"
                    style="width:100%;margin-left:2rem"
                    type="number"
                    v-model="item.value[1]"
                    placeholder="to"
                    @change="onInputValueChanged(index)"
                  />
                </div>

                <div class="col-3">
                  <select
                    :key="index"
                    style="margin-left: 3rem;"
                    @change="onFilterTypeChanged($event, item)"
                  >
                    <option disabled value="">Please select one</option>
                    <option
                      :selected="setDefaultSelectValue(item, '1')"
                      value="1"
                    >
                      Value range</option
                    >
                    <option
                      v-if="columnVisibility2"
                      :selected="setDefaultSelectValue(item, '2')"
                      value="2"
                      >Select values
                    </option>
                    <option
                      v-if="pasteValues"
                      :selected="setDefaultSelectValue(item, '3')"
                      value="3"
                      >Paste values</option
                    >
                  </select>
                </div>
                <div class="col-3" style="margin-top:0.5rem;">
                  <label for="exclude" style="font-size:10px!important"
                    >Exclude</label
                  >
                  <input
                    type="checkbox"
                    v-model="item.not_equal"
                    style="display: inline; margin-left: 1rem;vertical-align: middle;"
                    class="hoverCheckbox"
                  />
                </div>
                <div class="col-2">
                  <button
                    class="btn btn-danger"
                    style="
    font-size:1.15rem;"
                    @click="removeSelectedFilterFromLocalFiltersList(item)"
                  >
                    Remove
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        style="
                          display: flex;
                          margin: 0.5rem;
                          flex-direction: row;
                          align-items: stretch;
                          align-content: stretch;
                        "
      >
        <div
          style="
                            display: flex;
                            flex-direction: column;
                            flex: 2 0 0;
                            justify-content: left;
                          "
        >
          <div style="display: flex; justify-content: right">
            <div style="align-self: center">
              <button
                class="btn btn-regular"
                style="
                                  margin-right: 1rem;
                                  margin-left: 0.5rem;
                                "
                @click.stop="emitToParent('Save')"
              >
                Apply
              </button>
            </div>
          </div>
        </div>

        <div style="display: flex; justify-content: flex-end">
          <div style="align-self: center">
            <button
              class="btn btn-regular btnTabel"
              @click.stop="emitToParent('Close')"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>

      <a @click.stop="emitToParent('Close')" class="link-2"></a>
    </div>
  </div>
</template>
<script>
import PasteValues from "./PasteValues.vue";
import SelectedValues from "./SelectedValues.vue";
import jwtInterceptor from "../shared/jwtInterceptor";

import { API_URL } from "../../src/.env.js";
export default {
  name: "FilterModalInt",
  props: ["filters"],
  components: { PasteValues, SelectedValues },
  data: function() {
    return {
      localFilters: this.filters,
      fieldWithoutValues: false,
      columnName: "",
      columnDescription: "",
      columnStaticHaveValue: "",
      columnStaticNoValue: "",
      tmpObjIni: {},
      fieldIdForEmit: "",
      columnDbType: "",
      columnVisibility: false,
      columnVisibility2: false,
      pasteValues: false,
      selectedValues: [],
      listener: (e) => {
        e.key === "Escape" && this.emitToParent("Close");
      },
      eventt: "keydown",
      listenerForEnter:(e)=>{ (e.ctrlKey && e.keyCode==13) && this.emitToParent("Save")}
    };
  },

  computed: {},
  created() {
    this.getDescriptiveDataFromFilter();
    this.getSelectedValuesByColumnId();
  },
  mounted() {
    this.localFilters.forEach((element) => {
      if (element.value[0] == null) {
        this.fieldWithoutValues = true;
      }
    });
        window.addEventListener(this.eventt, this.listener);
        window.addEventListener(this.eventt, this.listenerForEnter);

  },
  methods: {
    formatNumber(num) {
      return new Intl.NumberFormat("en-US").format(num);
    },
    onSelectedValuesChange(value) {
      window.addEventListener(this.eventt, this.listener);

      if (value.state == "Close") {
        this.localFilters.forEach((element) => {
          element.testProperty2 = false;
        });
      } else if (value.state == "Save") {
        const index = this.localFilters
          .map((e) => e.testProperty2)
          .indexOf(true);
        this.localFilters[index].value = value.payload;

        this.localFilters.forEach((element) => {
          element.testProperty2 = false;
        });
      }
    },
    openSelectedValuesModal(item) {
      window.removeEventListener(this.eventt, this.listener);
      window.removeEventListener(this.eventt,this.listenerForEnter)
      if (document.activeElement.toString() == "[object HTMLButtonElement]") {
        document.activeElement.blur();
      }
      item.testProperty2 = true;
    },
    openPasteValuesModal(item) {
        window.removeEventListener(this.eventt, this.listener);
        window.removeEventListener(this.eventt,this.listenerForEnter)

      if (document.activeElement.toString() == "[object HTMLButtonElement]") {
        document.activeElement.blur();
      }
      item.testProperty = true;
    },
    onPasteValuesChange(value) {
         window.addEventListener(this.eventt, this.listener);
         window.addEventListener(this.eventt,this.listenerForEnter);
      if (value.state == "Close") {
        this.localFilters.forEach((element) => {
          element.testProperty = false;
        });
      } else if (value.state == "Save") {
        const index = this.localFilters
          .map((e) => e.testProperty)
          .indexOf(true);
        this.localFilters[index].value = value.payload;
        this.localFilters.forEach((element) => {
          element.testProperty = false;
        });
      }
    },  
       removeEmptyValues() {
    
           this.localFilters = this.localFilters.filter(el => {
   return el.value[0] !== "" || el.value[1] !== "";
});
       },
    getDescriptiveDataFromFilter() {
      this.columnName = this.localFilters[0].column_name;
      this.columnDescription = this.localFilters[0].column_description;
      this.columnStaticHaveValue = this.localFilters[0].column_static.have_value;
      this.columnStaticNoValue = this.localFilters[0].column_static.no_value;
      this.tmpObjIni = this.localFilters[0];
      this.fieldIdForEmit = this.localFilters[0].field_id;
      this.columnDbType = this.localFilters[0].field_type;
      this.columnVisibility = this.localFilters[0].column_db_visible;
      this.columnVisibility2 = this.localFilters[0].column_db_select_values;
      this.pasteValues = this.localFilters[0].column_db_paste_values;
    },
    emitToParent: function(modalState) {
      for (var i = this.localFilters.length - 1; i >= 0; --i) {
        this.localFilters.filter((n) => n);

        if (
          this.localFilters[i].value == "neinicijalizovan" ||
          this.localFilters[i].value == "neinicijalizovani"
        ) {
          this.localFilters.splice(i, 1);
        } else {
          this.localFilters[i].value.join("").split("");
        }
      }

      var result = {};
      result.state = modalState;
      result.id = this.fieldIdForEmit;
      this.removeEmptyValues();
      result.payload = this.localFilters;
      result.type = this.columnDbType;

      this.$parent.$emit("modalState", result);
    },
    onChangedFieldWithoutValues: function() {
      if (this.fieldWithoutValues) {
        var tmpObj = {};
        tmpObj = JSON.parse(JSON.stringify(this.tmpObjIni));
        tmpObj.value = [null];
        tmpObj.operator = "=";
        tmpObj.type = 1;
        tmpObj.not_equal = false;
        this.localFilters.push(tmpObj);
      } else {
        var noviIndex = this.localFilters.findIndex((e) => e.value[0] == null);

        this.localFilters.splice(noviIndex, 1);
      }
    },
    onInputValueChanged(index) {
      console.log("odradi li");
      if (
        this.localFilters[index].value[0] == "" ||
        (this.localFilters[index].value[0] == null &&
          this.localFilters[index].operator != "=")
      ) {
        var parsedFilterValueTo = Number(this.localFilters[index].value[1]);
        this.localFilters[index].value[0] = "";
        this.localFilters[index].value[1] = parsedFilterValueTo;

        this.localFilters[index].type = 1;
        this.localFilters[index].operator = "<=";
      } else if (
        this.localFilters[index].value[1] == "" ||
        (this.localFilters[index].value[1] == null &&
          this.localFilters[index].operator != "=")
      ) {
        var parsedFilterValueFrom = Number(this.localFilters[index].value[0]);
        this.localFilters[index].value[1] = "";
        this.localFilters[index].value[0] = parsedFilterValueFrom;
        this.localFilters[index].type = 1;
        this.localFilters[index].operator = ">=";
      } else {
        var parsedIntFilterValueTo = Number(this.localFilters[index].value[0]);
        var parsedIntFilterValueFrom = Number(
          this.localFilters[index].value[1]
        );

        this.localFilters[index].value = [
          parsedIntFilterValueTo,
          parsedIntFilterValueFrom,
        ];
        this.localFilters[index].operator = "=";
        this.localFilters[index].type = 2;
      }
    },

    addNewFilterToLocalFiltersList() {
      var tmpObj = {};
      tmpObj = JSON.parse(JSON.stringify(this.tmpObjIni));
      tmpObj.value = ["", ""];
      tmpObj.operator = "=";
      tmpObj.not_equal = false;
      this.localFilters.push(tmpObj);

      if (document.activeElement.toString() == "[object HTMLButtonElement]") {
        document.activeElement.blur();
      }
    },
    clearAllFilterValues() {
      if (this.fieldWithoutValues) {
        var index = this.localFilters.map((a) => a.value).indexOf(null);
        this.localFilters.splice(index, 1);
        this.fieldWithoutValues = false;
      }
      this.localFilters = [];
    },
    removeSelectedFilterFromLocalFiltersList(i) {
      var index = this.localFilters.map((a) => a.value).indexOf(i.value);

      this.localFilters.splice(index, 1);
    },
    onFilterTypeChanged: function(e, item) {
      var filterType = e.target.value;
      if (filterType == 1) {
        item.type = 1;
        item.option_type = 0;
        item.value = ["", ""];
      } else if (filterType == 2) {
        item.type = 3;
        item.option_type = 1;
        item.value = ["neinicijalizovani"];
      } else if (filterType == 3) {
        item.type = 3;
        item.option_type = 2;
        item.value = ["neinicijalizovani"];
      }
    },
    setDefaultSelectValue(item) {
      if (item.option_type == 1 || item.option_type == 2) {
        return true;
      } else {
        return false;
      }
    },
    getSelectedValuesByColumnId() {
      jwtInterceptor
        .post(`${API_URL}/api/metadata/column/values/`, {
          column_id: this.fieldIdForEmit,
        })
        .then((response) => {
          this.selectedValues = response.data.result;
          for (var i = 0; i < this.selectedValues.length; i++) {
            if (
              this.selectedValues[i] === null ||
              this.selectedValues[i] === " " ||
              this.selectedValues[i] === ""
            ) {
              var index = this.selectedValues.indexOf(null);
              this.selectedValues.splice(index, 1);
            }
          }
        })
        .catch(() => {});
    },
  },
  unmounted() {
    window.removeEventListener(this.eventt, this.listener);
    window.removeEventListener(this.eventt, this.listenerForEnter);

  },
};
</script>
<style>
/*Removing arrorws from input*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
