<template>
  <div class="selectedValuesModal">
    <div class="selectedValuesModalDiv" id="selectedValuesModal" ref="content">
      <div
        class="modal__detail"
        style="border-bottom: 2px solid var(--mainBlue)"
      >
        <div>
          <h1 class="modal__title" style="margin-bottom:0!important">
            Selected values
          </h1>
          <div class="container-fluid">
            <div class="row"
              style="margin:15px;"
            >
            <div class="col-sm-9">
              <input     style="  padding: 4px 12px;
      border: 1px solid rgba(0,0,0,.12);
      transition: .15s all ease-in-out;
          width: 100%;"
                type="text"
                v-model="search"
                placeholder="Search.."
              />
            </div>
            <div class="col-sm-3">
              <label  class="switch">
                <input
                  type="checkbox"
                  @change="filterListByCheckboxes($event)"
                  style="padding: 4px 12px;float:right;margin-top:-2rem;margin-right:2rem"
                />
                <span class="slider round">  
</span>
              </label>
                           <h3 style="margin:0!important;padding:0!important;font-size:1rem">  display selected values</h3>
              </div>
            </div>

            <div
              id="customScrollBar"
              style="height: 70vh;
    overflow-y: scroll;"
            >
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Predefined values</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(item, index) in filteredList" :key="index">
                    <th scope="row">
                      <input
                        type="checkbox"
                        style="display: inline; margin-left: 1rem;vertical-align: middle"
                        :value="item"
                        v-model="this.bindedValue"
                      />
                    </th>
                    <td>{{ item }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div
        style="
          display: flex;
          margin: 0.5rem;
          flex-direction: row;
          align-items: stretch;
          align-content: stretch;
        "
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            flex: 2 0 0;
            justify-content: left;
          "
        >
          <div style="display: flex; justify-content: right">
            <div style="align-self: center">
              <button
                class="btn btn-regular"
                style="margin-right: 1rem; margin-left: 0.5rem; "
                @click.stop="emitToParentFilter('Save')"
              >
                Apply
              </button>
            </div>
          </div>
        </div>

        <div style="display: flex; justify-content: flex-end">
          <div style="align-self: center">
            <button
              class="btn btn-regular btnTabel"
              @click.stop="emitToParentFilter('Close')"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>

      <a class="link-2" @click.stop="emitToParentFilter('Close')"></a>
    </div>
  </div>
</template>
<script>
export default {
  name: "SelectedValues",
  components: {},
  props: ["item", "modelValue", "selectedValues"],
  data: function() {
    return {
      filterObj: this.item,
      selectedValuesFromParent: this.selectedValues.map(String),
      search: "",
      bindedValue: this.modelValue,
      listener: (e) => {
        e.key === "Escape" && this.emitToParentFilter("Close");
      },
      childEvent: "keydown",
      listenerForEnter:(e)=>{ (e.ctrlKey && e.keyCode==13) && this.emitToParentFilter("Save")}
    };
  },
  computed: {
    filteredList() {
      return this.selectedValuesFromParent.filter((post) => {
        return post.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  created() {
    if (this.bindedValue == "neinicijalizovani") {
      this.bindedValue = [];
    }
  },

  mounted() {
    window.addEventListener(this.childEvent, this.listener);
    window.addEventListener(this.childEvent, this.listenerForEnter);

  },
  methods: {
    filterListByCheckboxes(e) {
      if (this.bindedValue.length > 0 && e.target.checked) {
        this.selectedValuesFromParent = this.bindedValue;
      } else if (!e.target.checked) {
        this.selectedValuesFromParent = this.selectedValues.map(String);
      }
    },

    emitToParentFilter: function(selectedValuesState) {
      var result = {};
      result.state = selectedValuesState;
      result.payload = this.bindedValue;
      // result.payload=this.bindedValueString.split("\n");
      //window.removeEventListener(this.childEvent, this.listener);
      this.$emit("selectedValuesState", result);
    },
  },
  unmounted() {
    window.removeEventListener(this.childEvent, this.listener);
    window.removeEventListener(this.childEvent, this.listenerForEnter);

  },
};
</script>
<style>
.selectedValuesModal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place front is invalid - may break your css so removed */
  padding-top: 100px; /* Location of the box - don't know what this does?  If it is to move your modal down by 100px, then just change top below to 100px and remove this*/
  left: 0;
  right: 0; /* Full width (left and right 0) */
  top: 0;
  bottom: 0; /* Full height top and bottom 0 */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.3); /* Black w/ opacity */
  z-index: 9999; /* Sit on top - higher than any other z-index in your site*/
  text-align: center;
}
.selectedValuesModalDiv {
  background-color: white;
  width: 50%;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 1rem;
  margin-bottom: 2rem;

  transform: translate(-50%, -50%);
}
li {
  list-style-type: none;
}
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #38446F;
}

input:focus + .slider {
  box-shadow: 0 0 1px #38446F;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
