<template>
  <main
    style="
      background-color: #f0f4f8 !important;
      width: 100%;
      height: 100%;
      padding: 0;
    "
    ref="top"
  >
    <div class="container-fluid" style="background-color: #f0f4f8 !important">
      <div @wheel="handleWheel">
        <div class="arrow" v-if="showArrow" @click="scrollToTop()">
          <i class="pi pi-angle-up"></i>
        </div>
      </div>
      <div class="row" style="position: relative; width: 100vw" id="home">
        <img
          style="width: 100vw; height: 100%"
          src="../../assets/staticAssets/homePage.svg"
        />
        <div
          class="col-lg-6 col-md-8 col-sm-12"
          style="
            position: absolute;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            padding-left: 15px;
          "
        >
          <h1 class="text-xl-10 text-md-8 text-sm-6" style="color: #3d3d94">
            Business data
          </h1>
          <h1 class="text-xl-10 text-md-8 text-sm-6" style="color: #3d3d94">
            for every need.
          </h1>
          <h4
            class="text-md-5 text-sm-4 d-none d-md-block"
            style="color: #5d708e"
          >
            Access the richest collection of complete and accurate business
            information datasets in Balkans.<br />Filter, build reports and
            export data that your business requires.
          </h4>
          <h4 class="text-md-5 text-sm-4 d-md-none" style="color: #5d708e">
            Access the richest collection of complete and accurate business
            information datasets in Balkans.<br />Filter, build reports and
            export data that your business requires.
          </h4>
          <router-link v-bind:to="{ path: '/home', hash: '#contact' }">
            <button
              class="
              btn btn-primary
              findOutMoreButton
              d-sm-block d-md-inline-block
            "
            >
              Find out More
            </button>
          </router-link>
        </div>
      </div>

      <div
        id="about"
        class="row"
        style="
          background-color: #ffffff;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100vw;
        "
      >
        <div class="col-sm-12">
          <div style="text-align: center">
            <h1 style="color: #3d3d94; line-height: 1">About the SegDB</h1>
            <h4 style="color: #5d708e; font-size: 1.5rem; line-height: 1">
              SegDB is a tool that helps you get the most out of our datasets
            </h4>
          </div>
        </div>
      </div>
      <div style="background-color: #ffffff">
        <div class="container-fluid">
          <div class="row">
            <div
              class="col-sm-6 d-flex align-items-center justify-content-center"
            >
              <img
                src="../../assets/staticAssets/about2.svg"
                class="img-fluid"
              />
            </div>
            <div class="col-sm-6">
              <ol>
                <li>
                  <h2>Select dataset</h2>
                  <p>
                    Select among multiple available international or country
                    specific datasets. Use only datasets you need.
                  </p>
                </li>
                <li>
                  <h2>Build reports</h2>
                  <p>
                    Select filers and report columns. Save reports for later
                    use.
                  </p>
                </li>
                <li>
                  <h2>Export data</h2>
                  <p>Export data into CSV for further use.</p>
                </li>
                <li>
                  <h2>Access pubic reports</h2>
                  <p>Access reports build by other users or our analysts.</p>
                </li>
                <li>
                  <h2>Have our analyst</h2>
                  <p>Ask our analyst to prepare a custom dataset for you.</p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div style="background-color: #ffffff" id="datasets">
        <div class="container-fluid">
          <div class="row d-flex align-items-center justify-content-center">
            <div class="col-sm-6 text-center">
              <h1>Datasets</h1>
              <h2>Countries covered</h2>
              <img
                src="../../assets/staticAssets/serbia.svg"
                class="img-fluid"
              />
              <img
                src="../../assets/staticAssets/bulgaria.svg"
                class="img-fluid"
                style="margin-top: 1rem"
              />
              <h3 class="pt-3">Data:</h3>
              <p>Companies</p>
              <p>Financials</p>
              <p>Promissory notes</p>
              <p>Contacts</p>
            </div>
            <div class="col-sm-6 text-center">
              <img
                src="../../assets/staticAssets/dataset.svg"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="d-none d-sm-block" id="industries">
        <div
          style="
            background-color: #ffffff;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <div class="row" style="width: auto">
            <div class="col-sm-12 text-center">
              <h1>Industries</h1>
            </div>
            <div class="col-sm-12 text-center">
              <h2>Find out more about industries that we can cover</h2>
            </div>
            <div class="col-sm-4 d-flex">
              <div class="buttonGroup ml-auto">
                <button>Banking</button>
                <button>Insurance</button>

                <button>Telco</button>

                <button>Wholesale</button>

                <button>IT</button>
              </div>
            </div>
            <div class="col-sm-3 d-flex">
              <img
                class="justify-content-between"
                style="height: 100%; position: absolute"
                src="../../assets/staticAssets/industrieData.png"
              />
            </div>
            <div class="col-sm-5 d-flex">
              <div class="buttonGroupExpanded">
                <button>
                  <i
                    style="
                      color: #fff;
                      background: #f89d00;
                      border-radius: 50%;
                      padding: 4px;
                      position: absolute;
                      left: 1em;
                    "
                    class="pi pi-check"
                  ></i
                  >Append data to company national ID numbers
                </button>
                <button>
                  <i
                    style="
                      color: #fff;
                      background: #f89d00;
                      border-radius: 50%;
                      padding: 4px;
                      position: absolute;
                      left: 1em;
                    "
                    class="pi pi-check"
                  ></i
                  >Export financial statements
                </button>

                <button>
                  <i
                    style="
                      color: #fff;
                      background: #f89d00;
                      border-radius: 50%;
                      padding: 4px;
                      position: absolute;
                      left: 1em;
                    "
                    class="pi pi-check"
                  ></i
                  >Build target lists
                </button>

                <button>
                  <i
                    style="
                      color: #fff;
                      background: #f89d00;
                      border-radius: 50%;
                      padding: 4px;
                      position: absolute;
                      left: 1em;
                    "
                    class="pi pi-check"
                  ></i
                  >Determine total addressable market
                </button>

                <button>
                  <i
                    style="
                      color: #fff;
                      background: #f89d00;
                      border-radius: 50%;
                      padding: 4px;
                      position: absolute;
                      left: 1em;
                    "
                    class="pi pi-check"
                  ></i
                  >Export data for further analysis
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="customDatasets"
        style="
          background-color: #ffffff;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <div
          class="row customDatasetContainer"
          style="position: relative; flex-wrap: wrap"
        >
          <div class="col-sm-12 text-center" style="margin: auto">
            <h1 style="color: #f89d00">Custom Datasets</h1>
            <h3 style="color: #9facdc">
              Let us know what dataset you need and we’ll build it for you.
            </h3>
            <router-link v-bind:to="{ path: '/home', hash: '#contact' }">
              <button
                style="
                text-decoration: none;
                color: white !important;
                width: 100%;
                max-width: 300px;
                height: 50px;
                background-color: #f89d00;
                border-radius: 5px;
                color: white;
                margin-top: 20px;
                border: none;
              "
              >
                Contact us
              </button>
            </router-link>
          </div>
        </div>
      </div>

      <div
        style="
          background-color: var(--lightestPurple);
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        "
        id="contact"
      >
        <div class="row">
          <div class="col-sm-12 text-center">
            <h1>Contact us</h1>
            <form>
              <div class="row">
                <div class="col-sm-6">
                  <input
                    type="text"
                    id="fname"
                    name="fname"
                    placeholder="Your Name & Surname"
                    style="width: 100%"
                  /><br />
                </div>
                <div class="col-sm-6">
                  <input
                    type="text"
                    id="lname"
                    name="lname"
                    placeholder="Your Company"
                    style="width: 100%"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <input
                    type="text"
                    id="fnumber"
                    name="fnumber"
                    placeholder="Your Phone Number"
                    style="width: 100%"
                  /><br />
                </div>
                <div class="col-sm-6">
                  <input
                    type="text"
                    id="femail"
                    name="femail"
                    placeholder="Your email"
                    style="width: 100%"
                  />
                </div>
              </div>
              <div class="row">
                <h3 style="color: var(--mainBlue)">
                  Select one or more industries
                </h3>
              </div>
              <div class="row">
                <div class="buttonGroupForIndustries">
                  <button class="buttonWithCheckbox" disabled>
                    <label>
                      <input type="checkbox" style="margin-right:1rem" />
                      Banking
                    </label>
                  </button>
                  <button class="buttonWithCheckbox" disabled>
                    <label>
                      <input type="checkbox" style="margin-right:1rem" />
                      Insurance
                    </label>
                  </button>
                  <button class="buttonWithCheckbox" disabled>
                    <label>
                      <input type="checkbox" style="margin-right:1rem" />
                      Telco
                    </label>
                  </button>
                  <button class="buttonWithCheckbox" disabled>
                    <label>
                      <input type="checkbox" style="margin-right:1rem" />
                      Wholesale
                    </label>
                  </button>
                  <button class="buttonWithCheckbox" disabled>
                    <label>
                      <input type="checkbox" style="margin-right:1rem" />
                      IT
                    </label>
                  </button>
                </div>
              </div>

              <div class="row">
                <h3>Or enter the name of the industry you are interested in</h3>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <input
                    type="text"
                    id="fadditional"
                    name="fadditional"
                    style="width: 100%"
                    placeholder="The name of the industry"
                  />
                </div>
              </div>
              <div class="row">
                <h3>Additional text</h3>
              </div>
              <div class="row">
                <textarea
                  style="height: 10rem"
                  id="fadditional"
                  name="fadditional"
                  placeholder="Message"
                />
              </div>
            </form>
            <button
              style="
                text-decoration: none;
                color: white !important;
                width: 152px;
                height: 43px;
                background-color: #f89d00;
                border-radius: 5px;
                color: white;
                margin-top: 0;
                border: none;
              "
            >
              Send message
            </button>
          </div>
        </div>
      </div>
      <div
        style="
          background-color: var(--mainBlue);
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 1rem;
          height: 5rem;
        "
      >
        <h4>Built by <span style="color: white">Cube Team LTD</span></h4>
      </div>
    </div>
  </main>
</template>

<script>
import emitter from '@/mitt';

export default {
  name: "staticHome",
  // components: {},
  data() {
    return {
      showArrow: false,
    };
  },

  mounted() {
    window.addEventListener("wheel", this.handleWheel, { passive: false });
    emitter.on("showArrow", this.showArrowHere);

  },

  computed: {},
  methods: {
    showArrowHere() {
      // Do something when the link is clicked
   
    

      this.showArrow=true;
    
    },
    scrollToTop() {
      this.$refs.top.scrollIntoView({ behavior: "smooth" });
      this.$router.push("/home");
    },
    handleWheel(event) {
      if (event.deltaY > 0) {
        this.showArrow = true;
      } else {
        this.showArrow = false;
        this.$router.push("/home");
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener("wheel", this.handleWheel);
    emitter.off("showArrow", this.showArrowHere);
  },
};
</script>

<style scoped>
[type="checkbox"] {
  width: 2rem;
  height: 2rem;
  color: white;
  vertical-align: middle;
  -webkit-appearance: none;
  background: none;
  border: 0 !important;
  outline: 0 !important;
  flex-grow: 0;
  border-radius: 50%;
  background-color: #f89d00;
  transition: background 300ms;
  cursor: pointer;
  padding: unset;
  margin: unset;
  box-shadow: none;

  border: unset;
}

[type="checkbox"]::before {
  content: "";
  color: transparent;
  display: block;
  vertical-align: middle;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 0!important;
  background-color: transparent;
  background-size: contain;

  /* content: "";
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  border: 0;
  background-color: transparent;
  background-size: contain;
  box-shadow: inset 0 0 0 1px #CCD3D8; */
}

/* Checked */

[type="checkbox"]:checked {
  background-color: #f89d00;
}

[type="checkbox"]:checked::before {
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
}

.arrow {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  background-color: #b8c1e1;
  color: #fff;
  text-align: center;
  line-height: 40px;
  font-size: 24px;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  z-index: 9999;
}
.arrow > i {
  font-size: 17px;
}
/* .arrow.show {
  display: block;
} */

.buttonWithCheckbox {
  padding-right: 1em;
}
.buttonWithCheckbox:hover {
  cursor: pointer;
}
.buttonWithCheckbox > label:hover {
  cursor: pointer;
}
.buttonWithCheckbox > label > input:hover {
  cursor: pointer;
}
/* .buttonWithCheckbox input[type="checkbox"] {
  float: left;
} */
.buttonWithCheckbox label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px !important;
  line-height: 50px !important;
}

@media (min-width: 768px) {
  h1 {
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 1.5rem;
  }

  h4 {
    max-width: 90%;
    white-space: normal;
  }
}
.staticHome {
}
ol {
  counter-reset: li; /* Initiate a counter */
  margin-left: 0; /* Remove the default left margin */
  padding-left: 0; /* Remove the default left padding */
}
ol > li {
  position: relative; /* Create a positioning context */
  margin: 0 0 6px 2em; /* Give each list item a left margin to make room for the numbers */
  padding: 0px 8px; /* Add some spacing around the content */
  list-style: none; /* Disable the normal item numbering */
}
ol > li:before {
  content: counter(li); /* Use the counter as content */
  counter-increment: li; /* Increment the counter by 1 */
  /* Position and style the number */
  position: absolute;
  top: -2px;
  left: -2em;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 2em;
  /* Some space between the number and the content in browsers that support
       generated content but not positioning it (Camino 2 is one example) */
  margin-right: 8px;
  padding: 4px;
  color: #fff;
  background: #f89d00;
  border-radius: 50%;
  font-weight: bold;
  font-family: "Helvetica Neue", Arial, sans-serif;
  text-align: center;
}
li ol,
li ul {
  margin-top: 6px;
}
ol ol li:last-child {
  margin-bottom: 0;
}
.findOutMoreButton {
  width: 152;
  height: 43px;
  background-color: #f89d00;
  border-radius: 5px;
  color: white;
  margin-top: 0;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  border: none;
}
.buttonGroup button {
  background-color: #e0e4f4; /* Green background */
  border: none; /* Green border */
  color: #3a4470; /* White text */
  font-weight: bold;
  padding: 10px 24px; /* Some padding */
  cursor: pointer; /* Pointer/hand icon */
  width: 20rem; /* Set a width if needed */
  height: 6rem;
  margin-bottom: 0.5rem;
  display: block; /* Make the buttons appear below each other */
  border-radius: 1rem;
}
.buttonGroupForIndustries button {
  background-color: white; /* Green background */
  border: none; /* Green border */
  color: #3a4470; /* White text */
  font-weight: bold;
  height: 5rem;
  width: 12rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 1rem;
}
.buttonGroupForIndustries button:hover{
  cursor:default;
}
.buttonGroupExpanded button {
  background-color: #e0e4f4; /* Green background */
  border: none; /* Green border */
  color: #3a4470; /* White text */
  font-weight: bold;
  padding: 10px 24px; /* Some padding */
  cursor: pointer; /* Pointer/hand icon */
  width: 30rem; /* Set a width if needed */
  height: 6rem;
  margin-bottom: 0.5rem;
  display: block; /* Make the buttons appear below each other */
  border-radius: 1rem;
}
input,
textarea {
  display: block;
  background-color: white;
  color: var(--mainBlue);
  font-size: bold;
  font-weight: bold;
  padding: 1.5rem;
  margin: 0.5rem;
  border: none;
  outline: none !important;
  border-radius: 1rem;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #9facdc;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #9facdc;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #9facdc;
}
.customDatasetContainer {
  background-image: url("../../assets/staticAssets/customDatasets.svg");
  background-size: cover;
  background-position: center;
  height: 40vh; /* adjust this to the desired height */
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
