<template>
    <div>
        <!-- <group-reports></group-reports> -->
    </div>
    <div>
        <private-reports/>
    </div>
</template>
<script>
// import GroupReports from '../components/GroupReports.vue';
import PrivateReports from '../components/PrivateReports.vue';
export default {
    components:{
        // GroupReports,
        PrivateReports
    }
}
</script>