<template>
  <TheNavigation v-if="!$route.meta.hideNavbar && $route.meta.requiredAuth" :username="userName" />
  <static-navigation v-if="$route.meta.hideNavbar" />
  <router-view></router-view>
 

</template>

<script>
import TheNavigation from './components/TheNavigation.vue';
import staticNavigation from './components/staticPages/staticNavigation.vue'




export default {
    name: 'App',
    computed: {
       
    },
    data() {
    return {
      info:[],
       AuthStr : 'Bearer ' + localStorage.getItem('access'),
      //  userRole:store.getters["auth/getAuthData"],      
       userName:''
    };
  },
  mounted() {
   
  },
  setup(){
 },
    components: {
        TheNavigation, staticNavigation

       
    },
    methods:{
      
    }
   
};
</script>
