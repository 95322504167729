<template>
        
    <report-information/>
    
        
  
</template>
<script>
import ReportInformation from './ReportInformation.vue';


export default {
   
         
    components:{
        ReportInformation
    }
    
}
</script>