import jwtInterceptor from '../../shared/jwtInterceptor';
import {API_URL} from '../../.env';


const state=()=>({
    info:[]
});


const getters={
    getNavInfo(state){
        return state.info
    }
};

const actions={
    async getNavInfo({commit}){
        var response=await jwtInterceptor.get(`${API_URL}/api/profile/info/`);
        if(response && response.data){
            commit('setNavInfo', response.data);
        }
    }

};

const mutations={
    setNavInfo(state, payload){
        state.info=payload
    }
};


export default{
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}