<template>
  <div class="margin-bottom">
    <div class="modalInformation" @click.stop="close" tabindex="-1">
      <div
        ref="content"
        class="modalDivInformation"
        style="border-bottom: 2px solid var(--mainBlue)"
      >
        <slot />
        <h1 class="modal__title" style="text-align: left; margin-left: 1rem">
          Add new user
        </h1>
        <form>
          <div class="row margin-bottom">
            <div class="col-sm-3">
              <label for="postGroupName">Username</label>
            </div>
            <div class="col-sm-8">
              <input
                id="postGroupName"
                v-model="postUsername"
                type="text"
                class="form-control modalOverride"
              />
            </div>
          </div>
          <div class="row margin-bottom">
            <div class="col-sm-3">
              <label for="postGroupName">Password</label>
            </div>
            <div
              class="col-sm-8"
              style="display: inline-block;
  position: relative;
  overflow: hidden;"
            >
              <input
                class="form-control modalOverride"
                v-bind:type="[showPassword ? 'text' : 'password']"
                placeholder="Current password"
                name="password"
                v-model="postPassword"
              />
              <span
                class="input-group-text"
                style="  position: absolute;
  top: 0;
  right: 0;
  transition: right 0.2s;"
                @click="showPassword = !showPassword"
                ><i
                  :class="[showPassword ? 'pi pi-eye' : 'pi pi-eye-slash']"
                  aria-hidden="true"
                  style="color:white"
                ></i
              ></span>
            </div>
          </div>
          <div class="container-fluid lineFooter">
            <div class="row margin-bottom">
              <div class="col-sm-3">
                <label for="postGroupName">First Name</label>
              </div>
              <div class="col-sm-8">
                <input
                  id="postDescriptionName"
                  v-model="postFirstName"
                  class="form-control modalOverride"
                />
              </div>
            </div>
            <div class="row margin-bottom">
              <div class="col-sm-3">
                <label for="postGroupName">Last Name</label>
              </div>
              <div class="col-sm-8">
                <input
                  id="postGroupName"
                  v-model="postLastName"
                  type="text"
                  class="form-control modalOverride"
                />
              </div>
            </div>
            <div class="row margin-bottom">
              <div class="col-sm-3">
                <label for="postGroupName">Email</label>
              </div>
              <div class="col-sm-8">
                <input
                  id="postGroupName"
                  v-model="postEmail"
                  type="text"
                  class="form-control modalOverride"
                />
              </div>
            </div>
            <div class="row margin-bottom">
              <div class="col-sm-3">
                <label for="postGroupName">Admin</label>
              </div>
              <div class="col-sm-8">
                <input
                  id="postGroupName"
                  v-model="postAdminRights"
                  class="hoverCheckbox"
                  type="checkbox"
                  style="display: inline; margin-left: 1rem;vertical-align: middle"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <label for="postGroupName">Company</label>
              </div>
              <div class="col-sm-8">
                <select
                  id="inputSchema"
                  v-model="postCompanyID"
                  class="form-control"
                >
                  <option
                    v-for="t in companies"
                    :key="t.id"
                    :value="t.id"
                    :selected="postCompanyID === t.id"
                  >
                    {{ t.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <a @click.stop="emitToParent('Close')" class="link-2"></a>
          <div
            style="
                    display: flex;
                    margin: 0.5rem;
                    flex-direction: row;
                    align-items: stretch;
                    align-content: stretch;
                  "
          >
            <div
              style="
                      display: flex;
                      flex-direction: column;
                      flex: 2 0 0;
                      justify-content: left;
                    "
            >
              <div style="display: flex; justify-content: flex-end">
                <div style="align-self: center">
                  <button
                    class="btn btn-regular btnTabel"
                    @click.stop="emitToParent('Save')"
                  >
                    Add
                  </button>
                  <button
                    class="btn btn-regular btnTabel"
                    style="margin-left: 0.5rem"
                    @click.stop="emitToParent('Close')"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import jwtInterceptor from "../../shared/jwtInterceptor";
import { API_URL } from "../../.env";
export default {
  data: function() {
    return {
      postUsername: "",
      postFirstName: "",
      postPassword: "",
      postLastName: "",
      postEmail: "",
      postAdminRights: false,

      postCompanyID: "",
      companies: [],
      showPassword: false,

      listener: (e) => {
        e.key === "Escape" && this.emitToParent("Close");
      },
      childEvent: "keydown",
      childEventForEnter: "keydown",
      listenerForEnter: (e) => {
        e.ctrlKey && e.keyCode == 13 && this.emitToParent("Save");
      },
    };
  },
  mounted() {
    this.getAllCompanies();

    window.addEventListener(this.childEvent, this.listener);
    window.addEventListener(this.childEventForEnter, this.listenerForEnter);
  },
  methods: {
    getAllCompanies() {
      this.loading = true;
      jwtInterceptor
        .get(`${API_URL}/api/companies/`)
        .then((res) => {
          this.companies = res.data;
          this.postCompanyID = this.companies[0].id;
        })

        .catch((error) => {
          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: error.response.data.error,
            life: 4000,
          });
          if (
            document.activeElement.toString() == "[object HTMLButtonElement]"
          ) {
            document.activeElement.blur();
          }

          // Manage errors if found any
        });
    },
    //Reconsider calling API function here instead in parent
    //because we need two way communication now

    emitToParent: function(modalState) {
      var result = {};
      result.postUsername = this.postUsername;
      result.postPassword = this.postPassword;
      result.postFirstName = this.postFirstName;
      result.postLastName = this.postLastName;
      result.postEmail = this.postEmail;
      result.postAdminRights = this.postAdminRights;
      result.postCompanyID = this.postCompanyID;
      result.state = modalState;

      this.$emit("modalState", result);
    },
  },
  unmounted() {
    window.removeEventListener(this.childEvent, this.listener);
    window.removeEventListener(this.childEventForEnter, this.listenerForEnter);
  },
};
</script>

<style scoped>
.input-group-text {
  background-color: var(--mainBlue) !important;
}
</style>
