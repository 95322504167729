import jwtInterceptor from '../../shared/jwtInterceptor';
import {API_URL} from '../../.env';


const state=()=>({
    permissions:[]
});


const getters={
    getUserPermissions(state){
        return state.permissions
        
    }
};

const actions={
    async getUserPermissions({commit}){
        var response=await jwtInterceptor.get(`${API_URL}/api/profile/permission/`);
        if(response && response.data){
            commit('setUserPermissions', response.data);
        }
    }

};

const mutations={
    setUserPermissions(state, payload){
        state.permissions=payload
      
    }
};


export default{
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}