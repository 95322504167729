import { createRouter, createWebHistory } from "vue-router";
import Home from "./components/Home.vue";
import StaticHome from "./components/staticPages/staticHome.vue";

import Login from "./components/Login.vue";
import Dashboard from "./components/Dashboard.vue";
import MyReports from "./components/MyReports.vue";
import PublicReports from "./components/PublicReports.vue";
import ReportBuilder from "./components/ReportBuilder.vue";
import ReportQuery from "./components/ReportQuery.vue";
import ReportQueryById from "./components/ReportQueryById.vue";
// import HalfmoonChecker from "./components/HalfmoonChecker.vue";
import ChangePassword from "./components/ChangePassword.vue";
// import TestComponent from "./components/TestComponent.vue";
import AdminPanel from "./components/AdminPanel.vue";
import store from "./store/index";
import axios from "axios";
import { API_URL } from "../src/.env";
//  import {IS_USER_AUTHENTICATE_GETTER} from '../src/store/actions/auth';

const routes = [

  {
    path: "/",
    component: StaticHome,
    name: "staticHome",
    redirect:'/home',
    meta: { requiredAuth: false, hideNavbar: true },
    },
  {
    
    path: "/home",
    component: StaticHome,
    name: "staticHome",
    meta: { requiredAuth: false, hideNavbar: true },
  },

  {
    path: "/app/login",
    component: Login,
    name: "login",
    meta: { requiredAuth: false },
  },
  {
    path: "/app/myreports",
    name: "myreports",
    component: MyReports,
    meta: { requiredAuth: true },
  },
  // { path: "/halfmoonchecker", name:'halfmoonchecker', component: HalfmoonChecker, meta: { requiredAuth: true } },

  {
    path: "/app/publicreports",
    name: "publicreports",
    component: PublicReports,
    meta: { requiredAuth: true },
  },
  {
    path: "/app/reportbuilder",
    name: "reportbuilder",
    component: ReportBuilder,
    meta: { requiredAuth: true },
    children: [
      {
        path: "/app/reportbuilder/:id",
        name: "reportbuilderedit",
        component: ReportBuilder,
        meta: { requiredAuth: true },
      },
    ],
  },
  {
    path: "/app/reportquery",
    name: "reportquery",
    component: ReportQuery,
    meta: { requiredAuth: true, isUserAdmin: true },
    children: [
      {
        path: "/app/reportquery/:id/",
        name: "reportqueryid",
        component: ReportQueryById,
        meta: { requiredAuth: true, isUserAdmin: true },
      },
    ],
  },

  {
    path: "/app/dashboard/change-password",
    name: "change-password",
    component: ChangePassword,
    meta: { requiredAuth: true, modal1: true },
  },
  {
    path: "/app/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: { requiredAuth: true },
  },
  {
    path: "/app/admin",
    name: "admin-panel",
    component: AdminPanel,
    meta: { requiredAuth: true, isUserAdmin: true },
  },
  {
    path: "/app/home",
    name: "home",
    component: Home,
    meta: { requiredAuth: true, isUserAdmin: true },
  },
  // { path: "/test", name:'testcomponent', component: TestComponent, meta: { requiredAuth: true,  isUserAdmin:true } },
];

export const routeConfig = createRouter({
  history: createWebHistory(),
  routes: routes,
  mode:'hash'
});

routeConfig.beforeEach(async (to, from, next) => {
  const authDatas = store.getters["auth/getAuthData"].access;
  if (!authDatas) {
    const access = localStorage.getItem("access");
    const refresh = localStorage.getItem("refresh");

    if (access) {
      const data = {
        access: access,
        refresh: refresh,
      };
      store.commit("auth/saveTokenData", data);
    }
  }

  let auth = store.getters["auth/isTokenActive"];
  let authRefresh = store.getters["auth/isRefreshTokenActive"];

  // if(auth===false){

  //  localStorage.removeItem('access');
  //   localStorage.removeItem('refresh');
  //  localStorage.removeItem('role');
  //  delete axios.defaults.headers.common['Authorization'];
  // this.$router.go(0);
  //  location.reload();
  //  router.stateService.go('/login');
  // location.reload(1);

  //}

  //OVO JE ZAKOMENTARISANO ZA PROVERU ISTEKA TOKENA
  //ovde token istice, auth je od returnTokenState gde se proverava expiration od access tokena
  //ako je false, treba nam novi
  if (auth === false && authRefresh === true) {
    const authData = store.getters["auth/getAuthData"];

    //u ovom m
    if (authData.access) {
      const payload = {
        //access: authData.access,
        //auth data has refreshToken
        refresh: authData.refresh,
        //access: authData.access
        //  role:authData.role
      };

      const refreshResponse = await axios.post(
        `${API_URL}/api/profile/token/refresh/`,

        payload
      );

      // payload.refresh=authData.refresh;
      refreshResponse.data.refresh = authData.refresh;

      //here should it be refreshResponse.data.refresh or?
      store.commit("auth/saveTokenData", refreshResponse.data);

      auth = true;
    }
  }

  //  if(authRefresh===false){
  //   localStorage.removeItem('access');
  //   localStorage.removeItem('refresh');
  //   localStorage.removeItem('role');

  //   this.$router.push("/login");
  //  }

  // if (to.fullPath == "/") {
  //   return next({ path: "/home" });
  // } else if (auth && !to.meta.requiredAuth) {
  //   return next({ path: "/home" });
  // } else if (!authRefresh && to.meta.requiredAuth) {
  //   localStorage.removeItem("refresh");
  //   localStorage.removeItem("access");

  //   delete axios.defaults.headers.common["Authorization"];
  //   return next({ path: "/app/login" });
  // }
  if (to.fullPath == "/" && to.fullPath !== '/home') {
    return next({ path: "/home" });
} else if (auth && !to.meta.requiredAuth && to.fullPath !== '/home' && to.fullPath !== '/home#contact' && to.fullPath !== '/home#about' && to.fullPath !== '/home#home' && to.fullPath !== '/home#datasets' && to.fullPath !== '/home#customDatasets' && to.fullPath !== '/home#industries') {
    return next({ path: "/app/home" });
} else if (!authRefresh && to.meta.requiredAuth) {
    localStorage.removeItem("refresh");
    localStorage.removeItem("access");

    delete axios.defaults.headers.common["Authorization"];
    return next({ path: "/home" });
}




  return next();
});
