<template>
  <div>
    <div class="report-group block overrideSection">
      <h1 style="text-align:left;font-size:24px;margin:0;">Info Board</h1>
      <table class="styled-table-header">
        <thead>
          <tr>
            <th style="width:20vw;white-space: nowrap;">
              <i class="pi pi-calendar" style="line-height:unset"></i>Date
            </th>
            <th>
              <i class="pi pi-info-circle" style="line-height:unset"></i>Info
            </th>
          </tr>
        </thead>
      </table>
      <div class="customScrollForHomeTable">
        <table class="styled-table">
          <tbody>
            <tr
              v-for="t in tableData"
              :key="t.id"
              style="white-space: pre-linestyle;"
            >
              <td style="vertical-align:top">{{ t.info_date }}</td>
              <td style="font-style:italic;white-space: pre-line">
                {{ t.info_message }}
                <template v-if="t.info_url.length != 0"
                  ><br />
                  <div style="margin-top:5px">
                    <a :href="t.info_url" target="_blank">{{ t.info_url }}</a>
                  </div></template
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div>
      <h2
        style="font-size:18px;position:absolute;left: 50%;transform: translate(-50%, -50%)"
      >
        HOW TO USE SEGDB
      </h2>
    </div>
  </div>

  <div class="bottomDashboard">
    <div class="row">
      <div class="col-sm-3 center">
        <i class="pi pi-check-square iconsHome"></i>
        <h3 style="font-size:16px;line-height:unset;margin:0">
          Select dataset
        </h3>
        <p>
          Choose, or change between your datasets and with them create reports
        </p>
      </div>
      <div class="col-sm-3 center">
        <i class="pi pi-pencil iconsHome"></i>
        <h3 style="font-size:16px;line-height:unset;margin:0">Build reports</h3>
        <p>Make the most out of your data using advanced filtering</p>
      </div>
      <div class="col-sm-3 center">
        <i class="pi pi-download iconsHome"></i>
        <h3 style="font-size:16px;line-height:unset;margin:0">
          Preview and download
        </h3>
        <p>Preview your data or dynamically export it in .csv</p>
      </div>
      <div class="col-sm-3 center">
        <i class="pi pi-chart-line iconsHome"></i>
        <h3 style="font-size:16px;line-height:unset;margin:0">
          Public Reports
        </h3>
        <p>Assign report to one of your employees, make it public</p>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3 center">
        <button class="btn btn-regular">
          Hover <i class="pi pi-angle-right"></i>
        </button>
      </div>
      <div class="col-sm-3 center">
        <button class="btn btn-regular">
          Learn more <i class="pi pi-angle-right"></i>
        </button>
      </div>
      <div class="col-sm-3 center">
        <button class="btn btn-regular">
          Learn more <i class="pi pi-angle-right"></i>
        </button>
      </div>
      <div class="col-sm-3 center">
        <button class="btn btn-regular">
          Learn more <i class="pi pi-angle-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import jwtInterceptor from "../shared/jwtInterceptor";

import { API_URL } from "../../src/.env.js";
export default {
  data: () => ({
    permissions: [],
    tableData: [],
  }),
  mounted() {
    this.getTableDataInfo();
  },
  computed: {
    ...mapGetters("permissions", {
      gettersUserPermissions: "getUserPermissions",
    }),
  },
  methods: {
    ...mapActions("permissions", {
      actionsGetUserPermissions: "getUserPermissions",
    }),
    async getUserPermissions() {
      await this.actionsGetUserPermissions();
    },
    getTableDataInfo() {
      this.loading = true;
      jwtInterceptor
        .get(`${API_URL}/api/info/`)
        .then((res) => {
          this.tableData = res.data;
        })

        .catch((error) => {
          this.$toast.add({
            severity: "info",
            summary: "Error Message",
            detail: error.response.data.error,
            life: 4000,
          });
          if (
            document.activeElement.toString() == "[object HTMLButtonElement]"
          ) {
            document.activeElement.blur();
          }

          // Manage errors if found any
        });
    },
  },
};
</script>

<style scoped>
.center {
  text-align: center;
}
.iconsHome {
  font-size: 30px;
  color: var(--mainBlue);
  text-align: center;
  position: relative;
}
div.chart-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.styled-table {
  border-collapse: collapse;
  margin: 10px 0;
  font-size: 13px;
  font-family: sans-serif;
  width: 100%;
}
.styled-table-header {
  border-collapse: collapse;
  margin: 10px 0;
  font-size: 13px;
  font-family: sans-serif;
  width: 100%;
}

.styled-table-header thead tr {
  background-color: var(--mainYellow);
  color: #ffffff;
  text-align: left;
}
.styled-table th,
.styled-table td {
  padding: 5px 15px;
}
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:last-of-type {
  /* border-bottom: 2px solid var(--mainBlue); */
}
.styled-table tbody tr.active-row {
  font-weight: bold;
  color: var(--mainBlue);
}
.styled-table-header th,
.styled-table-header td {
  padding: 5px 15px;
}
.styled-table-header tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table-header tbody tr:last-of-type {
  /* border-bottom: 2px solid var(--mainBlue); */
}
.styled-table-header tbody tr.active-row {
  font-weight: bold;
  color: var(--mainBlue);
}
.overrideSection {
  height: calc(100vh - 300px);
  margin: 15px;
  position: relative;
}
.bottomDashboard {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin-bottom: 5px;
  /* line-height: 180%; */
}
div::-webkit-scrollbar {
  /*Your styles here*/
  width: 0.5em;
  height: 1em;
}
div::-webkit-scrollbar-thumb {
  /*Your styles here*/
  background-color: var(--lighterPurple);
  outline: 1px solid var(--Purple);
}
div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.customScrollForHomeTable {
  height: calc(100vh - 385px);
  overflow-y: scroll;
}

@media all and (max-height: 865px) {
  /* .customScrollForHomeTable{
    height:20vw!important;
  } */
}
</style>
