<template>
  <div
    class="modalSave"
    @click="$emit('close')"
  >
    <div
      class="modalSaveDiv"
      id="modalString"
      ref="content"
      @click.stop="close"
    >
      <div
        class="modal__detail"
        style="border-bottom: 2px solid var(--mainBlue)"
      >
        <div>
          <h1 class="modal__title" style="margin-bottom:0!important">
            {{ this.columnName }}
          </h1>
          <p
            style="text-align: left; padding-left: 1rem;font-size:1.5rem;font-weight:bold;margin-bottom:1rem;margin-top:1rem!important"
          >
            {{ this.columnDescription }}
          </p>
        </div>
        <div>
          <p
            style="
              margin: 0 !important;
              text-align: left;
              padding-left: 1rem;
              font-style: italic;
              color:#9C9C9C;
              margin-bottom:0!important;

            "
          >
            number of records with value:
            <span style="font-weight:bold">
              {{ formatNumber(this.columnStaticHaveValue) }}</span
            >
          </p>
          <p
            style="
              margin: 0 !important;
              text-align: left;
              padding-left: 1rem;
              font-style: italic;
              color:#9C9C9C;
                
            "
          >
            number of records without value:
            <span style="font-weight:bold">{{ formatNumber(this.columnStaticNoValue) }}</span>
          </p>
        </div>
        <hr style="width:97%;text-align:left;background-color:#9C9C9C" />
        <div class="container-fluid">
          <div
            class="row"
            style="margin-top: 1rem;
    margin-bottom: 1rem;  "
          >
            <div class="col-4">
              <div
                class="container"
                style="text-align: left; margin-left: 1rem;"
              >
                <label for="include" style="font-size:10px!important"
                  >Include fields without values</label
                >
                <input
                  type="checkbox"
                  id="includeCheckbox"
                  name="include"
                  v-model="fieldWithoutValues"
                  @change="onChangedFieldWithoutValues()"
                  style="display: inline; margin-left: 1rem;vertical-align: middle"
                  class="hoverCheckbox"
                />
              </div>
            </div>
            <div class="col-6">
              <button
                class="btn btn-regular"
                style="float:right"
                @click="addNewFilterToLocalFiltersList()"
              >
                Add
              </button>
            </div>

            <div class="col-2">
              <div>
                <button
                  class="btn btn-danger"
                  style="float:right;margin-right:1rem;"
                  @click="clearAllFilterValues()"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="container-fluid margin-bottom"
          id="customScrollBar"
          style="height: 35rem;
    overflow: scroll;
    overflow-x: hidden;"
        >
          <h3 style="text-align: left; margin-left: 1rem;">Selected values</h3>

          <div class="row" style="margin-left: 0.25rem">
            <ul style="width: 100%; list-style-type: decimal">
              <li
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: left;
                  align-items: center;
                  margin-right: 1rem;
                "
                v-for="(item, index) in this.localFilters"
                v-bind:key="item.id"
                v-show="
                  !(item.value == 'neinicijalizovan') &&
                    !(item.value[0] == null)
                "
              >
                <div class="col-3">
                  <input
                    type="text"
                    v-show="item.option_type != 1 && item.option_type != 2"
                    v-model="item.value[0]"
                    style="width:100%;margin-left:1rem"
                    placeholder="Enter value"
                  />
                  <button
                    class="btn btn-regular"
                    v-show="item.option_type == 1"
                    style="width:8rem;margin-left:1rem;"
                    @click="openSelectedValuesModal(item)"
                  >
                    ...
                    <teleport
                      v-if="item.testProperty2"
                      to="#modals"
                      tabindex="0"
                    >
                      <selected-values
                        v-on:selectedValuesState="onSelectedValuesChange"
                        v-bind:selectedValues="selectedValues"
                        :modelValue="item.value"
                      />
                    </teleport>
                  </button>
                  <button
                    class="btn btn-regular"
                    v-if="item.option_type == 2"
                    style="width:8rem;margin-left:1rem;"
                    @click="openPasteValuesModal(item)"
                  >
                    ...

                    <teleport v-if="item.testProperty" to="#modals">
                      <paste-values
                        v-on:pasteValuesState="onPasteValuesChange"
                        :modelValue="item.value"
                      />
                    </teleport>
                  </button>
                </div>

                <select
                  class="col-3"
                  style="margin-left: 3rem;"
                  :key="index"
                  @change="onFilterTypeChanged($event, item)"
                >
                  <option disabled value="">Please select one</option>
                  <option
                    :selected="setDefaultSelectValue(item, '4')"
                    value="4"
                  >
                    Exact value</option
                  >
                  <option :selected="setDefaultSelectValue(item, '1')" value="1"
                    >Begins with
                  </option>
                  <option :selected="setDefaultSelectValue(item, '2')" value="2"
                    >Ends with</option
                  >
                  <option :selected="setDefaultSelectValue(item, '3')" value="3"
                    >Contains</option
                  >

                  <option
                    v-if="columnVisibility2"
                    :selected="setDefaultSelectValue(item, '5')"
                    value="5"
                    >Select values</option
                  >
                  <option
                    v-if="pasteValues"
                    :selected="setDefaultSelectValue(item, '6')"
                    value="6"
                    >Paste values</option
                  >
                </select>
                <div class="col-2" style="margin-left:0.5rem;">
                  <label for="exclude" style="font-size:10px!important"
                    >Case Insensitive</label
                  >
                  <input
                    type="checkbox"
                    v-model="item.case_insensitive"
                    style="display: inline-block;margin-left:0.5rem;vertical-align: middle;"
                    class="hoverCheckbox"
                  />
                </div>
                <div class="col-2">
                  <label for="exclude" style="font-size:10px!important"
                    >Exclude</label
                  >
                  <input
                    type="checkbox"
                    v-model="item.not_equal"
                    style="display: inline;margin-left:0.5rem;vertical-align: middle;"
                    class="hoverCheckbox"
                  />
                </div>
                <div class="col-1">
                  <button
                    class="btn btn-danger"
                    style="
    font-size:1.15rem;"
                    @click="removeSelectedFilterFromLocalFiltersList(item)"
                  >
                    Remove
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        style="
          display: flex;
          margin: 0.5rem;
          flex-direction: row;
          align-items: stretch;
          align-content: stretch;
        "
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            flex: 2 0 0;
            justify-content: left;
          "
        >
          <div style="display: flex; justify-content: right">
            <div style="align-self: center">
              <button
                class="btn btn-regular"
                style="margin-right: 1rem; margin-left: 0.5rem; "
                @click.stop="emitToParent('Save')"
              >
                Apply
              </button>
            </div>
          </div>
        </div>

        <div style="display: flex; justify-content: flex-end">
          <div style="align-self: center">
            <button
              class="btn btn-regular btnTabel"
              @click.stop="emitToParent('Close')"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>

      <a class="link-2" @click.stop="emitToParent('Close')"></a>
    </div>
  </div>
</template>

<script>
import jwtInterceptor from "../shared/jwtInterceptor";

import { API_URL } from "../../src/.env.js";
import SelectedValues from "./SelectedValues.vue";
import PasteValues from "./PasteValues.vue";
export default {
  name: "FilterModalString",
  components: { SelectedValues, PasteValues },
  props: ["filters"],
  data: function() {
    return {
      localFilters: this.filters,
      fieldWithoutValues: false,
      newFilterValue: "",
      columnName: "",
      columnDescription: "",
      columnStaticHaveValue: "",
      columnStaticNoValue: "",
      tmpObjIni: {},
      fieldIdForEmit: "",
      showNewFilter: false,
      statusShowItem: {},
      columnDbType: "",
      columnVisibility: false,
      columnVisibility2: false,
      pasteValues: false,
      selectedValues: [],
      isSelectedValueChosen: false,
      filterType: 4,
      listener: (e) => {
        e.key === "Escape" && this.emitToParent("Close");
      },
      eventt: "keydown",
      listenerForEnter:(e)=>{ (e.ctrlKey && e.keyCode==13) && this.emitToParent("Save")}

    

    };
  },

  created() {
    this.getDescriptiveDataFromFilter();
    this.getSelectedValuesByColumnId();
  },
  mounted() {
    window.addEventListener(this.eventt, this.listener);
    window.addEventListener(this.eventt, this.listenerForEnter);

    this.localFilters.forEach((element) => {
      if (element.value[0] == null) {
        this.fieldWithoutValues = true;
      }
      if (element.option_type == 1 || element.option_type == 2) {
        this.isSelectedValueChosen = true;
      }
    });
  },

  methods: {
    formatNumber(num) {
      return new Intl.NumberFormat("en-US").format(num);
    },
    onSelectedValuesChange(value) {
      //Kada se zatvori child modal, ti se registruj ponovo na event.
      window.addEventListener(this.eventt, this.listener);
      window.addEventListener(this.eventt, this.listenerForEnter);

      if (value.state == "Close") {
        this.localFilters.forEach((element) => {
          element.testProperty2 = false;
        });
      } else if (value.state == "Save") {
        const index = this.localFilters
          .map((e) => e.testProperty2)
          .indexOf(true);
        this.localFilters[index].value = value.payload;

        this.localFilters.forEach((element) => {
          element.testProperty2 = false;
        });
      }
    },
    onPasteValuesChange(value) {
       //Kada se zatvori child modal, ti se registruj ponovo na event.
      window.addEventListener(this.eventt, this.listener);
      window.addEventListener(this.eventt, this.listenerForEnter);

      if (value.state == "Close") {
        this.localFilters.forEach((element) => {
          element.testProperty = false;
        });
      } else if (value.state == "Save") {
        const index = this.localFilters
          .map((e) => e.testProperty)
          .indexOf(true);
        this.localFilters[index].value = value.payload;

        this.localFilters.forEach((element) => {
          element.testProperty = false;
        });
      }
    },
    openSelectedValuesModal(item) {
      //Unhook sa ESC
      window.removeEventListener(this.eventt, this.listener);
      window.removeEventListener(this.eventt, this.listenerForEnter);

      item.testProperty2 = true;

      if (document.activeElement.toString() == "[object HTMLButtonElement]") {
        document.activeElement.blur();
      }
    },
    openPasteValuesModal(item) {
        //Unhook sa ESC
      window.removeEventListener(this.eventt, this.listener);
      window.removeEventListener(this.eventt, this.listenerForEnter);

      item.testProperty = true;
        if (document.activeElement.toString() == "[object HTMLButtonElement]") {
        document.activeElement.blur();
      }
    },

    //ova metoda iz liste localFilters-a proverava da li u istoj postoje unete prazne vrednosti, i ako postoje, brise ih
    removeEmptyValues() {
  

this.localFilters = this.localFilters.filter(el => {
  return el.value[0] != '' || el.option_type == 1;
});
    
    },
    addNewSelectedValuesRow() {
      this.showNewFilter = true;
    },
    getDescriptiveDataFromFilter() {
      this.columnName = this.localFilters[0].column_name;
      this.columnDescription = this.localFilters[0].column_description;
      this.columnStaticHaveValue = this.localFilters[0].column_static.have_value;
      this.columnStaticNoValue = this.localFilters[0].column_static.no_value;
      this.columnDbType = this.localFilters[0].field_type;
      this.tmpObjIni = this.localFilters[0];
      this.fieldIdForEmit = this.localFilters[0].field_id;
      this.columnVisibility = this.localFilters[0].column_db_visible;
      this.columnVisibility2 = this.localFilters[0].column_db_select_values;
      this.pasteValues = this.localFilters[0].column_db_paste_values;
    },

    emitToParent: function(modalState) {
      for (var i = this.localFilters.length - 1; i >= 0; --i) {
        if (
          this.localFilters[i].value == "neinicijalizovan" ||
          this.localFilters[i].value == "neinicijalizovani"
        ) {
          this.localFilters.splice(i, 1);
        }
      }
      ``;
      var result = {};
      result.state = modalState;
      this.removeEmptyValues();
      result.payload = this.localFilters;
      result.id = this.fieldIdForEmit;
      result.type = this.columnDbType;
    

      this.$parent.$emit("modalState", result);
    },
    onChangedFieldWithoutValues: function() {
      if (this.fieldWithoutValues) {
        var tmpObj = {};
        tmpObj = JSON.parse(JSON.stringify(this.tmpObjIni));
        tmpObj.value = [null];
        tmpObj.operator = "=";
        tmpObj.type = 1;
        tmpObj.not_equal = false;
        tmpObj.case_insensitive = false;
        this.localFilters.push(tmpObj);

      } else {
        var noviIndex = this.localFilters.findIndex((e) => e.value[0] == null);

        this.localFilters.splice(noviIndex, 1);
      }
    },
    addNewFilterToLocalFiltersList() {
      var tmpObj = {};
      tmpObj = JSON.parse(JSON.stringify(this.tmpObjIni));
      tmpObj.value = [this.newFilterValue];
      tmpObj.operator = "=";
      tmpObj.type = 1;
      tmpObj.not_equal = false;
      tmpObj.case_insensitive = false;
      tmpObj.option_type = 0;
      this.filterType = 4;
      this.localFilters.push(tmpObj);
      this.newFilterValue = "";
      if (document.activeElement.toString() == "[object HTMLButtonElement]") {
        document.activeElement.blur();
      }
    },
    clearAllFilterValues() {
      if (this.fieldWithoutValues) {
        var index = this.localFilters.map((a) => a.value).indexOf(null);
        this.localFilters.splice(index, 1);
        this.fieldWithoutValues = false;
      }
      this.localFilters = [];

      this.newFilterValue = "";
    },
    removeSelectedFilterFromLocalFiltersList(i) {
      var index = this.localFilters.map((a) => a.value).indexOf(i.value);
      this.localFilters.splice(index, 1);
    },
    clearValuesAfterSelectedModal() {
      this.isSelectedValueChosen = false;
    },
    onFilterTypeChanged: function(e, item) {
      this.filterType = e.target.value;

      if (this.filterType == 1) {
        item.type = 1;
        item.operator = "->";
        item.option_type = 0;
        if (this.isSelectedValueChosen) {
          this.clearValuesAfterSelectedModal();
          item.value = [""];
        }
      } else if (this.filterType == 2) {
        item.type = 1;
        item.operator = "<-";
        item.option_type = 0;
        if (this.isSelectedValueChosen) {
          this.clearValuesAfterSelectedModal();
          item.value = [""];
        }
      } else if (this.filterType == 3) {
        item.type = 4;
        item.operator = " ";
        item.option_type = 0;
        if (this.isSelectedValueChosen) {
          this.clearValuesAfterSelectedModal();
          item.value = [""];
        }
      } else if (this.filterType == 4) {
        item.type = 1;
        item.operator = "=";
        item.option_type = 0;
        if (this.isSelectedValueChosen) {
          this.clearValuesAfterSelectedModal();
          item.value = [""];
        }
      } else if (this.filterType == 5) {
        item.value[0] = "neinicijalizovani";
        item.type = 3;
        item.option_type = 1;
        this.isSelectedValueChosen = true;
      } else if (this.filterType == 6) {
        item.value[0] = "";
        item.type = 3;
        item.option_type = 2;
        this.isSelectedValueChosen = true;
      }
    },
    setDefaultSelectValue(item, index) {
      var operator = "";
      if (index == 1) {
        operator = "->";
      } else if (index == 2) {
        operator = "<-";
      } else if (index == 3) {
        operator = " ";
      } else if (index == 4) {
        operator = "=";
      } else if (index == 5) {
        //
      }

      if (
        item.operator === operator ||
        item.option_type == 1 ||
        item.option_type == 2
      ) {
        return true;
      } else {
        return false;
      }
    },
    getSelectedValuesByColumnId() {
      jwtInterceptor
        .post(`${API_URL}/api/metadata/column/values/`, {
          column_id: this.fieldIdForEmit,
        })
        .then((response) => {
          this.selectedValues = response.data.result;
          for (var i = 0; i < this.selectedValues.length; i++) {
            if (this.selectedValues[i] === null) {
              var index = this.selectedValues.indexOf(null);
              this.selectedValues.splice(index, 1);
            }
          }
        })
        .catch(() => {});
    },
  },
  unmounted() {
  
    window.removeEventListener(this.eventt, this.listener);
    window.removeEventListener(this.eventt, this.listenerForEnter);


  },
};
</script>
<style>
.hoverCheckbox:hover {
  cursor: pointer;
}
#customScrollBar::-webkit-scrollbar {
  width: 4px !important;
}

/* Track */
#customScrollBar::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */
#customScrollBar::-webkit-scrollbar-thumb {
  background: #888 !important;
}

/* Handle on hover */
#customScrollBar::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}
</style>
