import jwtInterceptor from '../../shared/jwtInterceptor';
import {API_URL} from '../../.env';
  const state=()=>({
    /*
      Each element from the array has basically a title, 
      an open status and if sub-elements exist an additional array, ‘subnav’ in this example.
      And every element in this array has again a title, 
      the status open and the ‘subnav’ array if there are any child-elements.
      You can specify as many levels as you want.
      And you can exchange this array with addional informations, like a link for each entry.
    */
    navigation: [],
    open:true
  })

  const getters= {
    navigation: state => {
      return state.navigation;
    }
  }
  const actions={
    async getTableData({commit}){
        var response=await jwtInterceptor.post(`${API_URL}/api/metadata/tables/`);
        if(response && response.data){
            commit('setTableData', response.data);
        }
    }

};

const mutations={
  setTableData(state, payload){
        state.navigation=payload.fields;
        
      
    }
};
  export default{
    namespaced:true,
    state,
    getters,
    actions,
    mutations
    
}