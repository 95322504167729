<template>
  <Toast />
  <main id="login">
    <div class="arrow" @click="goToHome">
      <i class="pi pi-angle-left"> </i>
    </div>
    <section>
      <form class="width-100" v-on:keyup.enter="login">
        <div class="align-center margin-bottom">
          <router-link class="logo-header" to="/home"
            ><img src="../assets/segdb-logo.svg"
          /></router-link>
        </div>
        <div class="margin-bottom">
          <label for="txtemail" class="form-label dark-label">Email</label>
          <input
            type="text"
            class="form-control"
            id="txtemail"
            aria-describedby="emailHelp"
            v-model="email"
          />
        </div>

        <label for="txtPassword" class="form-label dark-label">Password</label>
        <div class="margin-bottom input-container">
          <input
            class="form-control input-with-icon"
            id="txtPassword"
            v-bind:type="[showPassword ? 'text' : 'password']"
            v-model="password"
          />
          <span class="input-icon" @click="showPassword = !showPassword"
            ><i
              :class="[showPassword ? 'pi pi-eye' : 'pi pi-eye-slash']"
              aria-hidden="true"
            ></i
          ></span>
        </div>

        <button
          type="button"
          class="btn btn-regular width-100"
          @click.prevent="login()"
        >
          LOGIN<i class="pi pi-sign-in rightIcon"></i>
        </button>
      </form>
    </section>
  </main>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
    };
  },
  computed: {
    ...mapGetters("auth", {
      getterLoginStatus: "getLoginStatus",
    }),
  },
  methods: {
    goToHome() {
      this.$router.push("/home");
    },
    ...mapActions("auth", {
      actionLogin: "login",
    }),
    async login() {
      await this.actionLogin({ email: this.email, password: this.password });
      if (this.getterLoginStatus === "success") {
        this.$router.push("/app/home");
        this.$router.go(0);
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error Message",
          detail: "Login unsuccessfull, please try again",
          life: 3000,
        });
      }
    },
  },
};
</script>
<style scoped>
input[type="text"] {
  color: var(--mainBlue);
}
.arrow {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  background-color: #b8c1e1;
  color: #fff;
  text-align: center;
  line-height: 40px;
  font-size: 24px;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  z-index: 9999;
}
.arrow > i {
  font-size: 17px;
}
.rightIcon {
  float: right;
  margin-top: 0.7rem;
  font-size: 1.5rem;
}
.input-container {
  position: relative;

  border: 1px solid transparent;

  border-radius: 1px;
  overflow: hidden;
}

/* Style the form icons */
.input-icon {
  position: absolute;
  right: 0;
  top: 1vh;
  color:var(--mainBlue)
}

.input-with-icon {
  outline: none;
}
</style>